import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import isFunction from 'lodash/isFunction';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import values from 'lodash/values';
import zipObject from 'lodash/zipObject';
import { observer } from 'mobx-react';

export default function bindLoading(bindings) {
  function decorator(Wrapped) {
    const boundHandlerProps = values(bindings);

    @observer
    class Bound extends Component {
      state = mapValues(bindings, () => false);

      setWrappedInstance = (wrappedInstance) => {
        this.wrappedInstance = wrappedInstance;
      };

      render() {
        const props = this.props;
        const newHandlers = boundHandlerProps.map((handlerKey) => {
          return props[handlerKey]
            ? async (...args) => {
                const boundKeys = keys(bindings).filter((key) => {
                  return bindings[key] === handlerKey;
                });
                let state = {
                  ...this.state,
                };
                boundKeys.forEach((key) => {
                  state[key] += 1;
                });
                this.setState(state);
                try {
                  const handlerRes = await (props[handlerKey] &&
                    props[handlerKey](...args));
                  return handlerRes;
                } finally {
                  state = {
                    ...this.state,
                  };
                  boundKeys.forEach((key) => {
                    state[key] -= 1;
                  });
                  this.setState(state);
                }
              }
            : null;
        });
        const newHandlerProps = zipObject(boundHandlerProps, newHandlers);
        const loadingState = mapValues(
          this.state,
          (count, key) => !!props[key] || count > 0
        );
        const newProps = {
          
          ...props,
          ...loadingState,
          ...newHandlerProps
        };

        return (
          <Wrapped
            {...(isFunction(Wrapped)
              ? null
              : {
                  ref: this.setWrappedInstance,
                })}
            {...newProps}
          />
        );
      }
    }

    const componentName = Bound.displayName || Bound.name || 'Component';
    Bound.displayName = `BindLoading(${componentName})`;
    const newPropTypes = Wrapped.propTypes;
    Bound.propTypes = newPropTypes && omit(newPropTypes, keys(bindings));
    return hoistNonReactStatics(Bound, Wrapped);
  }

  return decorator;
}
