import PSPDFKit, { Configuration } from 'pspdfkit';
import logger from 'src/logger';

export const PSPDFKIT_URL =
  window.Glide && window.Glide.PSPDFKIT_URL
    ? `${window.Glide.PSPDFKIT_URL}${
        window.Glide.PSPDFKIT_URL.endsWith('/') ? '' : '/'
      }`
    : undefined;

type PSPDFKitPackage = typeof import('pspdfkit');

let cachedBaseConfig: Partial<Configuration> | undefined;
let cachedPSPDFKit: typeof PSPDFKit | undefined;

const buildBaseConfig = (
  pspdfkitSDK: typeof PSPDFKit,
  packageName: string
): Partial<Configuration> => {
  let baseUrl: string;
  if (!packageName || packageName === 'pspdfkit') {
    baseUrl = window.WEBPACK_PUBLIC_PATH;
  } else {
    baseUrl = `${window.WEBPACK_PUBLIC_PATH}${packageName}/`;
  }
  return {
    baseUrl,
    serverUrl: PSPDFKIT_URL,
    container: '#doc-viewer',
    instant: false,
    autoSaveMode: pspdfkitSDK.AutoSaveMode.DISABLED,
    editableAnnotationTypes: [pspdfkitSDK.Annotations.RectangleAnnotation],
    maxDefaultZoomLevel: 2.4,
    minDefaultZoomLevel: 1,
  };
};

const setPspdfkitOptions = (pspdfkitSDK: typeof PSPDFKit) => {
  pspdfkitSDK.Options.IGNORE_DOCUMENT_PERMISSIONS = true;
  pspdfkitSDK.Options.SELECTION_OUTLINE_PADDING = () => -1;
  pspdfkitSDK.Options.SELECTION_STROKE_WIDTH = 2;
};

const importPSPDFKitPackage = async (version?: string) => {
  let packageName: string;
  let resolvedPackage: PSPDFKitPackage;
  switch (version) {
    case '2023.1':
      packageName = 'pspdfkit-2023.1.4';
      resolvedPackage = (await import(
        'pspdfkit-2023.1.4'
      )) as unknown as PSPDFKitPackage;
      break;
    // case "2023.2":
    //   packageName = "pspdfkit-2023.2.0";
    //   resolvedPackage = await import("pspdfkit-2023.2.0") as unknown as PSPDFKitPackage;
    //   break;
    // case "2023.3":
    //   packageName = "pspdfkit-2023.3.5";
    //   resolvedPackage = await import("pspdfkit-2023.3.5") as unknown as PSPDFKitPackage;
    //   break;
    // case "2023.4":
    //   packageName = "pspdfkit-2023.4.6";
    //   resolvedPackage = await import("pspdfkit-2023.4.6") as unknown as PSPDFKitPackage;
    //   break;
    // case "2023.5":
    //   packageName = "pspdfkit-2023.5.4";
    //   resolvedPackage = await import("pspdfkit-2023.5.4") as unknown as PSPDFKitPackage;
    //   break;
    // case "2024.1":
    //   packageName = "pspdfkit-2024.1.2";
    //   resolvedPackage = await import("pspdfkit-2024.1.2") as unknown as PSPDFKitPackage;
    //   break;
    default:
      packageName = 'pspdfkit';
      resolvedPackage = (await import(
        'pspdfkit'
      )) as unknown as PSPDFKitPackage;
  }
  return { resolvedPackage, packageName };
};

export const initializePSPDFKit = async (version?: string) => {
  if (cachedPSPDFKit && cachedBaseConfig) {
    return;
  }
  const {
    resolvedPackage: { default: pspdfkitSDK },
    packageName,
  } = await importPSPDFKitPackage(version);

  const versionBaseConfig = buildBaseConfig(pspdfkitSDK, packageName);
  setPspdfkitOptions(pspdfkitSDK);

  cachedPSPDFKit = pspdfkitSDK;
  cachedBaseConfig = versionBaseConfig;
  return {
    PSPDFKit: pspdfkitSDK,
    baseConfig: versionBaseConfig,
  };
};

export const getPSPDFKit = (): typeof PSPDFKit => {
  if (!cachedPSPDFKit) {
    setPspdfkitOptions(PSPDFKit);
    logger.error(
      '[getPSPDFKit] The dynamic PSPDFKit SDK was not initialized. Fallback to the default SDK.'
    );
    return PSPDFKit;
  }
  return cachedPSPDFKit;
};

export const getBaseConfig = (): Partial<Configuration> => {
  if (!cachedBaseConfig) {
    logger.error(
      '[getBaseConfig] The dynamic PSPDFKit SDK was not initialized. Fallback to the default SDK.'
    );
    return buildBaseConfig(PSPDFKit, 'pspdfkit');
  }
  return cachedBaseConfig;
};

export const DEFAULT_ZOOM_LEVEL = 'FIT_TO_WIDTH';
