import moment from 'moment-timezone';

export default function formatTimestamp(
  time: number | string | Date,
  format = 'ddd MMM D, h:mm a',
  timezone: string | null = null
) {
  const timeAsNumber = Number(time);
  const m = moment.unix(timeAsNumber / 1000);
  if (timezone) {
    m.tz(timezone);
  }
  return m.format(format);
}
