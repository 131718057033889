import type {
  CreateTemplateRequest,
  GetListTemplatesRequest,
  GetTeamsResponse,
} from 'src/types/proto/services/template_public_service';
import type {
  DispatchResponse,
  TransactionList,
} from 'src/types/proto/transactions';
import type { ApiResponseData } from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import BaseApi from '../base-api';

const getBaseUrl = (id?: string) =>
  `/transaction_templates/${id ? `${id}/` : ''}`;

export default class TransactionTemplates extends BaseApi {
  list = (params: GetListTemplatesRequest) => {
    return this.get<TransactionList>(getBaseUrl(), {
      params: formatToSnakeCase(params),
    });
  };

  teams = () =>
    this.get<ApiResponseData<GetTeamsResponse>>(`${getBaseUrl()}teams/`);

  create = (data: Partial<CreateTemplateRequest>) => {
    return this.post<DispatchResponse>(getBaseUrl(), formatToSnakeCase(data));
  };

  clone = (cloneId: string, data: Partial<CreateTemplateRequest>) => {
    return this.post<DispatchResponse>(
      `${getBaseUrl()}clone/${cloneId}/`,
      formatToSnakeCase(data)
    );
  };

  edit = (id: string, data: Partial<CreateTemplateRequest>) => {
    return this.post<DispatchResponse>(getBaseUrl(id), formatToSnakeCase(data));
  };

  deleteTemplate = (id: string) => {
    return this.delete<DispatchResponse>(getBaseUrl(id));
  };
}
