import URI from 'urijs';
import type { PackageDocuments } from 'src/types/proto/packages';
import type {
  Item as Offer,
  PublicOfferSummary,
} from 'src/types/proto/transactions';
import BaseApi from '../base-api';

export default class Packages extends BaseApi {
  downloadPackage = (actionUuid: string) => {
    return URI(`/api/packages/${actionUuid}/download`);
  };

  getPackageDocuments = (actionUuid: string) => {
    return this.get<PackageDocuments>(`/packages/${actionUuid}/pds`);
  };

  getPackageDocumentThumbnailBlob = (
    actionUuid: string,
    packageDocumentId: string
  ) => {
    return this.get(
      `/packages/${actionUuid}/pd/${packageDocumentId}/thumbnail`,
      {
        responseType: 'blob',
      }
    ) as any;
  };

  getPackageOffer = (actionUuid: string) => {
    return this.get<PublicOfferSummary>(`/packages/${actionUuid}/offer`);
  };

  recordPackageClickedLink = (actionUuid: string) => {
    return this.post<void>(`/packages/${actionUuid}/clicked-link/`);
  };

  getPreviousOfferData = (transactionPackageId: string) => {
    // transId should come from the offer.transaction
    return this.get<Offer>(`/packages/${transactionPackageId}/prev_offer/`);
  };
}
