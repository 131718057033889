import fromPairs from 'lodash/fromPairs';
import isFunction from 'lodash/isFunction';
import { action, computed, makeObservable } from 'mobx';
import logger from 'src/logger';
import Model from 'src/models/base';
import type { Form as FormJson, Field } from 'src/types/proto/reform';
import type BoundField from './bound-field';
import { bindOutput } from './outputs';
import type BoundOutput from './outputs/bound-output';
import type { RenderOptions } from './render-options';

export type RenderOptionsObjOrFunc =
  | RenderOptions
  | ((...args: unknown[]) => RenderOptions);
export type BindField = (field: Field) => BoundField | undefined;

export default class Form extends Model<FormJson> {
  /*
    A form has the logic associated with the actual form itself,
    that is, what would be associated with the reform form.

    It knows how to use the outputs and inputs to compute
    linked fields and imports based on the data in the reform form.
  */
  private _renderOptionsObjOrFunc: RenderOptionsObjOrFunc | undefined;
  private _boundOutputs: Map<string, BoundOutput>;

  constructor(json: FormJson, renderOptionsObjOrFunc?: RenderOptionsObjOrFunc) {
    // You might want a function for renderOptionsObjOrFunc for mobx-observers
    // ie. in pdf form fill contexts
    super(json);
    makeObservable(this);
    this._renderOptionsObjOrFunc = renderOptionsObjOrFunc;
    this._boundOutputs = new Map();
  }

  get renderOptions() {
    if (!this._renderOptionsObjOrFunc) {
      return {};
    }
    if (isFunction(this._renderOptionsObjOrFunc)) {
      return this._renderOptionsObjOrFunc();
    }
    return this._renderOptionsObjOrFunc;
  }

  @action
  setRenderOptions(renderOptions: RenderOptions) {
    this._renderOptionsObjOrFunc = renderOptions;
    // Go through all boundOutputs, and for each, update the renderOptions.
    for (const boundOutput of this._boundOutputs.values()) {
      boundOutput.setRenderOptions(renderOptions);
    }
  }

  @computed
  get outputs() {
    return this.data.outputs;
  }

  @computed
  get fields() {
    return this.data.fields;
  }

  getBoundOutput(outputId: string, bindField: BindField) {
    if (!this._boundOutputs.has(outputId)) {
      const output = (this.data.outputs || []).find((o) => o.id === outputId);
      if (!output) {
        logger.warn(`Undefined output: "${outputId}"`);
        return null;
      }
      const bindings = fromPairs(
        output.imports.map((i) => {
          const field = this.data.fields.find((f) => f.id === i.id) as Field;
          return [i.alias, bindField(field)];
        })
      );
      this._boundOutputs.set(
        outputId,
        bindOutput(bindings, output, this.renderOptions)
      );
    }
    return this._boundOutputs.get(outputId);
  }
}
