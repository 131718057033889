import { computed, override } from 'mobx';
import type { FormConfigItemStringChoice } from 'src/types/proto/reform';
import BaseItemDelegate from './base';

export default class StringChoiceItemDelegate extends BaseItemDelegate<FormConfigItemStringChoice> {
  @override
  get params() {
    return this.item.data.stringChoice as FormConfigItemStringChoice;
  }

  @computed
  get options() {
    return this.params.options;
  }

  toBoundFieldValue(val: any) {
    const fieldValue = val?.value;
    return [fieldValue, { [this.fieldId]: fieldValue }];
  }

  toWidgetValue(val = this.value) {
    const fieldValue = {
      value: val,
    };
    return [fieldValue, { [this.flowKey]: fieldValue }];
  }

  setValue(val: any) {
    const [fieldValue] = this.toBoundFieldValue(val);
    this.topLinkedOutput?.setFields({
      this: fieldValue,
    });
  }
}
