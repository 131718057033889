export default function fieldOutput(key, handler, outKind) {
  function outputFactory() {
    // TODO...
    return null;
  }

  outputFactory.key = key;
  outputFactory.handler = handler;
  outputFactory.outKind = outKind;
  return outputFactory;
}
