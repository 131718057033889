import get from 'lodash/get';
import merge from 'lodash/merge';
import { getFieldInstance } from '../fields';
import OutputKind from './base';

export default class Part extends OutputKind {
  getParams(outctx) {
    const fieldParams = get(outctx, 'bindings.this.field.params', {});
    const outputParams = get(outctx, 'output.params', {});

    return merge({}, fieldParams, outputParams);
  }

  inputKind(outctx) {
    const params = this.getParams(outctx);
    if (!params.base_path) {
      return get(outctx, 'bindings.this.field.kind', null);
    }
    return params.base_kind || 'string';
  }

  pdfValue(outctx) {
    const params = this.getParams(outctx);
    const value = outctx.get('this');
    let v = params.base_path ? get(value, params.base_path) : value;
    v = params.path ? get(v, params.path, '') : v;
    let subkind;
    if (params.format || params.render_kind) {
      if (!params.base_path && !params.path) {
        subkind = get(outctx, 'bindings.this.field.kind', {});
      } else {
        subkind = params.render_kind || 'string';
      }
      const fieldInstance = getFieldInstance(
        subkind,
        params,
        outctx.renderOptions
      );
      return fieldInstance.renderPdf(v);
    }
    return v;
  }
}
