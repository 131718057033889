import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type {
  Flow,
  FlowList,
  FlowVersionAuditList,
} from 'src/types/proto/flows';
import type {
  GetFlowAuditListRequest,
  GetFlowsRequest,
} from 'src/types/proto/services/flow_admin_service';
import Flows from '../public/flows';

export default class AdminFlows extends Flows {
  searchAll(args: GetFlowsRequest) {
    const params = omitBy(args, isNil);
    return this.get<FlowList>('/flows/flows_search_all', {
      params,
    });
  }

  getFlow(flowId: string) {
    return this.get<Flow[]>(`/flows/${flowId}`);
  }

  getFlowAuditList(
    flowId: string,
    args: Omit<GetFlowAuditListRequest, 'flowId'>
  ) {
    const params = omitBy(args, isNil);
    return this.get<FlowVersionAuditList>(`/flows/${flowId}/get_audit_list`, {
      params,
    });
  }
}
