import api from 'src/api';
import getLocalStorage from 'src/utils/local-storage';
import logger from 'src/logger';
import { action, computed, makeObservable, observable } from 'mobx';
import { getFetch } from 'src/utils/get-fetch';
import {
  publishSharePacket,
  updateSharePacketAccessStatus,
} from 'src/models/transactions/intents';

const { ACTIVITIES_LIMIT } = window.Glide.CONSTANTS;

/**
 * Store for fetching shared packets + downloading & viewing
 * documents in a shared packet
 */
export default class SharedPacketsStore {
  @observable observedSharePacketId = null;

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.sharedPacket;
    if (!this.transactions) {
      return;
    }
    this.activities.subscribeActivities((activities) => {
      if (!this.observedSharePacketId) {
        return;
      }
      if (
        activities.some((a) => a.itemIds.includes(this.observedSharePacketId))
      ) {
        this.getFetchActivitiesView.fetch(this.observedSharePacketId);
      }
    });
  }

  @computed
  get transactions() {
    return this.parent.transactions;
  }

  @computed
  get activities() {
    return this.parent.activities;
  }

  @getFetch({
    getMemoizeKey: ({ uuid }) => uuid,
  })
  async getFetchPacket({ uuid, activity }) {
    let data;
    let error;
    try {
      data = (await this.api.getPacket(uuid, activity)).data;
    } catch (err) {
      error =
        err.code === api.NOT_FOUND
          ? 'Package not found or was removed.'
          : this.parent.ui.getWentWrongMessage(err);

      if (err.code === api.NOT_FOUND) {
        try {
          const reqUuid = getLocalStorage()
            .get('sharedItemAccessMap')
            .findKey((v) => v === uuid)
            .value();
          getLocalStorage().unset(`sharedItemAccessMap.${reqUuid}`).write();
        } catch (localStorageErr) {
          logger.error(localStorageErr);
        }
      }
    }

    try {
      getLocalStorage()
        .defaultsDeep({
          sharedItemAccessMap: {},
        })
        .write();

      if (!error) {
        getLocalStorage()
          .get('sharedItemAccessMap')
          .set(data.sharePacketUuid, uuid)
          .write();
      }
    } catch (err) {
      logger.error(err);
    }

    if (error) {
      throw new Error(error);
    }
    return data;
  }

  @action
  observeSharePacketActivities = (sharePacketId) => {
    this.observedSharePacketId = sharePacketId;
  };

  @getFetch({
    getMemoizeKey: (x) => x,
  })
  async getFetchActivitiesView(sharePacketId) {
    return (await this.api.getActivitiesView(sharePacketId)).data;
  }

  fetchDiffPreview = async (sharePacketId) => {
    return (await this.api.getDiffPreview(sharePacketId)).data;
  };

  @action.bound
  async fetchDocumentURL(documentId, uuid) {
    const { data } = await this.api.downloadDocumentURL(documentId, uuid);
    return data ? data.url : '';
  }

  @action.bound
  async forwardSharePacket(uuid, data) {
    const { res } = await this.api.forwardSharePacket(uuid, data);
    return res;
  }

  @action.bound
  async downloadDocs(docs, outputFilename, shareItemUuid, numberDocuments) {
    if (shareItemUuid) {
      this.api.getDownloadAllForShareItem(shareItemUuid);
    }
    return this.api.fileServiceDownloadZip(
      docs.map((d) => ({
        url: d.url,
        name: d.displayName,
      })),
      outputFilename,
      {
        numberDocuments,
      }
    );
  }

  refreshActivities = (sharePacket) => {
    return Promise.all([
      this.getFetchActivitiesView.fetch(sharePacket.id),
      this.parent.activities.fetchActivities({
        transactionId: sharePacket.transId,
        itemId: sharePacket.id,
        offset: 0,
        limit: ACTIVITIES_LIMIT,
      }),
    ]);
  };

  publishSharePacket = async (notify, message, transaction, sharePacketId) => {
    try {
      await this.transactions.dispatch(
        transaction.id,
        publishSharePacket({
          sharePacketId,
          notify,
          message,
        })
      );
      await this.parent.sharedPackets.getFetchActivitiesView.fetch(
        sharePacketId
      );
    } catch (err) {
      this.parent.ui.wentWrong(err);
    }
  };

  updateAccessStatus = async (sharePacket, accessStatus) => {
    try {
      await this.transactions.dispatch(
        sharePacket.transId,
        updateSharePacketAccessStatus(sharePacket.id, accessStatus)
      );
      if (accessStatus === 'DISABLED') {
        this.parent.ui.toast({
          type: 'info',
          message: 'Disclosure package sharing has been disabled.',
        });
      } else if (accessStatus === 'ENABLED') {
        this.parent.ui.toast({
          type: 'success',
          message: 'Disclosure package sharing has been enabled.',
        });
      }
    } catch (err) {
      this.parent.ui.wentWrong(err);
    }
  };
}
