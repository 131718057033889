

import React, { Component } from 'react';
import { Radio } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const clsPrefix = 'app-radio-button';
const groupClsPrefix = 'app-radio-group';

class AppRadioGroup extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className, ...otherProps } = this.props;

    return (
      <Radio.Group
        className={classNames(groupClsPrefix, className)}
        {...otherProps}
      />
    );
  }
}

export class AppRadio extends Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    size: 'default',
  };

  static Group = AppRadioGroup;
  static Button = Radio.Button;

  render() {
    const { className, size, ...otherProps } = this.props;

    return (
      <Radio
        className={classNames(clsPrefix, className, `${clsPrefix}--${size}`)}
        {...otherProps}
      />
    );
  }
}
