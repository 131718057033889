import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export default (Comp) => {
  class LoadingWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
      };
    }

    handleAsync = (asyncToRun) => async (...args) => {
      this.setState({
        loading: true,
      });

      let res;
      try {
        res = await asyncToRun(...args);
      } catch (e) {
        if (this.mounted) {
          this.setState({
            loading: false,
          });
        }
        throw e;
      } finally {
        if (this.mounted) {
          this.setState({
            loading: false,
          });
        }
      }
      return res;
    };
    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      return (
        <Comp
          loading={this.state.loading}
          handleAsync={this.handleAsync}
          {...this.props}
        />
      );
    }
  }

  return hoistNonReactStatics(LoadingWrapper, Comp);
};
