export default function preventDefault(handler) {
  return (
    handler &&
    ((e, ...args) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      } else if (e && e.domEvent && e.domEvent.preventDefault) {
        e.domEvent.preventDefault();
      }
      return handler(e, ...args);
    })
  );
}
