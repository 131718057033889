import get from 'lodash/get';
import BoundOutput from './bound-output';
import { OUTPUT_HANDLERS } from './handlers';

export function bindOutput(bindings, output, renderOptions) {
  const handler = get(output, 'handler', 'identity');
  return new BoundOutput(
    bindings,
    new OUTPUT_HANDLERS[handler](handler),
    output,
    renderOptions
  );
}
