import get from 'lodash/get';
import has from 'lodash/has';
import merge from 'lodash/merge';
import values from 'lodash/values';
import { action, computed, makeObservable } from 'mobx';
import type { Field, ConditionalLink } from 'src/types/proto/reform';
import BoundField from './bound-field';
import type Form from './form';
import type Namespace from './namespace';
import type BoundOutput from './outputs/bound-output';
import type { RenderOptions } from './render-options';

type NamespaceWithGetFieldIds = Namespace & {
  getFieldIds?(): string[];
};

export default class BoundForm {
  /*
     A BoundForm takes a form and the namespaces containing
     the actual data and creates BoundOutput objects which are
     what is actually needed by the PdfOutput components.

     The bound form depends on other BoundForms, for instance the
     form depends on the BoundForm for each transaction namespace.

     dependencies is a map {namepaceId: BoundForm(...)} which will
     be delegated to in case of a linked field
  */
  namespace: NamespaceWithGetFieldIds;
  form: Form;
  dependencies: Record<string, BoundForm>;
  private _boundFields: Map<string, BoundField>;

  constructor(
    namespace: NamespaceWithGetFieldIds,
    form: Form,
    dependencies?: Record<string, BoundForm>
  ) {
    makeObservable(this);
    this.namespace = namespace;
    this.form = form;
    this.dependencies = dependencies || {};

    this._boundFields = new Map();
  }

  unstage() {
    this.namespace.unstage();
    values(this.dependencies).forEach((boundForm) => boundForm.unstage());
  }

  @computed
  get isDirty(): boolean {
    return (
      this.namespace.isDirty || values(this.dependencies).some((x) => x.isDirty)
    );
  }

  getStaged() {
    const ownData = this.namespace.getStaged();
    const res = {
      [this.namespace.id]: ownData,
    };
    values(this.dependencies).forEach((boundForm) => {
      merge(res, boundForm.getStaged());
    });
    get(this.form, 'fields', []).forEach((field) => {
      // this is not strictly needed because these fields
      // are going to be overwritten by linking on
      // the backend anyway. It is only used for the
      // consistency check that looks for inconsistencies
      // between the front-end display and the backend render
      if (!field.linkId || !field.linkNamespace) {
        return;
      }
      const boundField = this.getBoundFieldById(field.id);
      if (!boundField?.linkedOutput || boundField.isUnlinked) {
        return;
      }
      const hasStagedChange = boundField.linkedOutput.boundFields.some(
        (bf: BoundField) => {
          return has(res, [bf.namespace.id, bf.fieldId]);
        }
      );
      if (hasStagedChange) {
        res[this.namespace.id][boundField.fieldId] = boundField.value ?? null;
      }
    });
    return res;
  }

  getStagedUnlinkedIds() {
    return this.namespace.getStagedUnlinkedIds();
  }

  getStagedLinkedIds() {
    return this.namespace.getStagedLinkedIds();
  }

  getAlternativeBoundOutputs = (field: Field) => {
    const alternativeLinks: (Field | ConditionalLink)[] = [
      ...(field.conditionalLinking ?? []),
    ];
    if (field.linkId) {
      alternativeLinks.push(field);
    }
    const alternativeOutputsByNsLinkId: Record<
      string,
      Record<string, BoundOutput>
    > = {};
    alternativeLinks.forEach((cond) => {
      const boundForm =
        cond.linkNamespace === this.namespace.id
          ? this
          : this.dependencies[cond.linkNamespace];
      const boundOutput = boundForm
        ? boundForm.getBoundOutput(cond.linkId)
        : null;
      if (!boundOutput) {
        return;
      }
      if (!alternativeOutputsByNsLinkId[cond.linkNamespace]) {
        alternativeOutputsByNsLinkId[cond.linkNamespace] = {};
      }
      alternativeOutputsByNsLinkId[cond.linkNamespace][cond.linkId] =
        boundOutput;
    });
    return alternativeOutputsByNsLinkId;
  };

  getBoundField = (field: Field) => {
    if (!this._boundFields.has(field.id)) {
      const alternativeOutputs = this.getAlternativeBoundOutputs(field);
      this._boundFields.set(
        field.id,
        new BoundField(this, field, alternativeOutputs)
      );
    }
    return this._boundFields.get(field.id);
  };

  getBoundFieldById = (fieldId: string) => {
    return this.getBoundOutputForField(fieldId)?.getField('this');
  };

  getBoundOutputForField = (fieldId: string) => {
    return this.form.getBoundOutput(fieldId, this.getBoundField);
  };

  getBoundOutput = (outputId: string) => {
    return this.form.getBoundOutput(outputId, this.getBoundField);
  };

  @action
  setRenderOptions = (renderOptions: RenderOptions) => {
    this.form.setRenderOptions(renderOptions);
    Object.values(this.dependencies).forEach((boundForm) => {
      boundForm.form.setRenderOptions(renderOptions);
    });
  };
}
