import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { EmailList, EmailMessage } from 'src/types/proto/emails';
import type { GetEmailListRequest } from 'src/types/proto/services/mailer_admin_service';
import BaseApi from '../base-api';

export default class Emails extends BaseApi {
  searchAll({ query, cursor, limit }: GetEmailListRequest) {
    const params = omitBy(
      {
        query,
        cursor,
        limit,
      },
      isNil
    );

    return this.get<EmailList>('/mailer/emails', {
      params,
    });
  }

  getEmail(emailId: string) {
    return this.get<EmailMessage>(
      `/mailer/emails/${encodeURIComponent(emailId)}`
    );
  }

  checkEmailStatus(emailId: string) {
    return this.post<EmailMessage>(
      `/mailer/emails/${encodeURIComponent(emailId)}/activity`
    );
  }

  resendEmail(emailId: string, recipients: string[]) {
    return this.post<EmailMessage>(
      `/mailer/emails/${encodeURIComponent(emailId)}/retry`,
      { recipients }
    );
  }
}
