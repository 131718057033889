export const LegalNameOptions = {
  NAME_WITHOUT_ENTITY: '',
  LEGAL_NAME_ENTITY_ONLY: 'LEGAL_NAME_ENTITY_ONLY',
  LEGAL_NAME_INCLUDE_ENTITY: 'LEGAL_NAME_INCLUDE_ENTITY',
} as const;

export type LegalNameValues =
  typeof LegalNameOptions[keyof typeof LegalNameOptions];

// NB: Ideally, these RenderOptions would just be the same type
// as the TransactionSettings defined in jsapp/src/types/proto/transaction_settings.
// That would work if we defined an enum for possible values in protos/src/transaction_settings.proto
// But, one of the options is a blank string (LegalNameOptions.NAME_WITHOUT_ENTITY),
// and there is no way to define a protobuf field to represent that, so instead
// we add this more specific definition here.
export type RenderOptions = {
  legalNameFormat: LegalNameValues;
  joinedLegalNameFormat: LegalNameValues;
};
