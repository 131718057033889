import { type ReactElement } from 'react';
import { renderToString } from 'react-dom/server';

export default function textFromReactElem(elem: unknown) {
  if (typeof elem === 'string') {
    return elem;
  }
  let elemText = null;
  if (typeof elem === 'object') {
    try {
      const htmlString = renderToString(elem as ReactElement);
      elemText = htmlString.replace(/<\/?[^>]+>/g, '');
    } catch (unusedErr) {
      // do nothing
    }
  }
  return elemText;
}
