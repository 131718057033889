import type { SnakeCasedPropertiesDeep } from 'type-fest';
import type {
  AuditListItem,
  GetAuditItemsRequest,
  GetAuditListRequest,
  GetAuditListResponse,
  GetAuditTablesResponse,
  ListItemVersionsRequest,
} from 'src/types/proto/services/audit_admin_service';
import type { ApiResponseData } from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import BaseApi from '../base-api';

export default class Audit extends BaseApi {
  getAuditTables = () => {
    return this.get<ApiResponseData<GetAuditTablesResponse>>('/audit/tables');
  };

  getAuditList = (params: GetAuditListRequest) => {
    return this.get<SnakeCasedPropertiesDeep<GetAuditListResponse>>(
      '/audit/list',
      {
        params: formatToSnakeCase(params),
      }
    );
  };

  listItemVersions = (params: ListItemVersionsRequest) => {
    return this.get<SnakeCasedPropertiesDeep<AuditListItem[]>>(
      '/audit/versions',
      {
        params: formatToSnakeCase(params),
      }
    );
  };

  getAuditItems = (params: GetAuditItemsRequest) => {
    return this.get<SnakeCasedPropertiesDeep<AuditListItem[]>>('/audit/items', {
      params: formatToSnakeCase(params),
    });
  };
}
