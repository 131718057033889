import type {
  GetAggregateItemListRequest,
  GetAggregateItemRequest,
} from 'src/types/proto/services/aggregate_service';
import type { TransactionAuditDetail } from 'src/types/proto/services/transaction_admin_service';
import type { DeepPartial } from 'src/types/utils';
import BaseApi from '../base-api';

type DefaultGetItemListParams = Partial<
  Omit<GetAggregateItemListRequest, 'aggregateKind' | 'aggregateId' | 'kind'>
>;
type DefaultGetItemParams = Partial<
  Omit<GetAggregateItemRequest, 'aggregateKind' | 'aggregateId' | 'kind'>
>;

export default abstract class AggregatesApi<
  Detail,
  Intent,
  DispatchResponse,
  ItemKind,
  ItemList,
  Item
> extends BaseApi {
  abstract prefix: string;

  getAggregateUrl(aggregateId: string | number) {
    return `/${this.prefix}/${encodeURIComponent(aggregateId)}`;
  }
  getAggregate(aggregateId: string, params: unknown) {
    return this.get<Detail>(this.getAggregateUrl(aggregateId), {
      params,
    });
  }

  create(intent: DeepPartial<Intent>) {
    return this.post<DispatchResponse>(`/${this.prefix}`, intent);
  }

  getAudit(aggregateId: string) {
    return this.get<TransactionAuditDetail[]>(
      `${this.getAggregateUrl(aggregateId)}/get_audit`
    );
  }

  dispatch(aggregateId: string, intent: DeepPartial<Intent>) {
    return this.post<DispatchResponse>(
      `${this.getAggregateUrl(aggregateId)}/dispatch`,
      intent
    );
  }

  fetchItems<Params = DefaultGetItemListParams>(
    aggregateId: string,
    kind: ItemKind,
    params: Params
  ) {
    return this.get<ItemList>(`${this.getAggregateUrl(aggregateId)}/${kind}`, {
      params,
    });
  }

  fetchItem<Params = DefaultGetItemParams>(
    aggregateId: string,
    kind: ItemKind,
    itemId: string,
    params: Params
  ) {
    return this.get<Item>(
      `${this.getAggregateUrl(aggregateId)}/${kind}/${itemId}`,
      {
        params,
      }
    );
  }
}
