import capitalize from 'lodash/capitalize';
import toLower from 'lodash/toLower';
import integerToWords from 'src/utils/integer-to-words';
import Field from './base';
import fieldOutput from './field-output';

export default class Currency extends Field {
  static outputs = [
    fieldOutput('no_dollar_sign', 'string_format', 'string'),
    fieldOutput('with_dollar_sign', 'string_format', 'string'),
    fieldOutput('words', 'string_format', 'string'),
    fieldOutput('words_with_dollars', 'string_format', 'string'),
  ];

  renderString(value) {
    const format = this.params.format || 'no_dollar_sign';
    function words(v) {
      if (!v) {
        return '';
      }
      const [dollars, cents] = v.split('.');
      const dollarsDecimalStr = dollars.replace(/,/g, '');
      let toWords;
      try {
        toWords = integerToWords(dollarsDecimalStr);
      } catch (error) {
        if (error instanceof RangeError) {
          return error.message;
        }
        return 'Error converting numeric value to text';
      }
      const dollarStr = toLower(toWords).replace(/\w+/g, capitalize);
      if (!cents || cents === '00') {
        return dollarStr;
      }
      return `${dollarStr} ${cents}/100`;
    }

    const formaters = {
      no_dollar_sign: (v) => {
        const withDecimal = v
          ? Number(v).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
          : '';
        return withDecimal;
      },
      with_dollar_sign: (v) => {
        const withDecimal = v
          ? `$${Number(v).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`
          : '';
        return withDecimal;
      },
      words,
      words_with_dollars: (v) => (v ? `${words(v)} Dollars` : ''),
    };
    return (formaters[format] || ((v) => v || ''))(value);
  }
}
