import React, { Component } from 'react';
import { Progress } from 'antd';
import omit from 'lodash/omit';
import { observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';

@observer
export default class FakeProgress extends Component {
  static propTypes = {
    startTime: PropTypes.any,
    estimatedTime: PropTypes.any.isRequired,
    isComplete: PropTypes.bool,
    remountOnUpdate: PropTypes.bool,
    exponential: PropTypes.bool,
  };

  static defaultProps = {
    remountOnUpdate: true,
    exponential: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      startTime: props.startTime || moment(),
      progress: undefined,
    };
  }

  setTime = () => {
    const { isComplete, exponential } = this.props;
    if (isComplete) {
      this.setState({
        progress: 100,
      });
      return;
    }

    const progress = exponential
      ? 1 -
        Math.exp((this.state.startTime - moment()) / this.props.estimatedTime)
      : Math.min(
          (moment() - this.state.startTime) / this.props.estimatedTime,
          0.99
        );
    this.setState({
      progress: Math.floor(progress * 100),
    });
  };

  componentDidMount() {
    const { isComplete } = this.props;
    if (!isComplete) {
      this.interval = setInterval(this.setTime, 250);
    }
    this.setTime();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const progressBarProps = omit(this.props, [
      'startTime',
      'estimatedTime',
      'isComplete',
      'remountOnUpdate',
      'exponential',
    ]);
    return (
      <Progress
        key={this.props.remountOnUpdate ? this.state.progress : null}
        percent={this.state.progress}
        {...progressBarProps}
      />
    );
  }
}
