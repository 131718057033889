import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import type { FormConfigItem } from 'src/types/proto/reform';
import Item from './item';
import type Section from './section';
import type FormConfig from '.';

export default class Term extends Model<FormConfigItem> {
  id: string;
  formConfig: FormConfig;
  section: Section;

  constructor(
    formConfig: FormConfig,
    section: Section,
    json: FormConfigItem,
    id: string
  ) {
    super(json);
    makeObservable(this);
    this.formConfig = formConfig;
    this.section = section;
    this.id = id;
  }

  @computed
  get store() {
    return this.formConfig.store;
  }

  @computed
  get boundForm() {
    return this.formConfig.boundForm;
  }

  @computed
  get number() {
    return this.data.num || '';
  }

  @computed
  get title() {
    return this.data.title || '';
  }

  @computed
  get description() {
    return this.data.description || '';
  }

  @computed
  get kind() {
    return this.data.kind;
  }

  @computed
  get outlineExclusive() {
    return this.data.outlineExclusive || false;
  }

  @computed
  get items() {
    return (this.data.term?.items || []).map(
      (item, idx) =>
        new Item(this.formConfig, this, item, `${this.id}-${idx + 1}`)
    );
  }

  @computed
  get visibleItems() {
    return this.items.filter((item) => item.isVisible);
  }

  @computed
  get isVisible() {
    return this.items.some((item) => item.isVisible);
  }

  @computed
  get filteredItems() {
    return this.visibleItems.filter((t) => t.matchesSearch);
  }

  @computed
  get matchesSearch() {
    return this.visibleItems.some((t) => t.matchesSearch);
  }
}
