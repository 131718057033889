import Field from './base';

export default class RecurringFee extends Field {
  renderString(value) {
    const format = this.params.format || 'recurring_fee';
    const RecurringFeeFormat = (v) => {
      const fee = v.fee || '0';
      let period = v.period || this.params.choices[0];
      period = (
        this.params.choices.find((e) => {
          return e.value === v.period;
        }) || this.params.choices[0]
      ).label;
      return `$ ${fee} ${period}`;
    };
    const formaters = {
      recurring_fee: RecurringFeeFormat,
      period: (v) => {
        return v.period;
      },
      fee: (v) => {
        return v.fee;
      },
    };
    return (formaters[format] || RecurringFeeFormat)(
      value || {
        fee: '',
        period: '',
      }
    );
  }
}
