import React, { Component } from 'react';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppIcon from 'src/components/common/app-icon';
import PromiseButton from 'src/components/common/promise-button';
import pickFile from 'src/utils/pick-file';
import personIcon from 'src/images/icons/person-product.svg';



const clsPrefix = 'app-avatar-uploader';

@inject('ui')
@observer
class AvatarUploader extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    circle: PropTypes.bool,
    imageMin: PropTypes.array,
    imageMax: PropTypes.array,
    className: PropTypes.string,
    transformations: PropTypes.object,
    readOnly: PropTypes.bool,
    buttonProps: PropTypes.object,
    showButton: PropTypes.bool,
    showAvatarIfEmpty: PropTypes.bool,
    showRemoveLink: PropTypes.bool,
    avatarWidth: PropTypes.number,
    avatarHeight: PropTypes.number,
    isUser: PropTypes.bool,
    title: PropTypes.string,
    onEditTitle: PropTypes.func,
  };
  static defaultProps = {
    imageMin: [600, 600],
    showAvatarIfEmpty: true,
    showButton: false,
    showRemoveLink: true,
    avatarWidth: 100,
    avatarHeight: 100,
    isUser: false,
  };
  state = {
    loading: false,
  };
  handleUpload = async () => {
    if (this.props.readOnly) {
      return;
    }
    const { imageMin, imageMax, transformations } = this.props;

    const file = await pickFile({
      maxFiles: 1,
      accept: 'image/*',
      ...(imageMin
        ? {
            imageMin,
          }
        : null),
      ...(imageMax
        ? {
            imageMax,
          }
        : null),
      ...(transformations
        ? {
            transformations,
          }
        : null),
    });
    if (file) {
      this.props.onChange(file.url);
    }
  };
  render() {
    const {
      ui,
      isUser,
      showAvatarIfEmpty,
      showButton,
      showRemoveLink,
      buttonProps,
      className,
      circle,
      readOnly,
      avatarWidth,
      avatarHeight,
      title,
      onEditTitle,
    } = this.props;

    const imageUrl = this.props.value;

    const avatarPlaceholder = isUser ? (
      <div className={`${clsPrefix}__avatar-placeholder`}>
        <AppIcon
          type="svg"
          svg={personIcon}
          size={Math.ceil(avatarHeight * 0.58)}
        />
      </div>
    ) : (
      <div className={`${clsPrefix}__avatar-placeholder`}>
        {this.state.loading ? (
          <LoadingOutlined />
        ) : (
          <UploadOutlined style={{ fontSize: avatarHeight * 0.3 }} />
        )}
        <div className={`${clsPrefix}__avatar-placeholder-text`}>Upload</div>
      </div>
    );

    const avatar = (
      <div
        className={`${clsPrefix}__avatar-wrap`}
        style={{
          width: avatarWidth,
          height: avatarHeight,
        }}
        onClick={this.handleUpload}
        role="button"
      >
        {imageUrl ? (
          <div
            className={`${clsPrefix}__image`}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          />
        ) : (
          avatarPlaceholder
        )}
      </div>
    );

    const button = (
      <PromiseButton
        className={`${clsPrefix}__button`}
        size={ui.isMobileSize ? 'small' : 'large'}
        {...buttonProps}
        children={imageUrl ? 'Replace' : 'Upload photo'}
        onClick={this.handleUpload}
        loading={this.state.loading}
      />
    );

    return (
      <div
        className={classNames(clsPrefix, className, {
          [`${clsPrefix}--exists`]: !!imageUrl,
          [`${clsPrefix}--read-only`]: readOnly,
          [`${clsPrefix}--circle`]: circle,
        })}
      >
        {showAvatarIfEmpty || imageUrl ? avatar : null}
        <div className={`${clsPrefix}__side`}>
          {title && (
            <div className={`${clsPrefix}__title-wrap`}>
              {title}
              {onEditTitle ? (
                <AppIcon
                  size={16}
                  className={`${clsPrefix}__edit-title-icon`}
                  name="edit-3"
                  onClick={onEditTitle}
                />
              ) : null}
            </div>
          )}
          <div className={`${clsPrefix}__cta-wrap`}>
            {!this.props.readOnly && showButton && button}
            {this.props.readOnly || !imageUrl || !showRemoveLink ? null : (
              <Button
                danger
                size={ui.isMobileSize ? 'small' : 'large'}
                onClick={() => this.props.onChange('')}
              >
                Remove
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AvatarUploader;
