import type {
  GetIdpKindResponse,
  GetIdpKindRequest,
} from 'src/types/proto/services/external_auth_public_service';
import type { ApiResponseData } from 'src/types/utils';
import BaseApi from '../base-api';

export default class ExternalAuth extends BaseApi {
  getIdpKind({ email }: GetIdpKindRequest) {
    return this.get<ApiResponseData<GetIdpKindResponse>>(
      `/external_auth/get_idp_kind/${email}`
    );
  }
}
