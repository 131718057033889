const codes = {
  UNKNOWN: 2,
  INVALID_ARGUMENT: 3,
  DEADLINE_EXCEEDED: 4,
  NOT_FOUND: 5,
  ALREADY_EXISTS: 6,
  INTEGRITY_ERROR: 6,
  PERMISSION_DENIED: 7,
  UNAUTHENTICATED: 16,
  FAILED_PRECONDITION: 9,
  ABORTED: 10,
  OUT_OF_RANGE: 11,
  UNIMPLEMENTED: 12,
  UNAVAILABLE: 14,
  PAYMENT_ERROR: 17,
  ACCOUNT_LOCKED: 18,
  EXTERNAL_SIGN_IN_REQUIRED: 19,
} as const;

export default codes;
