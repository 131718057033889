import flatten from 'lodash/flatten';
import api from 'src/api';
import adminApi from 'src/api/admin-api';
import DocumentEditUIState from 'src/components/transactions/documents/document-editor/ui-state';
import BoundForm from 'src/models/fields/bound-form';
import Form from 'src/models/fields/form';
import Namespace from 'src/models/fields/namespace';
import FormConfig from 'src/models/forms/form-config';

const { FORM_NAMESPACE } = window.Glide.CONSTANTS;

class FakeFormDataSource {
  namespace = FORM_NAMESPACE;
  constructor(data) {
    this.data = data || {};
  }

  getFieldValue(fieldId) {
    return this.data[fieldId];
  }

  getFieldIsUnlinked(_fieldId) {
    return false;
  }

  getFieldIds() {
    return Object.keys(this.data);
  }
}

function getFlowFieldsDataNamespace(flowFields, formConfig, store = {}) {
  let dataSource = {};
  let _formConfig;
  if (formConfig && flowFields) {
    if (formConfig instanceof FormConfig) {
      _formConfig = formConfig;
    } else {
      _formConfig = new FormConfig(store, formConfig);
    }
    Object.keys(flowFields).forEach((fieldId) => {
      const formConfigItem = _formConfig.itemsByFieldId[fieldId];
      if (!formConfigItem) {
        return;
      }
      const [, fieldsValue] = formConfigItem.toBoundFieldValue(
        flowFields[fieldId]
      );
      dataSource = {
        ...dataSource,
        ...fieldsValue,
      };
    });
  }
  return new Namespace(new FakeFormDataSource(dataSource));
}

export async function updateBoundFormFlowNamespaceFlowFields(
  boundForm,
  flowFields,
  formUuid,
  store
) {
  const { fillConfigs } = store;
  const fillConfig = await fillConfigs.getFetchFillConfigByFormUuid.getOrFetch({
    formUuid,
  });
  const { formConfig } = fillConfig || {};
  if (boundForm.namespace.source instanceof FakeFormDataSource) {
    const namespaceObject = getFlowFieldsDataNamespace(
      flowFields,
      formConfig.data,
      store
    );
    boundForm.namespace.source = namespaceObject.source;
  }
  // At this point the document was already saved
  // and we don't need to keep any of the staged values.
  boundForm.unstage();
}

export async function getTransactionDocumentData(
  transactionId,
  transactionDocumentId,
  store
) {
  const { transactions, transactionFields } = store;

  const [transaction, transactionSettings, transactionDocument, folders] =
    await Promise.all([
      transactions.getOrFetchTransaction(transactionId),
      transactions.getFetchTransactionSettings.getOrFetch({
        transactionId,
      }),
      transactions.getOrFetchItem(
        transactionId,
        'TRANSACTION_DOCUMENT',
        transactionDocumentId
      ),
      transactions.getFetchItems.getOrFetch({
        transactionId,
        kind: 'FOLDER',
      }),
    ]);

  await transactions.getOrFetchItem(
    transactionId,
    'TRANSACTION_DOCUMENT_VERSION',
    transactionDocument.latestVersionId
  );
  let fillConfig;
  let formOutline;
  let formConfig;
  let disableAddendums;

  if (transactionDocument.latestVersion.form) {
    const { namespace, itemId } =
      transactionDocument.itemNamespace.namespaceScope;
    fillConfig = (
      await Promise.all([
        store.fillConfigs.getFetchFillConfigByFormUuid.getOrFetch({
          formUuid: transactionDocument.latestVersion.form.uuid,
          td: transactionDocument,
        }),
        itemId
          ? transactions.getOrFetchItem(
              transactionId,
              transactionFields.getNamespaceItemKind(namespace),
              itemId
            )
          : transactions.getFetchItems.getOrFetch({
              transactionId,
              kind: 'TRANSACTION_FIELDS',
            }), // hack to make sure two network calls that would otherwise
        // be serialized are in parallel (transactionFields.getFetchTransactionReformForms)
      ])
    )[0];
  }

  let transactionReformForms = {};
  const requiredTransactionNamespaces =
    fillConfig &&
    fillConfig.getRequiredTransactionNamespaces(transactionDocument);
  if (requiredTransactionNamespaces && requiredTransactionNamespaces.length) {
    transactionReformForms =
      await transactionFields.getFetchTransactionReformForms.getOrFetch({
        transactionId,
        namespaces: requiredTransactionNamespaces,
      });
  }
  if (fillConfig) {
    formOutline = fillConfig.getFormOutline({
      transactionDocument,
      transactionSettings,
      transactionReformForms,
    });
    formConfig = fillConfig.formConfig;
    disableAddendums = fillConfig.toJS().disableAddendums;
  }

  const documentEditUIState = new DocumentEditUIState();

  const isPspdfkitViewerEnabled = true;
  return {
    transaction,
    transactionDocument,
    folders,
    documentEditUIState,
    isPspdfkitViewerEnabled,
    fillConfig,
    formOutline,
    transactionReformForms,
    transactionSettings,
    formConfig,
    disableAddendums,
  };
}

export async function getTransactionFormData(
  transactionId,
  formId,
  formUuid,
  store,
  flowFields
) {
  const { transactions, fillConfigs, transactionFields } = store;
  const [transaction, transactionSettings, libraries] = await Promise.all([
    transactions.getOrFetchTransaction(transactionId),
    transactions.getFetchTransactionSettings.getOrFetch({
      transactionId,
    }),
    formUuid
      ? Promise.resolve()
      : transactions.getFetchTransactionForms.getOrFetch({ transactionId }),
  ]);
  let _formUuid;
  if (formUuid) {
    _formUuid = formUuid;
  } else {
    const forms = flatten(libraries.libraries.map((l) => l.forms))[0];
    const form = forms.find((f) => f.id === formId);
    _formUuid = form.uuid;
  }
  const fillConfig = await fillConfigs.getFetchFillConfigByFormUuid.getOrFetch({
    formUuid: _formUuid,
  });
  const { formOutline, formConfig, reformFormPrepared, reformForm } =
    fillConfig || {};
  const namespaceObject = getFlowFieldsDataNamespace(
    flowFields,
    formConfig,
    store
  );

  let transactionReformForms = {};
  const requiredTransactionNamespaces =
    fillConfig?.getRequiredTransactionNamespaces();
  if (requiredTransactionNamespaces && requiredTransactionNamespaces.length) {
    transactionReformForms =
      await transactionFields.getFetchTransactionReformForms.getOrFetch({
        transactionId,
        namespaces: requiredTransactionNamespaces,
      });
  }

  return {
    transaction,
    fillConfig,
    formOutline,
    reformForm,
    reformFormPrepared,
    transactionSettings,
    formConfig,
    namespaceObject,
    transactionReformForms,
  };
}
export async function getFormData(formId, flowFields) {
  const form = (await adminApi.documents.getForm(formId))?.data;
  const fillConfig =
    (await api.documents.getFillConfigByFormUuid(form.uuid))?.data || {};
  const {
    formOutline,
    formConfig,
    reformFormPrepared,
    reformForm,
    disableAddendums,
  } = fillConfig;

  const namespaceObject = getFlowFieldsDataNamespace(flowFields, formConfig);

  return {
    fillConfig,
    formOutline,
    reformForm,
    reformFormPrepared,
    formConfig,
    namespaceObject,
    disableAddendums,
  };
}

export function getDocumentBoundForm({
  dependentForms,
  mainNamespace,
  mainForm,
  renderOptions,
}) {
  return new BoundForm(
    mainNamespace,
    new Form(mainForm, renderOptions),
    dependentForms
  );
}

export function getTransactionDocumentBoundForm({
  transactionDocument,
  transactionReformForms,
  fillConfig,
  transactionSettings,
}) {
  return getDocumentBoundForm({
    dependentForms: transactionReformForms,
    mainNamespace: transactionDocument.formNamespace,
    mainForm: fillConfig.reformFormPrepared,
    renderOptions: transactionSettings?.renderOptions,
  });
}

export async function getBoundReformForm(
  transactionId,
  tdId,
  namespaces,
  store,
  formId,
  formUuid,
  flowFields
) {
  if (transactionId && tdId) {
    const {
      transactionDocument,
      fillConfig,
      transactionReformForms,
      transactionSettings,
    } = await getTransactionDocumentData(transactionId, tdId, store);
    return getTransactionDocumentBoundForm({
      transactionDocument,
      transactionReformForms,
      fillConfig,
      transactionSettings,
    });
  }
  if (transactionId && (formId || formUuid)) {
    const {
      transactionSettings,
      reformFormPrepared,
      namespaceObject,
      transactionReformForms,
    } = await getTransactionFormData(
      transactionId,
      formId,
      formUuid,
      store,
      flowFields
    );
    const boundForm = getDocumentBoundForm({
      dependentForms: transactionReformForms,
      mainNamespace: namespaceObject,
      mainForm: reformFormPrepared,
      renderOptions: transactionSettings?.renderOptions,
    });
    return boundForm;
  }
  if (formId) {
    const { reformFormPrepared, namespaceObject } = await getFormData(
      formId,
      flowFields
    );
    const boundForm = getDocumentBoundForm({
      dependentForms: [],
      mainNamespace: namespaceObject,
      mainForm: reformFormPrepared,
    });
    return boundForm;
  }
  const boundForms =
    await store.transactionFields.getFetchTransactionReformForms.getOrFetch({
      transactionId,
      namespaces,
    });
  return Object.values(boundForms)[0];
}
