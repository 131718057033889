import type {
  AddressVerificationRequest,
  Address,
} from 'src/types/proto/properties';
import BaseApi from '../base-api';

export default class Addresses extends BaseApi {
  verify(request: Partial<AddressVerificationRequest>) {
    return this.post<Address>('/addresses/verify', request);
  }

  getCountyOptions(request: Partial<AddressVerificationRequest>) {
    return this.post<Array<string>>(`/addresses/county_options`, request);
  }
}
