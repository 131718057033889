import AnchorButton from 'src/components/common/anchor-button';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import preventDefault from 'src/utils/prevent-default';
import stopPropagation from 'src/utils/stop-propagation';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { inject, observer } from 'mobx-react';

const clsPrefix = 'app-clipboard-link';

@inject('ui')
@observer
export default class ClipboardLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    message: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.any,
    ui: PropTypes.object.isRequired,
  };

  handleCopy = () => {
    const { ui, description, message } = this.props;
    ui.toast({
      message: message || `Copied ${description || ''} to clipboard`,
      type: 'success',
    });
  };

  render() {
    const { children, className, text, ...linkProps } = this.props;
    return (
      <CopyToClipboard onCopy={this.handleCopy} text={text || children}>
        <AnchorButton
          type="primary"
          {...linkProps}
          className={classNames(clsPrefix, className)}
          onClick={stopPropagation(preventDefault)}
        >
          {children}
        </AnchorButton>
      </CopyToClipboard>
    );
  }
}
