import capitalize from 'lodash/capitalize';
import { computed, makeObservable } from 'mobx';
import type { PermissionEnum } from 'src/types/proto/permissions';
import type { Field, ConditionalLink, Condition } from 'src/types/proto/reform';
import evalCondition from 'src/utils/eval-condition';
import type BoundForm from './bound-form';
import type { Validation } from './namespace';
import type BoundOutput from './outputs/bound-output';

const normalizedFieldKind = (kind: string) => {
  const mapping = {
    __default: 'text',
    text: 'text',
    string: 'text',
    string_list: 'checkbox',
    boolean: 'checkbox',
    simple_string_choice: 'radio',
    radio: 'radio',
    checkbox: 'checkbox',
  };
  return mapping[(kind as keyof typeof mapping) || '__default'] || 'text';
};

export type AlternativeLinkedOutputs = Record<
  string,
  Record<string, BoundOutput>
>;

export default class BoundField {
  /*
    A BoundField is a simple wrapper providing access
    to one field of a namespace. It also lets you set
    (that is, stage) the value of the bound field.

    The BoundOutput will get these objects when it
    imports a particular field from a namespace
  */
  boundForm: BoundForm;
  field: Field;
  alternativeLinkedOutputs: AlternativeLinkedOutputs;

  constructor(
    boundForm: BoundForm,
    field: Field,
    linkedOutputs: AlternativeLinkedOutputs
  ) {
    makeObservable(this);
    this.field = field;
    this.boundForm = boundForm;
    this.alternativeLinkedOutputs = linkedOutputs;
  }

  @computed
  get namespace() {
    return this.boundForm.namespace;
  }

  get kind() {
    return this.field.kind;
  }

  get fieldId() {
    return this.field.id;
  }

  get conditionalLinks() {
    return this.field.conditionalLinking ?? [];
  }

  @computed
  get currentLink() {
    // finds the first conditional link fulfilling condition
    let link: Field | ConditionalLink | undefined = this.conditionalLinks.find(
      this.isConditionFulfilled
    );
    // if no conditional link matches, returns the field (as both conditional link
    // and field have the attributes linkId and linkNamespace)
    if (!link) {
      link = this.field;
    }
    return link;
  }

  @computed
  get linkId() {
    return this.currentLink.linkId;
  }

  @computed
  get linkNamespace() {
    return this.currentLink.linkNamespace;
  }

  @computed
  get linkedOutput(): BoundOutput | null {
    if (!this.linkId && !this.linkNamespace) {
      return null;
    }
    const ns = this.alternativeLinkedOutputs[this.linkNamespace];
    if (!ns) {
      return null;
    }
    return ns[this.linkId] ?? null;
  }

  get params() {
    return this.field.params || {};
  }

  get optional() {
    return this.field.optional ?? false;
  }

  get defaultValue() {
    return this.field.defaultValue ?? null;
  }

  @computed
  get isNa() {
    if (
      (this.condition as Condition).terms &&
      (this.condition as Condition).terms.length
    ) {
      const conditionedFields = (this.condition as Condition).terms.map(
        (term) => this.boundForm.getBoundFieldById(term)
      );
      if (conditionedFields.some((f) => f?.isNa)) {
        return true;
      }
      return evalCondition(
        conditionedFields.map((f) => f && f.value),
        (this.condition as Condition).rule
      );
    }

    return false;
  }

  @computed
  get condition(): Condition | Record<string, unknown> {
    return this.field.condition || {};
  }

  @computed
  get fillConditions() {
    return this.field.fillConditions || [];
  }

  @computed
  get value() {
    const v =
      this.linkedOutput && !this.isUnlinked
        ? this.linkedOutput.pdfValue
        : this.namespace.get(this.fieldId);

    if (this.isNa) {
      if ((this.condition as Condition).value === v) {
        return v;
      }
      return null;
    }

    return v;
  }

  @computed
  get topLinkedValue() {
    const linkedField = this.linkedOutput?.getField('this');
    const v =
      linkedField && !this.isUnlinked
        ? linkedField.value
        : this.namespace.get(this.fieldId);

    if (this.isNa) {
      if ((this.condition as Condition).value === v) {
        return v;
      }
      return null;
    }

    return v;
  }

  @computed
  get validation() {
    return this.namespace.getStagedValidation(this.fieldId);
  }

  isValueSet() {
    const nsFields = this.namespace.getFieldIds
      ? this.namespace.getFieldIds()
      : [];
    return this.value != null || nsFields.includes(this.fieldId);
  }

  @computed
  get isUnlinked() {
    return this.namespace.getIsUnlinked(this.fieldId);
  }

  @computed
  get permissions() {
    const r = this.field.permissions || [];
    if (!r.length) {
      r.push('ACT_AS_ADMIN');
    }

    return r;
  }

  canSetValue(permissions: PermissionEnum[]) {
    if (!this.permissions.length) {
      return true;
    }

    return permissions.reduce(
      (m, p) => m || this.permissions.includes(p),
      false
    );
  }

  set(value: Field) {
    return this.namespace.stage({
      [this.fieldId]: value,
    });
  }

  setValidation(value: Validation) {
    return this.namespace.stageValidation({
      [this.fieldId]: value,
    });
  }

  setIsUnlinked(value: boolean) {
    return this.namespace.stageIsUnlinked(this.fieldId, value);
  }

  isConditionFulfilled = (condition: Condition) => {
    const ruleTerms = condition.terms.map((term) =>
      this.boundForm.getBoundFieldById(term)
    );
    return evalCondition(
      ruleTerms.map((f) => f && f.value),
      condition.rule
    );
  };

  @computed
  get missingFillConditions() {
    const conditions = this.fillConditions;
    return conditions.filter((cond) => !this.isConditionFulfilled(cond));
  }

  @computed
  get userFriendlyLabel() {
    const label = this.field.label;
    if (label && label !== this.field.id) {
      return label;
    }
    if (!this.linkedOutput) {
      return `${capitalize(normalizedFieldKind(this.kind))} Field`;
    }
    if (this.linkedOutput.label) {
      return this.linkedOutput.label;
    }
    return `${capitalize(this.linkedOutput.normalizedKind as string)} Field`;
  }
}
