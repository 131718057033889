import type { SnakeCasedProperties } from 'type-fest';
import type {
  DefaultRoleEnum,
  NotificationSettings,
  OboProfile,
  Team,
  User,
  UserConfig,
  UserEmail,
  UserTemplates,
  UserTemplatesCategory,
} from 'src/types/proto/auth';
import type {
  BillingInfo,
  BillingSubscriptionRequest,
  BillingSubscriptionResponse,
  Coupon,
} from 'src/types/proto/billing';
import type { Reminder } from 'src/types/proto/reminders';
import type { Route } from 'src/types/proto/routes';
import type {
  AddEmailRequest,
  BillingSourceChangeRequest,
  BillingSubscriptionChangeRequest,
  FindTemplateByCategoryResponse,
  GetBridgeRiCredentialsResponse,
  GetOboProfileTransactionSettingsResponse,
  GetNarCredentialsResponse,
  GetNwmlsCredentialsResponse,
  GetNJCredentialsResponse,
  QueryEmailRequest,
  QueryOboProfileRequest,
  RegisterWebinarRequest,
  SetBridgeRiCredentialRequest,
  SetNarCredentialRequest,
  SetNwmlsCredentialsRequest,
  SetNJCredentialsRequest,
  UpdateOboProfileTransactionSettingsRequest,
  UpdateOptOutDateSaleRequest,
  UpdateProfileInfoRequest,
} from 'src/types/proto/services/account';
import type { TransactionSettings } from 'src/types/proto/transaction_settings';
import type {
  ApiRequestBody,
  ApiQueryParams,
  EnumValues,
  ApiResponseData,
} from 'src/types/utils';
import BaseApi from '../base-api';

export default class Account extends BaseApi {
  fetchMe() {
    return this.get<User>('/account/me');
  }

  fetchMyTeams() {
    return this.get<Team[]>('/account/teams');
  }

  updateProfileInfo(
    contact: ApiRequestBody<UpdateProfileInfoRequest>,
    params: ApiQueryParams<UpdateProfileInfoRequest>
  ) {
    return this.post<User>(
      '/account/update_profile_info',
      contact,
      params
        ? {
            params,
          }
        : undefined
    );
  }

  updateConfig(config: Partial<UserConfig>) {
    return this.post<UserConfig>('/account/config', config);
  }

  getNarCredentials() {
    return this.get<SnakeCasedProperties<GetNarCredentialsResponse>>(
      '/account/nar_credentials'
    );
  }

  updateNarApiCredentials(nrdsid: string, lastname: string) {
    return this.post<boolean>('/account/nar_api_credentials', {
      nrdsid,
      lastname,
    } as SetNarCredentialRequest);
  }

  updateNJApiCredentials(nrdsid: string, lastname: string) {
    return this.post<boolean>('/account/nj_api_credentials', {
      nrdsid,
      lastname,
    } as SetNJCredentialsRequest);
  }

  getBridgeRiCredentials() {
    return this.get<SnakeCasedProperties<GetBridgeRiCredentialsResponse>>(
      '/account/bridge_ri_api_credentials'
    );
  }

  updateBridgeRiApiCredentials(nrdsId: string, lastName: string) {
    return this.post<boolean>('/account/bridge_ri_api_credentials', {
      nrdsId,
      lastName,
    } as SetBridgeRiCredentialRequest);
  }

  getNwmlsCredentials() {
    return this.get<SnakeCasedProperties<GetNwmlsCredentialsResponse>>(
      '/account/nwmls_credentials'
    );
  }

  getNJCredentials() {
    return this.get<SnakeCasedProperties<GetNJCredentialsResponse>>(
      '/account/nj_credentials'
    );
  }

  updateNwmlsApiCredentials(nwmlsid: string, lastname: string) {
    return this.post<boolean>('/account/nwmls_api_credentials', {
      nwmlsid,
      lastname,
    } as SetNwmlsCredentialsRequest);
  }

  updateReminder(reminder: Reminder) {
    return this.post<Reminder>('/account/reminder', reminder);
  }

  deleteReminder(key: string) {
    return this.post<void>(`/account/reminder/${key}/delete`);
  }

  addEmail(email: string, isMarkedPrimary?: boolean) {
    return this.post<UserEmail>('/account/emails/add', {
      email,
      isMarkedPrimary,
    } as AddEmailRequest);
  }

  removeEmail(email: string) {
    return this.post<string>('/account/emails/remove', {
      email,
    } as QueryEmailRequest);
  }

  dashboardRedirects() {
    return this.get<Route>('/account/dashboard_redirects');
  }

  setDefaultEmail(email: string) {
    return this.post<User>('/account/emails/default', {
      email,
    } as QueryEmailRequest);
  }

  findTemplateByCategory(category: EnumValues<typeof UserTemplatesCategory>) {
    return this.get<ApiResponseData<FindTemplateByCategoryResponse>>(
      `/account/template/${category}`
    );
  }

  updateTemplate(template: UserTemplates) {
    return this.post<UserTemplates>('/account/template', template);
  }

  deleteTemplate(id: string) {
    return this.post<void>(`/account/template/${id}/delete`);
  }

  updateNotificationSettings(settings: Partial<NotificationSettings>) {
    return this.post<void>('/account/notification_settings', settings);
  }

  getBillingInfo() {
    return this.get<BillingInfo>('/account/billing_info');
  }

  billingSubscribe(request: BillingSubscriptionRequest) {
    return this.post<BillingSubscriptionResponse>(
      '/account/billing_subscribe',
      request
    );
  }

  billingResumeSubscription(subscriptionId: string) {
    return this.post<void>('/account/billing_resume_subscription', {
      subscriptionId,
    } as BillingSubscriptionChangeRequest);
  }

  billingCancelSubscription(subscriptionId: string) {
    return this.post<void>('/account/billing_cancel_subscription', {
      subscriptionId,
    } as BillingSubscriptionChangeRequest);
  }

  billingUpdateSource(sourceId: string) {
    return this.post<void>('/account/billing_update_source', {
      sourceId,
    } as BillingSourceChangeRequest);
  }

  billingCoupon(couponId: string) {
    return this.get<Coupon>(
      `/account/billing_coupon/${encodeURIComponent(couponId)}`
    );
  }

  acceptPrivacyPolicy() {
    return this.post<void>('/account/accept_privacy_policy');
  }

  updateOptOutDataSale(value: boolean, userId?: string) {
    const payload: UpdateOptOutDateSaleRequest = {
      value,
    };
    if (userId) {
      payload.userId = userId;
    }
    return this.post('/account/opt_out_data_sale', payload);
  }

  updateSelectedRoles(selectedRoles: EnumValues<typeof DefaultRoleEnum>[]) {
    return this.post('/account/selected_roles', {
      selectedRoles,
    });
  }

  fetchOboProfile(userId: string) {
    return this.get<OboProfile>(`/account/obo_profiles/${userId}`);
  }

  fetchOboProfileByEmail(email: string) {
    return this.get('/account/obo_profiles', {
      params: {
        email,
      } as QueryOboProfileRequest,
    });
  }

  fetchKeyDateSuggestions() {
    return this.get('/account/key_date_suggestions/');
  }

  registerWebinar(webinarId: string, occurrenceId: string) {
    return this.post<boolean>('/account/register_webinar', {
      webinarId,
      occurrenceId,
    } as RegisterWebinarRequest);
  }

  updateToTms() {
    return this.post<string>('/account/update_to_tms');
  }

  getOboProfileTransactionSettings(userId: string) {
    return this.get<GetOboProfileTransactionSettingsResponse>(
      `/account/obo_profiles/${userId}/transaction_settings`
    );
  }

  updateOboProfileTransactionSettings(
    userId: string,
    data: ApiRequestBody<UpdateOboProfileTransactionSettingsRequest>
  ) {
    return this.post<TransactionSettings>(
      `/account/obo_profiles/${userId}/transaction_settings`,
      data
    );
  }
}
