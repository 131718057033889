

import AppIcon from 'src/components/common/app-icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getDisplayName from 'src/utils/get-display-name';
import { isSupportedBrowser, supportedBrowsers } from 'src/utils/browsers';

const clsPrefix = 'unsupported-browser';

const browserImages = {
  chrome: () => import('!!svgJsx!src/images/logo-chrome.svg'),
  safari: () => import('!!svgJsx!src/images/logo-safari.svg'),
  firefox: () => import('!!svgJsx!src/images/logo-firefox.svg'),
  ie11: () => import('!!svgJsx!src/images/logo-ie.svg'),
  edge: () => import('!!svgJsx!src/images/logo-edge.svg'),
};

const BrowserImageLink = ({ name, data }) => {
  return (
    <div className={`${clsPrefix}__item`}>
      <a href={data.link} target="_blank" rel="noopener noreferrer">
        <AppIcon type="svg" importSvg={browserImages[name]} size={80} />
        <div className={`${clsPrefix}__item-name`}>{data.name}</div>
        <div>Version {data.version}+</div>
      </a>
    </div>
  );
};
BrowserImageLink.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

class UnsupportedBrowser extends Component {
  render() {
    return (
      <div className={clsPrefix}>
        <div className={`${clsPrefix}__title`}>
          Please upgrade your browser to use Glide
        </div>
        <div className={`${clsPrefix}__description`}>
          We built Glide using the latest technology which makes Glide easier
          and faster to use. Your current browser unfortunately is not
          supported. Please upgrade to a browser from the list below.
        </div>
        <div className={`${clsPrefix}__items`}>
          {Object.entries(supportedBrowsers).map(([k, v]) => (
            <BrowserImageLink name={k} data={v} />
          ))}
        </div>
      </div>
    );
  }
}

export default function withBrowserCheck() {
  return (WrappedComponent) => {
    class WithBrowserCheck extends Component {
      render() {
        return isSupportedBrowser() ? (
          <WrappedComponent {...this.props} />
        ) : (
          <UnsupportedBrowser />
        );
      }
    }

    WithBrowserCheck.displayName = `WithBrowserCheck(${getDisplayName(
      WrappedComponent
    )})`;
    return WithBrowserCheck;
  };
}
