

import React, { Component } from 'react';
import { Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { inject, observer } from 'mobx-react';
import type UiStore from 'src/stores/ui-store';
import type { AppButtonProps } from './app-button';
import PromiseButton from './promise-button';

const clsPrefix = 'app-modal';

export const WIDTH_LG = 600;
const contentPadding = 24;

export type AppModalProps = ModalProps & {
  ui?: UiStore;
  onCancel: (
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => Promise<void> | void;
  onOk?: (
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => Promise<void> | void;
  className?: string;
  wrapClassName?: string;
  keyboard?: boolean;
  center?: boolean;
  mobileFullScreen?: boolean;
  embeddedFullScreen?: boolean;
  // The returned element from this function will be focused on (element.focus())
  // in componentDidMount.
  // This level of indirection is needed to work around antd Modal limitations.
  elementToFocus?: () => HTMLElement | undefined;
  animate?: boolean;
  showCancelButton?: boolean;
  allowModalOverModal?: boolean;
  visible?: boolean;
  cancelable?: boolean;
  cancelText?: React.ReactNode;
  cancelButtonProps?: AppButtonProps;
  okText?: React.ReactNode;
  okButtonProps?: AppButtonProps;
  theme?: 'default' | 'grey';
  confirmCancel?: boolean;
  confirmLoading?: boolean;
  footer?: React.ReactNode;
  footerLeft?: React.ReactNode;
  footerRight?: React.ReactNode;
  hideFooter?: boolean;
  width?: string | number;
  registerModalOnStack?: boolean;
  useDefaultStyles?: boolean;
  children?: React.ReactNode;
  embeddedReducedOffset?: boolean;
};

interface AppModalState {
  actionLoading: string | null;
  modalId?: string;
}

interface FooterProps {
  left?: React.ReactNode;
  btns?: React.ReactNode;
  right?: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ left, btns, right }) => (
  <div className={`${clsPrefix}__footer-inner`}>
    {!!left && <div className={`${clsPrefix}__footer-left`}>{left}</div>}
    <div className={`${clsPrefix}__footer-btns`}>{btns}</div>
    {!!right && <div className={`${clsPrefix}__footer-right`}>{right}</div>}
  </div>
);

@inject('ui')
@observer
export default class AppModal extends Component<AppModalProps, AppModalState> {
  static Footer = Footer;

  static defaultProps = {
    center: true,
    keyboard: true,
    cancelable: true,
    mobileFullScreen: true,
    embeddedFullScreen: false,
    embeddedReducedOffset: false,
    showCancelButton: true,
    okText: 'OK',
    cancelText: 'Cancel',
    animate: false,
    allowModalOverModal: false,
    theme: 'default',
    registerModalOnStack: true,
  };

  constructor(props: AppModalProps) {
    super(props);

    const modalId = this.props.registerModalOnStack
      ? this.props.ui.registerModal(this.handleCancel)
      : undefined;

    if (this.props.visible) {
      this.props.ui.handleModalVisibilityChange({
        modalId,
        visible: true,
        cancelable: this.props.cancelable,
      });
    }

    this.state = {
      actionLoading: null,
      modalId,
    };
  }

  handleCancel = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!this.props.onCancel) {
      return;
    }
    this.props.onCancel(e);
  };

  componentDidMount() {
    const elementToFocus =
      this.props.elementToFocus && this.props.elementToFocus();
    if (elementToFocus) {
      elementToFocus.focus();
    }
  }

  componentDidUpdate(prevProps: AppModalProps) {
    const { visible: isVisible } = this.props;
    const { ui, visible: wasVisible } = prevProps;

    if (isVisible && !wasVisible) {
      ui.handleModalVisibilityChange({
        modalId: this.state.modalId,
        visible: true,
        cancelable: this.props.cancelable,
      });
    } else if (!isVisible && wasVisible) {
      ui.handleModalVisibilityChange({
        modalId: this.state.modalId,
        visible: false,
      });
    }
  }

  componentWillUnmount() {
    const { ui, visible } = this.props;
    ui.handleModalVisibilityChange({
      modalId: this.state.modalId,
      visible: false,
      notify: visible,
    });
    ui.unregisterModal(this.state.modalId);
  }

  onCancel = async (...args: any[]) => {
    if (this.actionLoading) {
      return;
    }
    const { onCancel } = this.props;
    try {
      this.setState({
        actionLoading: 'cancel',
      });
      await onCancel(...args);
    } finally {
      this.setState({
        actionLoading: null,
      });
    }
  };

  onOk = async (...args: any[]) => {
    if (this.actionLoading) {
      return;
    }
    const { onOk } = this.props;
    try {
      this.setState({
        actionLoading: 'ok',
      });
      await onOk(...args);
    } finally {
      this.setState({
        actionLoading: null,
      });
    }
  };

  get fullWidthModal() {
    const { ui, mobileFullScreen, embeddedFullScreen } = this.props;

    return (
      (ui.isMobileSize && mobileFullScreen) ||
      (ui.isEmbedded && embeddedFullScreen)
    );
  }

  get actionLoading() {
    return Boolean(this.state.actionLoading);
  }

  get okLoading() {
    return this.state.actionLoading === 'ok';
  }

  get cancelLoading() {
    return this.state.actionLoading === 'cancel';
  }

  get footer() {
    const {
      footer,
      footerLeft,
      footerRight,
      hideFooter,
      cancelable,
      showCancelButton,
      okText,
      cancelText,
      okButtonProps,
      cancelButtonProps,
      confirmLoading,
    } = this.props;

    if (hideFooter) {
      return null;
    }

    const cancelBtn = cancelable && showCancelButton && (
      <PromiseButton
        key="cancelBtn"
        type="default"
        onClick={this.onCancel}
        forceLoading={this.cancelLoading}
        {...(cancelButtonProps || {})}
        className={classNames(
          `${clsPrefix}__footer-btn`,
          `${clsPrefix}__cancel-btn`,
          get(cancelButtonProps, 'className'),
          {
            [`${clsPrefix}__blocked-btn`]: this.actionLoading,
          }
        )}
      >
        {cancelText || 'Cancel'}
      </PromiseButton>
    );

    const okBtn = (
      <PromiseButton
        key="okBtn"
        type="primary"
        onClick={this.onOk}
        forceLoading={Boolean(confirmLoading) || this.okLoading}
        {...(okButtonProps || {})}
        className={classNames(
          `${clsPrefix}__footer-btn`,
          `${clsPrefix}__ok-btn`,
          get(okButtonProps, 'className'),
          {
            [`${clsPrefix}__blocked-btn`]: this.actionLoading,
          }
        )}
      >
        {okText || 'Ok'}
      </PromiseButton>
    );

    if (footer === null) {
      return null;
    }

    if (React.isValidElement(footer)) {
      return footer;
    }

    if (isFunction(footer)) {
      return footer({
        footerLeft,
        footerRight,
        cancelBtn,
        okBtn,
      });
    }

    return (
      <Footer left={footerLeft} btns={[cancelBtn, okBtn]} right={footerRight} />
    );
  }

  getOverrideStyle = () => {
    const {
      ui,
      embeddedFullScreen,
      center,
      useDefaultStyles,
      embeddedReducedOffset,
    } = this.props;

    if (!ui.isEmbedded || useDefaultStyles) {
      return {};
    }

    if (embeddedFullScreen && center && !ui.isMobileSize) {
      return {
        top: 0,
        display: 'inline-block',
        paddingTop: contentPadding,
      };
    }

    // eslint-disable-next-line no-shadow
    const { viewportHeight, top } = ui.parent.embeddedApp?.embeddedPosition;

    if (!ui.isMobileSize) {
      // Use a small enough offset to avoid issues with big modals
      const viewportOffsetFraction = !embeddedReducedOffset ? 4 : 8;
      const modalOffset =
        Math.floor(viewportHeight / viewportOffsetFraction) - Math.min(0, top);
      return {
        top: modalOffset,
      };
    }

    // mobile size
    return {
      top: Math.max(0, -top),
      height: viewportHeight - Math.max(0, top),
      padding: 0,
      margin: 0,
    };
  };

  // Long modals (like email) might not render correctly due to the
  // size of the embedded container, so adding a max-height
  // and overflow the body container with scroll
  getBodyOverrideStyle = () => {
    const { ui, embeddedFullScreen } = this.props;

    if (!ui.isEmbedded || embeddedFullScreen || ui.isMobileSize) {
      return {};
    }

    const { viewportHeight } = ui.parent.embeddedApp?.embeddedPosition;
    const headerFooterPadding = 200;

    return {
      maxHeight: viewportHeight - headerFooterPadding,
      overflow: 'scroll',
    };
  };

  render() {
    const {
      ui,
      className,
      wrapClassName,
      center,
      mobileFullScreen,
      embeddedFullScreen,
      animate,
      allowModalOverModal,
      visible,
      cancelable,
      theme,
      width,
      useDefaultStyles,
      ...props
    } = this.props;
    const newClassName = classNames(
      clsPrefix,
      className,
      wrapClassName,
      `${clsPrefix}--${theme}`,
      {
        'vertical-center-modal': center,
        'mobile-fullscreen-modal': mobileFullScreen || embeddedFullScreen,
        'embedded-fullscreen': ui.isEmbedded,
      }
    );
    const overrideStyle = this.getOverrideStyle();
    const bodyOverrideStyle = this.getBodyOverrideStyle();
    const centered = (center && useDefaultStyles) || undefined;
    return (
      <Modal
        wrapClassName={newClassName}
        visible={
          allowModalOverModal
            ? visible
            : visible && ui.isModalVisibleOnStack(this.state.modalId)
        }
        style={overrideStyle}
        bodyStyle={bodyOverrideStyle}
        closable={cancelable}
        {...(!animate
          ? {
              maskTransitionName: null,
              transitionName: null,
            }
          : null)}
        {...props}
        width={this.fullWidthModal ? '100%' : width}
        footer={this.footer}
        onCancel={(e) => {
          if (
            !cancelable ||
            (e && e.target && e.target.keyCode && !props.keyboard)
          ) {
            return;
          }

          this.handleCancel(e);
        }}
        centered={centered}
      />
    );
  }
}
