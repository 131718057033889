/*
 * isRoot: routes that are at the root of the app entry point
 * isTxnRoot: routes that are direct children of the `transactions.transaction` route.
 *    (ie `transactions.transaction.some-route`)
 * group: an id to identify group of routes. Routes with the same group id will render
 *   within the same webview. Navigating to a route with a group id of undefined or
 *   different from the current group id will trigger a new webview in the mobile app
 *   (including the fancy animation).
 */
type RouteInfo = {
  isTxnRoot?: boolean;
  isRoot?: boolean;
  group?: number;
  isFullScreenModal?: boolean;
  isOrgRoot?: boolean;
};

export const iosAllowedRoutes: Record<string, RouteInfo> = {
  home: {
    isRoot: true,
    group: 1,
  },
  pipelines: {
    isRoot: true,
    group: 1,
  },
  dash: {
    isRoot: true,
    group: 1,
  },
  transactions: {
    isRoot: true,
    group: 1,
  },
  'pipelines.transactions': {
    isRoot: true,
    group: 1,
  },
  'pipelines.archived': {
    isRoot: true,
    group: 1,
  },
  'pipelines.workflows': {
    isRoot: true,
    group: 1,
  },
  'transactions.transaction': {
    isTxnRoot: true,
  },
  'transactions.transaction.disclosure': {
    isTxnRoot: true,
    group: 2,
  },
  'transactions.transaction.disclosurePackage': {
    isTxnRoot: true,
    group: 2,
  },
  'transactions.transaction.documents': {
    isTxnRoot: true,
  },
  'transactions.transaction.documents.document': {},
  'transactions.transaction.documents.task': {
    group: 5,
  },
  'transactions.transaction.documents.deleted': {},
  'transactions.transaction.details': {
    isTxnRoot: true,
    group: 3,
  },
  'transactions.transaction.details.party': {
    isTxnRoot: true,
    group: 3,
  },
  'transactions.transaction.parties': {
    isTxnRoot: true,
    group: 3,
  },
  'transactions.transaction.parties.party': {
    isTxnRoot: true,
    group: 3,
  },
  'transactions.transaction.items.item': {
    group: 5,
  },
  'transactions.transaction.packets.packet': {
    group: 5,
  },
  'transactions.transaction.items.item.gfpf': {
    group: 6,
  },
  'transactions.transaction.packets.packet.gfpf': {
    group: 6,
  },
  'transactions.transaction.signature-requests': {
    isTxnRoot: true,
  },
  'transactions.transaction.signature-requests.detail': {},
  'transactions.transaction.properties': {
    isTxnRoot: true,
  },
  'transactions.transaction.properties.property': {},
  'transactions.transaction.disclosures.packet': {},
  'transactions.transaction.chores': {},
  'transactions.transaction.disclosures.packet.preview': {},
  'transactions.transaction.offers': {
    isTxnRoot: true,
  },
  'transactions.transaction.offers.offer': {
    group: 7,
  },
  'transactions.transaction.offers.prepare': {
    group: 7,
  },
  'transactions.transaction.offers.offer.prepare': {
    group: 7,
  },
  'transactions.transaction.offers.create': {
    group: 7,
  },
  'transactions.transaction.offers.offer.create': {
    group: 7,
  },
  'account.profile': {
    isTxnRoot: true,
  },
  'account.preferences': {
    isTxnRoot: true,
  },
  'account.notifications': {
    isTxnRoot: true,
  },
  'account.integrations': {
    isTxnRoot: true,
  },
  'account.addressBook': {
    isTxnRoot: true,
  },
  'account.verifyMembership': {
    isTxnRoot: true,
  },
  'account.compass-alias-email-cta': {
    isTxnRoot: true,
  },
  'account.compass-alias-email-cta2': {
    isTxnRoot: true,
  },
  compassEmailBlock: {
    isTxnRoot: true,
  },
  account: {},
  'orgs.invite': {},
  sflow: {
    isFullScreenModal: true,
  },
  flow: {
    isFullScreenModal: true,
    group: 4,
  },
  'flow.page': {
    isFullScreenModal: true,
    group: 4,
  },
  orgs: {
    isTxnRoot: true,
    isOrgRoot: true,
    group: 8,
  },
  'orgs.members': {
    isTxnRoot: true,
    isOrgRoot: true,
    group: 8,
  },
  'orgs.groups': {
    isTxnRoot: true,
    isOrgRoot: true,
  },
  'orgs.checklists': {
    isTxnRoot: true,
    isOrgRoot: true,
  },
  'orgs.checklists.edit': {},
  'orgs.offices': {
    isTxnRoot: true,
    isOrgRoot: true,
  },
  'orgs.settings': {
    isTxnRoot: true,
    isOrgRoot: true,
  },
  'advertising-hub-flow': {
    isFullScreenModal: true,
  },
};
