import jsonLogic from 'json-logic-js';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';

const evalBoolean = (v: unknown) => {
  return !!v && !isEqual(v, []) && !isEqual(v, {});
};

const evalCondition = (values: unknown[], rule?: string) => {
  if (!(values && values.length)) {
    return true;
  }
  if (!rule) {
    return values.some(evalBoolean);
  }

  const data = fromPairs(
    values.map((v, i) => {
      return [
        String.fromCharCode(i + 'A'.charCodeAt(0)),
        Object.keys((v as object) ?? {}).includes('value')
          ? (v as Record<string, unknown>)?.value || ''
          : v,
      ];
    })
  );
  return evalBoolean(jsonLogic.apply(JSON.parse(rule), data));
};

export default evalCondition;
