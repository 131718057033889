import type { SharedPacketAccessRequest } from 'src/types/proto/services/packet_public_service';
import type {
  SharedSharePacketView,
  SharePacketRequestResponse,
} from 'src/types/proto/shared_item_view_models';
import type { ApiRequestBody } from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import BaseApi from '../base-api';

export default class RequestPacketAccess extends BaseApi {
  getPacketData(uuid: string) {
    return this.get<SharedSharePacketView>(`/public/share_packet/${uuid}`);
  }

  saveAccessRequest(
    uuid: string,
    saveAccessRequest: ApiRequestBody<SharedPacketAccessRequest>
  ) {
    return this.post<SharePacketRequestResponse>(
      `/public/share_packet/${uuid}/request_access`,
      formatToSnakeCase(saveAccessRequest)
    );
  }
}
