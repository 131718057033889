import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import type { FormConfigItem } from 'src/types/proto/reform';
import Term from './term';
import type FormConfig from '.';

export default class Section extends Model<FormConfigItem> {
  id: string;
  formConfig: FormConfig;

  constructor(formConfig: FormConfig, json: FormConfigItem, id: string) {
    super(json);
    makeObservable(this);
    this.formConfig = formConfig;
    this.id = id;
  }

  @computed
  get store() {
    return this.formConfig.store;
  }

  @computed
  get boundForm() {
    return this.formConfig.boundForm;
  }

  @computed
  get number() {
    return this.data.num || '';
  }

  @computed
  get title() {
    return this.data.title || '';
  }

  @computed
  get description() {
    return this.data.description || '';
  }

  @computed
  get kind() {
    return this.data.kind;
  }

  @computed
  get fieldId() {
    return this.data.fieldId;
  }

  @computed
  get outlineExclusive() {
    return this.data.outlineExclusive || false;
  }

  @computed
  get terms() {
    return (this.data.section?.items || []).map(
      (item, idx) =>
        new Term(this.formConfig, this, item, `${this.id}-${idx + 1}`)
    );
  }

  @computed
  get isEmpty() {
    return this.filteredTerms.length === 0;
  }

  @computed
  get annotation() {
    return this.store.annotationsByFieldId.get(this.fieldId)?.[0];
  }

  @computed
  get visibleTerms() {
    return this.terms.filter((t) => t.isVisible);
  }

  @computed
  get isVisible() {
    return (
      this.formConfig.showAllSections || this.terms.some((t) => t.isVisible)
    );
  }

  @computed
  get filteredTerms() {
    return this.visibleTerms.filter((t) => t.matchesSearch);
  }

  @computed
  get matchesSearch() {
    return (
      this.formConfig.showAllSections ||
      this.visibleTerms.some((t) => t.matchesSearch)
    );
  }

  jumpToAnnotation() {
    if (!this.annotation) {
      return;
    }
    this.store.jumpToAnnotation(this.annotation, {
      blocking: false,
      placement: 'top',
      offset: '-20%', // Align top of the annotation to roughly 20% of pdf viewer's height
      minScrollLength: 100, // Avoid "small" jumps
    });
  }
}
