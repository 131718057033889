import type { ApiRequestBody } from 'src/types/utils';
import BaseApi from '../base-api';
import { GenerateNhdUsageReportRequest } from 'src/types/proto/services/nhd_admin_service';

export default class NHD extends BaseApi {

  generateNhdUsageReport(
    data: ApiRequestBody<GenerateNhdUsageReportRequest>
  ) {
    return this.post(`/nhd/usage`, data);
  }
}
