import debouncePromise from 'debounce-promise';
import invariant from 'invariant';
import forOwn from 'lodash/forOwn';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import { ObservableMap, runInAction, toJS } from 'mobx';
import functionDecorator from 'src/utils/function-decorator';
import hasOwnProperty from 'src/utils/has-own-property';

export type Mode = boolean | 'auto';
export type Fetcher<Params = unknown, Value = unknown> = (
  params: Params
) => Promise<Value>;
type Getter<Params = unknown, Value = unknown> = (key: Params) => Value;

interface GetFetchOptions<Params = unknown, Value = unknown> {
  fetcher?: Fetcher<Params, Value>;
  bindTo?: any;
  getter?: Getter<Params, Value>;
  flat?: boolean;
  debounce?: number;
  returnLastWhileFetching?: boolean;
  getMemoizeKey?: (val: Params) => string;
  cacheAttribute?: string;
}

export interface GetFetch<Params = unknown, Value = unknown> {
  get: Getter<Params, Value>;
  fetch: Fetcher<Params, Value>;
  getOrFetch: Fetcher<Params, Value>;
  getAndFetch: Fetcher<Params, Value>;
}

let promiseKey = 0;

function getFetchMode<Params, Value>(mode: Mode) {
  const res: {
    shouldFetch:
      | false
      | (({
          complete,
          value,
        }: {
          complete: boolean;
          value: Params;
          cached?: Record<string, Fetcher<Params, Value>>;
        }) => boolean);
    force: boolean;
  } = {
    shouldFetch: false,
    force: false,
  };
  if (mode === true) {
    Object.assign(res, {
      shouldFetch: () => true,
      force: true,
    });
  } else if (mode === 'auto') {
    Object.assign(res, {
      shouldFetch: ({ complete }: { complete: unknown }) => !complete,
    });
  } else if (mode) {
    Object.assign(res, mode);
  }

  return res;
}

export function getFetchMulti() {
  return functionDecorator(
    (fetcher: Fetcher, isClassMethod: boolean, key: string) => {
      const cacheAttribute = `_${key}`;
      const accessedAttribute = `_${key}__accessed`;
      const globalCache = new ObservableMap();
      const globalAccessed = new Map();
      const promiseMap = new Map();

      function getClearPromiseCallback(pks: unknown[]) {
        return () => {
          window.setTimeout(() => {
            pks.forEach((pk) => {
              if (promiseMap.has(pk)) {
                promiseMap.delete(pk);
              }
            });
          });
        };
      }

      return function wrapped(this: any, mode: Mode, ids: string[]) {
        let cache: ObservableMap<string, Fetcher>;
        let accessed: any;
        if (isClassMethod) {
          this[cacheAttribute] = this[cacheAttribute] || new ObservableMap();
          this[accessedAttribute] = this[accessedAttribute] || new Map();
          cache = this[cacheAttribute];
          accessed = this[accessedAttribute];
        } else {
          cache = globalCache;
          accessed = globalAccessed;
        }

        const _mode = getFetchMode(mode);

        const data = mapValues(keyBy(ids), (id) => cache.get(id)!);

        const complete = !values(data).some((datum) => {
          return typeof datum === 'undefined';
        });

        let fetching = false;
        let promise;

        if (_mode.shouldFetch) {
          const missings = _mode.force
            ? ids
            : ids.filter((id) => typeof data[id] === 'undefined');
          fetching = _mode.shouldFetch({
            complete,
            cached: data,
            value: ids,
          });

          if (fetching) {
            if (missings.length) {
              const toFetchs = missings.filter((id) => !accessed.get(id));
              if (toFetchs.length) {
                const fetchPromise = fetcher
                  .bind(this)(missings)
                  .then((newData) => {
                    runInAction(() => {
                      forOwn(newData, (value, id) => cache.set(id, value));
                    });
                    return mapValues(keyBy(ids), (id) => cache.get(id));
                  });
                const pk = promiseKey.toString();
                promiseKey += 1;
                promiseMap.set(pk, fetchPromise);
                toFetchs.forEach((id) => accessed.set(id, pk));
              }
              const pendingPks = missings.map((id) => accessed.get(id));
              const clearPksCb = getClearPromiseCallback(pendingPks);
              promise = Promise.all(pendingPks.map((pk) => promiseMap.get(pk)));
              promise = promise.then(clearPksCb, clearPksCb).then(() => {
                missings.map((id) => accessed.delete(id));
              });
              promise = promise.then(() => {
                return mapValues(keyBy(ids), (id) => cache.get(id));
              });
            } else {
              promise = Promise.resolve(data);
            }
          }
        }

        return {
          data,
          complete,
          fetching,
          promise,
        };
      };
    },
    true
  );
}

export function getFetchFromFunction<Params = any, Value = any>(
  f: any
): GetFetch<Params, Value> {
  f.get = (val: Params) => {
    return f(false, val).data;
  };

  f.fetch = (val: Params) => {
    return f(true, val).promise;
  };

  f.getOrFetch = async (val: Params) => {
    const getResult = f(false, val);
    if (getResult.complete && getResult.data !== null) {
      return getResult.data;
    }

    const data = await f.fetch?.(val);
    return data;
  };

  f.getAndFetch = async (val: Params) => {
    const getResult = f(false, val);
    const { complete } = getResult;
    const fetchPromise = f.fetch?.(val);
    if (complete) {
      return getResult.data;
    }
    const fetchResult = await fetchPromise;
    return fetchResult;
  };
  return f;
}

export function transformGetFetch(
  f: (
    mode: Mode,
    v: unknown
  ) => { complete: boolean; promise: Promise<unknown>; data: unknown },
  transformData: (data: unknown) => unknown
) {
  return getFetchFromFunction((mode: Mode, v: unknown) => {
    const load = f(mode, v);
    const promise = load.promise && load.promise.then(transformData);
    if (load.complete) {
      return {
        ...load,
        data: transformData(load.data),
        promise,
      };
    }
    return {
      ...load,
      promise,
    };
  });
}

let getFetchIdSeq = 0;

function getFetchFunction<Params, Value>(
  thisArg: any,
  options: GetFetchOptions<Params, Value>,
  _getter?: Getter<Params, Value> | null,
  _fetcher?: Fetcher<Params, Value>
) {
  const getter = _getter ? _getter.bind(thisArg) : null;
  const fetcher = _fetcher ? _fetcher.bind(thisArg) : null;

  const {
    flat = false,
    debounce = null,
    returnLastWhileFetching = true,
    getMemoizeKey = null,
  } = options;

  let cache: ObservableMap;
  if (thisArg) {
    const cacheAttribute = options.cacheAttribute
      ? options.cacheAttribute
      : // eslint-disable-next-line no-plusplus
        `_getFetchCache${getFetchIdSeq++}`;
    thisArg[cacheAttribute] = new ObservableMap();
    cache = thisArg[cacheAttribute];
  } else {
    cache = new ObservableMap();
  }

  const debouncedFetcherMap = new Map<string, Fetcher<Params, Value>>();

  function getDebouncedFetcher(val: Params) {
    const fetcherKey = getMemoizeKey ? getMemoizeKey(val) : '__SINGLETON';
    if (!debouncedFetcherMap.has(fetcherKey)) {
      const debouncedFetcher =
        debounce === null
          ? fetcher
          : debouncePromise(fetcher!, debounce).bind(thisArg);
      debouncedFetcherMap.set(fetcherKey, debouncedFetcher!);
    }
    return debouncedFetcherMap.get(fetcherKey)!;
  }

  function isValEqual(cacheVal: string, val: Params) {
    return getMemoizeKey
      ? getMemoizeKey(val) === cacheVal
      : isEqual(toJS(cacheVal), toJS(val));
  }

  function prepValForCache(val: Params): string {
    return getMemoizeKey ? getMemoizeKey(val) : (val as string);
  }

  function boundDoGetFetch(mode: Mode, val: Params) {
    const memoizeKey = getMemoizeKey ? getMemoizeKey(val) : '';
    const cacheValueKey = `value:${memoizeKey}`;
    const cacheCallKey = `call:${memoizeKey}`;
    const cacheLoadingKey = `loading:${memoizeKey}`;
    const complete =
      cache.has(cacheCallKey) && isValEqual(cache.get(cacheCallKey), val);

    const _mode = getFetchMode<Params, Value>(mode);

    let fetching = false;
    let promise;

    if (_mode.shouldFetch) {
      fetching = _mode.shouldFetch({
        complete,
        value: val,
      });

      if (fetching) {
        const cacheVal = prepValForCache(val);
        const debouncedFetcher = getDebouncedFetcher(val);
        if (
          !cache.has(cacheLoadingKey) ||
          !isValEqual(cache.get(cacheLoadingKey).val, val)
        ) {
          promise = debouncedFetcher(val).then(
            (data: unknown) => {
              // If this getFetch is a class method and there are multiple
              // instances each trying to fetch, this callback should run
              // foreach instance even if it wasn't the instance that originally
              // triggered the fetch. This is because the loadingKey is instance
              // specific, while the debounced fetcher is global.
              //
              // Conversely, for singletons or non class methods, this callback
              // Should only ever fire once per actual `fetcher()` invocation.
              runInAction(() => {
                cache.set(cacheCallKey, cacheVal);
                cache.delete(cacheLoadingKey);
                if (!getter) {
                  cache.set(cacheValueKey, data);
                }
              });
              return getter ? getter(val) : cache.get(cacheValueKey);
            },
            (err: unknown) => {
              runInAction(() => {
                cache.delete(cacheLoadingKey);
              });
              throw err;
            }
          );
          cache.set(cacheLoadingKey, {
            promise,
            val: cacheVal,
          });
        }
        ({ promise } = cache.get(cacheLoadingKey));
        invariant(promise, 'Expected a promise when fetching.');
      }
    }

    let data;
    if (returnLastWhileFetching || !fetching) {
      data = getter ? getter(val) : cache.get(cacheValueKey);
    }

    if (data === undefined) {
      data = null;
    }
    const common = {
      complete,
      fetching,
      promise,
    };
    return flat
      ? {
          ...data,
          ...common,
        }
      : {
          data,
          ...common,
        };
  }

  boundDoGetFetch.cache = cache;
  boundDoGetFetch.debouncedFetcherMap = debouncedFetcherMap;
  return getFetchFromFunction<Params, Value>(boundDoGetFetch);
}

function getFetchDecorator<Params, Value>(options = {}) {
  return function decorate(
    target: Fetcher<Params, Value>,
    key: string,
    descriptor?: PropertyDescriptor
  ) {
    if (!descriptor) {
      return getFetchFunction(null, options, null, target);
    }

    // Copypasta from autobind-decorator

    let fn = descriptor.value;

    // In IE11 calling Object.defineProperty has a side-effect of evaluating the
    // getter for the property which is being replaced. This causes infinite
    // recursion and an "Out of stack space" error.
    let definingProperty = false;

    return {
      configurable: true,
      get() {
        if (
          definingProperty ||
          this === target.prototype ||
          // eslint-disable-next-line no-prototype-builtins
          this.hasOwnProperty(key) ||
          typeof fn !== 'function'
        ) {
          return fn;
        }

        const boundFn = getFetchFunction(this, options, null, fn);
        definingProperty = true;
        Object.defineProperty(this, key, {
          configurable: true,
          get() {
            return boundFn;
          },
          set(value) {
            fn = value;
            delete this[key];
          },
        });
        definingProperty = false;
        return boundFn;
      },
      set(value: unknown) {
        fn = value;
      },
    };
  };
}

export function getFetch<Params = unknown, Value = unknown>(
  options: GetFetchOptions<Params, Value> = {}
): GetFetch<Params, Value> {
  if (options.fetcher) {
    invariant(
      hasOwnProperty(options, 'bindTo'),
      '`bindTo` is a required option if specifying `fetcher` as an option.'
    );
    return getFetchFunction(
      options.bindTo,
      options,
      options.getter,
      options.fetcher
    );
  }
  return getFetchDecorator<Params, Value>(options);
}
