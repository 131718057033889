import camelCase from 'lodash/camelCase';
import { CamelCase } from 'type-fest';

type Kind<T extends string> = {
  kind: T;
} & Partial<Record<CamelCase<T>, unknown>> &
  Record<string, unknown>;

export default function getKindData<
  D extends Kind<T>,
  T extends string = D['kind']
>(obj: D) {
  const kind = camelCase(obj.kind) as CamelCase<D['kind']>;
  return obj[kind];
}
