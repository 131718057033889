const isOldIE = () => {
  return /MSIE/.test(navigator.userAgent);
};

const isOldIEor11 = () => {
  const ua = navigator.userAgent;
  return isOldIE() || (/Trident/.test(ua) && /rv:11/.test(ua));
};

export const isSupportedBrowser = () => {
  return !isOldIEor11();
};

// const isIE11orOlderEdge = () => {
//   const ua = navigator.userAgent;
//   return /Edg/.test(ua) || (/Trident/.test(ua) && /rv:11/.test(ua));
// };

// const isChrome = () => {
//   const agents = navigator.userAgent.split(' ');
//   const chr = agents.find((agent) => /Chrome/.test(agent));
//   if (!chr || !/Google Inc/.test(navigator.vendor)) {
//     return false;
//   }
//   const ver = /\//.test(chr) && chr.split('/')[1];
//   return ver && parseInt(ver.split('.')[0], 10) >= 38;
// };

// const isSafari = () => {
//   const agents = navigator.userAgent.split(' ');
//   if (!/Safari/.test(navigator.userAgent) || !/Apple/.test(navigator.vendor)) {
//     return false;
//   }
//   const version = agents.find((part) => /Version\//.test(part));
//   const ver = version && version.split('/')[1];
//   return ver && parseInt(ver.split('.')[0], 10) >= 10;
// };

// const isFirefox = () => {
//   const agents = navigator.userAgent.split(' ');
//   const ffox = agents.find((agent) => /Firefox/.test(agent));
//   if (!ffox) {
//     return false;
//   }
//   const ver = /\//.test(ffox) && ffox.split('/')[1];
//   return ver && parseInt(ver.split('.')[0], 10) >= 50;
// };

// const isEdge = () => {
//   const agents = navigator.userAgent.split(' ');
//   const edge = agents.find((agent) => /Edg/.test(agent));
//   if (!edge) {
//     return false;
//   }
//   const ver = /\//.test(edge) && edge.split('/')[1];
//   return ver && parseInt(ver.split('.')[0], 10) >= 79;
// };

// export const isSupportedBrowser = (permissive = true) => {
//   return (
//     isFirefox() ||
//     isEdge() ||
//     isChrome() ||
//     isSafari() ||
//     (permissive && isIE11orOlderEdge())
//   );
// };

export const isAndroidBrowser = () => {
  // different from Glide Android App
  if (/Glide/i.test(navigator.userAgent)) {
    return false;
  }
  return /Android\s\d+/.test(navigator.userAgent);
};

export const supportedBrowsers = {
  chrome: {
    name: 'Google Chrome',
    version: '38',
    link: 'https://www.google.com/chrome/',
  },
  safari: {
    name: 'Apple Safari',
    version: '10',
    link: 'https://support.apple.com/downloads/safari',
  },
  firefox: {
    name: 'Mozilla FireFox',
    version: '50',
    link: 'https://www.mozilla.org/firefox/new/',
  },
  edge: {
    name: 'Microsoft Edge',
    version: '79',
    link: 'https://www.microsoft.com/edge',
  },
};

export const isGlideMobileApp = () => isGlideIOSApp() || isGlideAndroidApp();

export function isGlideTabletApp() {
  return /Glide iOS iPad/.test(navigator.userAgent);
}

export const isGlideIOSApp = () => /Glide iOS/.test(navigator.userAgent);

export const isGlideAndroidApp = () =>
  /Glide Android/.test(navigator.userAgent);
