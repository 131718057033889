import React, { cloneElement } from 'react';
import get from 'lodash/get';
import { isGlideIOSApp, isGlideAndroidApp } from './utils/browsers';
import { getAdapter } from 'src/heap-adapter';

const Analytics = () => {
  const isIos = isGlideIOSApp();
  const isAndroid = isGlideAndroidApp();
  const analytics = getAdapter();

  const gtag = window.gtag || (() => {});

  let client = 'web';
  if (isIos) {
    client = 'ios';
  } else if (isAndroid) {
    client = 'android';
  }

  const newAnalytics = {
    ...analytics,
    track: (name, options, platforms) => {
      analytics.track(name, {
        ...options,
        client,
      });

      if (platforms?.includes('google')) {
        gtag('event', name, {
          ...options,
          client,
        });
      }
    },
    platforms: ['google'],
  };
  return newAnalytics;
};

export default Analytics;

export function trackError(error, kind, props) {
  Analytics().track('Client Error', {
    kind,
    path: window.location.pathname,
    status: get(error, 'response.status', 0),
    code: get(error, 'response.code', 0),
    ...props,
  });
}

export function trackClick(element, props = {}) {
  Analytics().track('Client Click', {
    element,
    path: window.location.pathname,
    ...props,
  });
}

const getPropsWithTrackedEventHandlers = (props, trackEvents) => ({
  ...props,
  ...Object.keys(trackEvents)
    .filter((eventHandlerKey) => !!props[eventHandlerKey])
    .reduce(
      (allTrackedEvents, eventHandlerKey) => ({
        ...allTrackedEvents,
        [eventHandlerKey]: (...args) => {
          props[eventHandlerKey](...args);
          Analytics().track(...trackEvents[eventHandlerKey]);
        },
      }),
      {}
    ),
});

export const trackEventHandlers =
  (trackEvents) => (WrappedComponent) => (props) =>
    (
      <WrappedComponent
        {...getPropsWithTrackedEventHandlers(props, trackEvents)}
      />
    );

export const cloneElementWithTrackedEvents = (
  originalReactComponent,
  trackEvents
) =>
  cloneElement(
    originalReactComponent,
    getPropsWithTrackedEventHandlers(originalReactComponent.props, trackEvents)
  );

/**
 * @description Click tracking event from analytics suite, along with other functions invoked afterward.
 * @param {string} name of the event, i.e 'Clicked Download'
 * @param {string} component string from which this was invoked i.e 'Download Button'
 * @param  {...Function} ...rest additional functions to be invoked along with tracking event
 */
export const trackClickWithFunctions =
  (eventString, component, ...functions) =>
  () => {
    Analytics().track(eventString, {
      component,
      path: window.location.pathname,
    });
    functions.forEach((fn) => fn());
  };
