import get from 'lodash/get';
import preventHandleContactSuggestion from 'mocks/account/prevent-handle-contact-suggestion';
import features from 'mocks/features/features';
import variation from 'mocks/ld/variation';
import openPopupMock from 'mocks/zipform/open-popup';
// TODO make features patch work on backend

const MOCKS = {
  variation,
  features,
  'zipform-link-popup': openPopupMock,
  'prevent-handle-contact-suggestion': preventHandleContactSuggestion,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mockable =
  <T = any, Params extends unknown[] = any[]>(
    patchKey: string,
    mockKey: keyof typeof MOCKS,
    fn: (...params: Params) => T
  ) =>
  (...args: Params): T => {
    if (
      window.Glide.env !== 'production' &&
      get(window, `__e2ePatches.${patchKey}`) &&
      MOCKS[mockKey]
    ) {
      return MOCKS[mockKey](window.__e2ePatches?.[patchKey], fn)(...args);
    }

    return fn(...args);
  };

export default mockable;
