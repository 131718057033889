export const clsPrefix = 'app-document-edit';
export const testClsPrefix = 'test__document-edit';

export const TILE_SPACING = 15;
export const DEFAULT_ZOOM_LEVEL = 4;
export const MIN_ZOOM_LEVEL = 3;
export const MAX_ZOOM_LEVEL = 6;

// drag and drop source & target types
export const DND_DOCUMENT_PAGE = 'document-edit-page';
