import autobindDecorator from 'autobind-decorator';

export default function functionDecorator(decorator, autoBind = false) {
  return function wrapped(target, key, descriptor) {
    const isClassMethod = !!descriptor;
    const f = isClassMethod ? descriptor.value : target;
    const res = decorator(f, isClassMethod, key);
    if (isClassMethod) {
      descriptor.value = res;
      return autoBind ? autobindDecorator(target, key, descriptor) : descriptor;
    }
    return res;
  };
}
