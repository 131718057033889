import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { SnakeCasedProperties } from 'type-fest';
import type { Provider } from 'src/entries/admin/providers/models';
import type { PaginationListResult } from 'src/types/api';
import type { Association } from 'src/types/proto/brokers';
import type { MlsOrganization } from 'src/types/proto/integrations';
import type { Org, OrgRequest } from 'src/types/proto/orgs';
import type {
  GetOrgListRequest,
  ToggleMembersTmsRequest,
  UploadMembersRequest,
  UploadMembersResponse,
} from 'src/types/proto/services/org_admin_service';
import type {
  GetAllOrganizationsResponse,
  GetOrganizationsByUserIdResponse,
} from 'src/types/proto/services/organization_admin_service';
import type { VerificationMethod } from 'src/types/proto/verifications';
import type { ApiRequestBody, DeepPartial } from 'src/types/utils';
import Orgs from '../public/orgs';

export default class AdminOrgs extends Orgs {
  list(args: GetOrgListRequest) {
    const params = omitBy(args, isNil);
    return this.get<PaginationListResult<Org>>('/orgs', {
      params,
    });
  }
  createOrg(orgRequest: DeepPartial<OrgRequest>) {
    return this.post<Org>('/orgs', orgRequest);
  }
  uploadMembers(orgId: string, fileUrl: string) {
    return this.post<UploadMembersResponse>(`/orgs/${orgId}/upload_members`, {
      fileUrl,
    } as ApiRequestBody<UploadMembersRequest>);
  }
  inviteMembers(orgId: string) {
    return this.post<void>(`/orgs/${orgId}/invite_members`);
  }

  // TODO deprecate after TMS launch
  toggleMembersTms(orgId: string, enable = true) {
    return this.post<void>(`/orgs/${orgId}/toggle_members_tms`, {
      enable,
    } as ApiRequestBody<ToggleMembersTmsRequest>);
  }

  listForUser(userId: string) {
    return this.get<SnakeCasedProperties<GetOrganizationsByUserIdResponse>>(
      `/organizations/${userId}`
    );
  }

  getWithProviderKind(providerId: string, providerKind: number) {
    return this.get<Association[] | MlsOrganization[]>(
      `organizations/get_provider/${providerId}/${providerKind}`
    );
  }

  listAllOrganizations() {
    return this.get<SnakeCasedProperties<GetAllOrganizationsResponse>>(
      'organizations/list_all'
    );
  }

  upsertProvider(providerKind: number, provider: DeepPartial<Provider>) {
    return this.post<Provider>(
      `/organizations/upsert_provider/${providerKind}`,
      {
        provider,
      }
    );
  }

  updateVerificationMethod(
    providerId: string,
    providerKind: number,
    verificationMethod: DeepPartial<VerificationMethod>
  ) {
    return this.post(
      `/organizations/update_verification_method/${providerKind}/${providerId}`,
      {
        verificationMethod,
      }
    );
  }

  deleteProvider(providerId: string, providerKind: number) {
    return this.post<void>(
      `organizations/delete_provider/${providerId}/${providerKind}`
    );
  }

  exportProviders() {
    return this.post<string>(`/organizations/export_providers`);
  }

  importProviders(key: string) {
    return this.post<void>(`/organizations/import_providers`, { key });
  }

  reverifyMemberships() {
    return this.post<void>(`/organizations/re_verify_memberships`);
  }

  exportMembersCsv(providerId: string) {
    return this.post<string>(
      `/organizations/export_provider_members/${providerId}`
    );
  }
}
