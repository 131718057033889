import range from 'lodash/range';
import { getJoinedLegalName } from 'src/models/auth/contact';
import camelCaseObject from 'src/utils/camel-case-object';
import OutputHandler from './base';

export default class GroupJoinedName extends OutputHandler {
  pdfValue(outctx) {
    const params = this.getParams(outctx);
    const people = [];
    range(1, params.count + 1).forEach((i) => {
      const p = outctx.get(`person${i}`);
      if (p) {
        people.push(p);
      }
    });
    return getJoinedLegalName(
      people.map(camelCaseObject),
      outctx.renderOptions.joinedLegalNameFormat
    );
  }
}
