import { computed, makeObservable, override } from 'mobx';
import type BoundOutput from 'src/models/fields/outputs/bound-output';
import type PDFAnnotationsStore from 'src/stores/pdf-annotations-store';
import type {
  FormConfigItemStringChoice,
  FormConfigItemStringChoiceStringChoiceOption,
} from 'src/types/proto/reform';
import type FormConfig from '..';
import type Item from '../item';
import BaseItemDelegate from './base';

class MultipleSelectOption<
  ParentItem extends Item<
    FormConfigItemStringChoice,
    BaseItemDelegate<FormConfigItemStringChoice>
  >
> {
  item: ParentItem;
  data: FormConfigItemStringChoiceStringChoiceOption;
  store: PDFAnnotationsStore;
  formConfig: FormConfig;

  constructor(
    item: ParentItem,
    data: FormConfigItemStringChoiceStringChoiceOption
  ) {
    makeObservable(this);
    this.item = item;
    this.store = item.store;
    this.formConfig = item.formConfig;
    this.data = data;
  }

  @computed
  get fieldId() {
    return this.data.annotationId;
  }

  @computed
  get value() {
    return this.data.annotationId;
  }

  @computed
  get triggerNote() {
    return this.data.triggerNote;
  }

  @computed
  get requireNote() {
    return this.data.requireNote;
  }

  @computed
  get noteLabel() {
    return this.data.noteLabel;
  }

  @computed
  get textAnnotationId() {
    return this.data.textAnnotationId;
  }

  @computed
  get label() {
    return this.data.label;
  }

  @computed
  get boundOutput(): BoundOutput | null {
    return this.store.getBoundOutputByFieldId(this.fieldId);
  }

  @computed
  get topLinkedOutput() {
    return this.boundOutput?.topLinkedOutput;
  }

  @computed
  get field() {
    return this.topLinkedOutput?.getField('this');
  }

  @computed
  get isNa() {
    return this.topLinkedOutput?.isNa;
  }

  @computed
  get isReadOnly() {
    return (
      this.field &&
      this.field.canSetValue &&
      !this.field.canSetValue(
        this.formConfig.overrides.permissions || this.store.permissions
      )
    );
  }

  @computed
  get isVisible() {
    return !this.isNa && !this.isReadOnly;
  }
}

export default class MultipleSelectItemDelegate extends BaseItemDelegate<FormConfigItemStringChoice> {
  @override
  get fieldIds() {
    return (this.params?.options || []).map((o) => o.fieldId);
  }

  @override
  get flowKey() {
    // This logic is replicated in webapp/web/reform_ext/config/items/multiple_select.py
    return this.fieldIds.join('_');
  }

  @override
  get params() {
    return (this.item.data.multiSelect ||
      this.item.data.stringChoice) as FormConfigItemStringChoice;
  }

  @computed
  get choices() {
    return (this.params.options || []).map((option) => ({
      ...option,
      value: option.annotationId,
    }));
  }

  @computed
  get options() {
    return this.choices.map(
      (option) => new MultipleSelectOption(this.item, option)
    );
  }

  @computed
  get visibleOptions() {
    return this.options.filter((option) => option.isVisible);
  }

  @override
  get isVisible() {
    return this.options.some((field) => field.isVisible);
  }

  @override
  get value() {
    return this.visibleOptions
      .filter(({ fieldId }) => {
        const boundOutput = this.store.getBoundOutputByFieldId(
          fieldId
        ) as unknown as BoundOutput;
        return !!boundOutput?.getFieldValue('this');
      })
      .map(({ fieldId }) => fieldId);
  }

  @override
  get pdfValue() {
    return this.value.join('_');
  }

  toBoundFieldValue(val: any) {
    const fields: Record<string, boolean> = {};
    this.visibleOptions.forEach((option) => {
      const fieldId = option.value;
      fields[fieldId] = !!(val?.value || []).includes(fieldId);
    });
    return [val, fields];
  }

  toWidgetValue(val = this.value) {
    const fieldValue = {
      value: val,
    };
    return [fieldValue, { [this.flowKey]: fieldValue }];
  }

  setValue(val: any) {
    const [, fields] = this.toBoundFieldValue(val);
    Object.keys(fields).forEach((fieldId) => {
      const boundOutput = this.store.getBoundOutputByFieldId(
        fieldId
      ) as unknown as BoundOutput;
      boundOutput?.setFields({
        this: fields[fieldId],
      });
    });
  }
}
