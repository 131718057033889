import forOwn from 'lodash/forOwn';

function encodePair(k: string, v: string | number | boolean) {
  return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
}

export default function querySerialize(obj: object, prefix = '') {
  const parts: string[] = [];
  forOwn(obj, (value, key) => {
    if (value === undefined || value === null) {
      return;
    }
    let param = prefix ? `${prefix}[${key}]` : key;
    if (Array.isArray(obj)) {
      param = prefix;
    } else if (prefix) {
      param = `${prefix}[${key}]`;
    } else {
      param = key;
    }
    parts.push(
      typeof value === 'object'
        ? querySerialize(value, param)
        : encodePair(param, value)
    );
  });
  return parts.join('&');
}
