import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import type {
  FormConfigItem,
  FormConfigItemBoolean,
  FormConfigItemCurrency,
  FormConfigItemDynamicExplanation,
  FormConfigItemLegalDescription,
  FormConfigItemLegalDisclaimer,
  FormConfigItemNumeric,
  FormConfigItemStringChoice,
  FormConfigItemStringOption,
  FormConfigItemTitlePage,
} from 'src/types/proto/reform';
import type { PspdfkitAnnotation } from 'src/types/proto/services/pspdfkit_admin_service';
import getDelegate from './item-delegates';
import type BaseItemDelegate from './item-delegates/base';
import type BooleanItemDelegate from './item-delegates/boolean';
import type CurrencyItemDelegate from './item-delegates/currency';
import type DynamicExplanationItemDelegate from './item-delegates/dynamic-explanation';
import type LegalDisclaimerItemDelegate from './item-delegates/legal-disclaimer';
import type MultipleSelectItemDelegate from './item-delegates/multiple-select';
import type NumericItemDelegate from './item-delegates/numeric';
import type StringItemDelegate from './item-delegates/string';
import type StringChoiceItemDelegate from './item-delegates/string-choice';
import type Term from './term';
import type FormConfig from '.';

export const PAGE_TITLE = 'PAGE_TITLE';

export type BooleanItem = Item<FormConfigItemBoolean, BooleanItemDelegate>;
export type DynamicExplanationItem = Item<
  FormConfigItemDynamicExplanation,
  DynamicExplanationItemDelegate
>;
export type LegalDisclaimerItem = Item<
  FormConfigItemLegalDisclaimer,
  LegalDisclaimerItemDelegate
>;
export type MultipleSelectItem = Item<
  FormConfigItemStringChoice,
  MultipleSelectItemDelegate
>;
export type StringChoiceItem = Item<
  FormConfigItemStringChoice,
  StringChoiceItemDelegate
>;
export type TitlePageItem = Item<
  FormConfigItemTitlePage,
  BaseItemDelegate<FormConfigItemTitlePage>
>;
export type StringItem = Item<FormConfigItemStringOption, StringItemDelegate>;
export type CurrencyItem = Item<FormConfigItemCurrency, CurrencyItemDelegate>;
export type NumericItem = Item<FormConfigItemNumeric, NumericItemDelegate>;

export default class Item<
  Params = any,
  Delegate extends BaseItemDelegate<Params> = BaseItemDelegate<Params>
> extends Model<FormConfigItem> {
  formConfig: FormConfig;
  term: Term;
  id: string;
  delegate: Delegate;

  constructor(
    formConfig: FormConfig,
    term: Term,
    json: FormConfigItem,
    id: string
  ) {
    super(json);
    makeObservable(this);
    this.formConfig = formConfig;
    this.term = term;
    this.id = id;
    this.delegate = getDelegate<Delegate>(this);
  }

  @computed
  get store() {
    return this.formConfig.store;
  }

  @computed
  get boundForm() {
    return this.formConfig.boundForm;
  }

  @computed
  get number() {
    return this.data.num || '';
  }

  @computed
  get title() {
    return this.data.title || '';
  }

  @computed
  get description() {
    return this.data.description || '';
  }

  @computed
  get kind() {
    return this.data.kind;
  }

  @computed
  get fieldId() {
    return this.data.fieldId;
  }

  @computed
  get legalDescription(): Partial<FormConfigItemLegalDescription> {
    return this.data.legalDescription || {};
  }

  @computed
  get outlineExclusive() {
    return this.data.outlineExclusive || false;
  }

  @computed
  get helpText() {
    return this.data.helpText || '';
  }

  @computed
  get showLegalDescription() {
    return Boolean(this.legalDescription.description);
  }

  @computed
  get fieldIds() {
    return this.delegate.fieldIds;
  }

  @computed
  get flowKey() {
    return this.delegate.flowKey;
  }

  @computed
  get params() {
    return this.delegate.params;
  }

  @computed
  get annotations(): PspdfkitAnnotation[] {
    return this.store.annotationsByFieldId.get(this.fieldId) ?? [];
  }

  @computed
  get mainAnnotation() {
    return this.annotations[0];
  }

  @computed
  get boundField() {
    return this.boundForm?.getBoundFieldById(this.fieldId);
  }

  @computed
  get boundOutput() {
    return this.boundForm?.getBoundOutputForField(this.fieldId);
  }

  @computed
  get topLinkedOutput() {
    return this.boundOutput?.topLinkedOutput;
  }

  @computed
  get field() {
    return this.delegate.field;
  }

  @computed
  get fieldKind() {
    return this.field?.kind;
  }

  @computed
  get isContact() {
    return this.fieldKind === 'contact';
  }

  @computed
  get bindingKeys() {
    return Object.keys(this.topLinkedOutput?.bindings ?? {});
  }

  @computed
  get hasMultipleBindings() {
    return this.bindingKeys.length > 1;
  }

  @computed
  get contactRoles() {
    if (!this.isContact || !this.topLinkedOutput) {
      return [];
    }

    const people = this.bindingKeys.map((key) => ({
      role: this.topLinkedOutput?.bindings[key].params.role,
      value: this.topLinkedOutput?.bindings[key].value,
    }));
    const numberOfRolesOccupied = people.filter((p) => p.value).length;
    const numberOfVisibleRoles = numberOfRolesOccupied + 1;

    return people.map((p) => p.role).slice(0, numberOfVisibleRoles);
  }

  @computed
  get pdfValue() {
    return this.delegate.pdfValue;
  }

  @computed
  get value() {
    return this.delegate.value;
  }

  @computed
  get widgetValue() {
    return this.delegate.widgetValue;
  }

  @computed
  get showTitle() {
    return this.kind !== PAGE_TITLE;
  }

  @computed
  get isNa() {
    return this.delegate.isNa;
  }

  @computed
  get isVisible() {
    return this.delegate.isVisible;
  }

  @computed
  get optional() {
    return this.delegate.optional;
  }

  @computed
  get isReadOnly() {
    return this.delegate.isReadOnly;
  }

  @computed
  get matchesSearch() {
    return this.title
      .toLowerCase()
      .includes(this.formConfig.searchText.toLowerCase());
  }

  toBoundFieldValue(val: any) {
    return this.delegate.toBoundFieldValue(val);
  }

  toWidgetValue(val = this.value) {
    return this.delegate.toWidgetValue(val);
  }

  setValue(val: any) {
    this.delegate.setValue(val);
  }

  isValueEqual(val: any) {
    return this.delegate.isValueEqual(val);
  }

  jumpToAnnotations = (highlight: boolean, blocking = false) => {
    return this.store.jumpToAnnotations(this.annotations, {
      blocking,
      placement: 'top',
      offset: '-20%', // Align top of the annotation to roughly 20% of pdf viewer's height
      minScrollLength: 100, // Avoid "small" jumps
      callback: highlight && this.highlight.bind(this),
    });
  };

  highlight() {
    this.store.setHighlighted(this.annotations, true);
  }

  clearHighlighted() {
    this.store.clearAllHighlighted();
  }
}
