import type { GetPacketsRequest } from 'src/types/proto/services/packet_public_service';
import type { ItemList } from 'src/types/proto/transactions';
import BaseApi from '../base-api';

export default class Packets extends BaseApi {
  list(params: GetPacketsRequest) {
    return this.get<ItemList>('/packets', {
      params,
    });
  }
}
