

import AnchorButton from 'src/components/common/anchor-button';
import AppButton from 'src/components/common/app-button';
import AppModal from 'src/components/common/app-modal';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

const clsPrefix = 'app-policy-acceptance-modal';

@inject('account')
@observer
export default class PolicyAcceptance extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
  };

  render() {
    const { account } = this.props;
    const { isDemo, showPolicyAcceptanceModal } = account;
    if (isDemo || !showPolicyAcceptanceModal) {
      return null;
    }

    return (
      <AppModal
        visible={showPolicyAcceptanceModal}
        center
        showCancelButton={false}
        cancelable={false}
        width={650}
        footer={null}
        {...this.props}
      >
        <div className={`${clsPrefix}`}>
          <h1>We&#39;ve made updates to our policies</h1>
          <p>
            We&#39;ve made updates to our policies. By choosing
            &quot;accept&quot; below, you agree to Glide&#39;s updated{' '}
            <AnchorButton
              className={`${clsPrefix}__privacy-policy-link`}
              href="https://app.glide.com/legal/tos"
              type="primary"
              target="_blank"
            >
              terms of service
            </AnchorButton>{' '}
            and{' '}
            <AnchorButton
              className={`${clsPrefix}__privacy-policy-link`}
              href="https://app.glide.com/legal/privacy"
              type="primary"
              target="_blank"
            >
              privacy policy
            </AnchorButton>
            .
          </p>
          <AppButton
            type="primary"
            size="xlarge"
            onClick={account.acceptPrivacyPolicy}
          >
            Accept
          </AppButton>
        </div>
      </AppModal>
    );
  }
}
