

import React, { Component } from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import api from 'src/api';
import AddressLabel from 'src/components/common/address-label';
import AppButton from 'src/components/common/app-button';
import AppModal from 'src/components/common/app-modal';
import { AppRadio as Radio } from 'src/components/common/app-radio';
import AvatarUploader from 'src/components/common/avatar-uploader';
import ContactAvatar from 'src/components/common/contact-avatar';
import { withPusherEvents } from 'src/utils/user-channel';

const clsPrefix = 'app-contact-suggestion-modal';

const KEY_TO_DISPLAY_NAMES = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  cellPhone: 'Phone number',
  officePhone: 'Office number',
  faxPhone: 'Fax number',
  avatarUrl: 'Avatar',
  addressId: 'Address',
  'reAgent.companyName': 'Brokerage name',
  'reAgent.licenseNumber': 'License number',
  'reAgent.licenseState': 'License state',
  'reAgent.addressId': 'Firm address',
  'reAgent.companyAddress': 'Firm address',
  'reAgent.companyPhoneNumber': 'Firm phone',
  'reAgent.companyLicenseNumber': 'License number',
  'reAgent.companyLogoUrl': 'Firm Logo',
};

@inject('ui')
@observer
@withPusherEvents('close_suggestions_profile')
class ProfileUpdateModal extends Component {
  static propTypes = {
    ui: PropTypes.object,
    suggestion: PropTypes.object,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
  };

  /* eslint-disable */
  flatten = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (
        key !== 'address' &&
        typeof obj[key] === 'object' &&
        obj[key] !== null
      ) {
        const temp = this.flatten(obj[key]);
        for (const key2 in temp) {
          newObj[`${key}.${key2}`] = temp[key2];
        }
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  /* eslint-enable */
  toLabel = (s) => {
    return get(KEY_TO_DISPLAY_NAMES, s) || s;
  };

  renderValue = ({ keyName, contact, value }) => {
    const empty = <span className="gray-text">--</span>;
    const renderers = {
      avatarUrl: ({ contact: c }) =>
        contact.avatarUrl ? <ContactAvatar contact={c} size={80} /> : empty,
      'reAgent.companyLogoUrl': ({ contact: c }) =>
        get(c, 'reAgent.companyLogoUrl') ? (
          <AvatarUploader value={c.reAgent.companyLogoUrl} readOnly />
        ) : (
          empty
        ),
      addressId: ({ contact: c }) =>
        c.addressId ? <AddressLabel address={c.address} /> : empty,
      'reAgent.addressId': ({ contact: c }) =>
        get(c, 'reAgent.addressId') ? (
          <AddressLabel address={c.reAgent.address} />
        ) : (
          empty
        ),
    };

    return renderers[keyName] ? (
      renderers[keyName]({
        contact,
        value,
      })
    ) : (
      <span className={`${clsPrefix}__plain-value`}>{value || empty}</span>
    );
  };

  renderChanges = () => {
    const { suggestion } = this.props;
    const flattenedSuggestedContact = this.flatten(suggestion.contact);
    const newContact = cloneDeep(suggestion.contact);

    return (
      <div className={`${clsPrefix}__profile-container`}>
        <div className={`${clsPrefix}__profile-change`}>
          <div className={`${clsPrefix}__profile-name`} />
          <div className={`${clsPrefix}__profile-oldheader`}>Current</div>
          <div className={`${clsPrefix}__profile-newheader`}>Suggested</div>
        </div>
        {Object.keys(flattenedSuggestedContact).map((keyName) => {
          const label = this.toLabel(keyName);
          const oldValue = get(suggestion.user.contact, keyName);
          const newValue = get(newContact, keyName);

          if (keyName.endsWith('address')) {
            const oldId = get(suggestion.user.contact, `${keyName}Id`);
            const newId = get(newContact, `${keyName}Id`);
            if (oldId === newId) {
              return null;
            }
          }

          if (
            newValue &&
            newValue !== oldValue &&
            KEY_TO_DISPLAY_NAMES[keyName]
          ) {
            return (
              <div className={`${clsPrefix}__profile-change`}>
                <div className={`${clsPrefix}__profile-name`}>{label}</div>
                <div className={`${clsPrefix}__profile-old`}>
                  {this.renderValue({
                    keyName,
                    contact: suggestion.user.contact,
                    value: oldValue,
                  })}
                </div>
                <div className={`${clsPrefix}__profile-new`}>
                  {this.renderValue({
                    keyName,
                    contact: newContact,
                    value: newValue,
                  })}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  onAccept = async () => {
    const { onClose, onSuccess, suggestion, ui } = this.props;
    try {
      await api.auth.updateProfileContactSuggestionStatus(suggestion.id, {
        status: 'APPLIED',
      });
      onSuccess();
    } catch (e) {
      ui.wentWrong(e);
      onClose();
    }
  };

  onDecline = async () => {
    const { onClose, suggestion } = this.props;
    await api.auth.updateProfileContactSuggestionStatus(suggestion.id, {
      status: 'DECLINED',
    });
    onClose();
  };

  pusherEventsHandle = (eventName) => {
    if (eventName === 'close_suggestions_profile') {
      this.props.onClose();
    }
  };

  render() {
    const { suggestion } = this.props;

    let headline;

    if (suggestion.kind === 'PARTY_UPDATE_BY_SELF') {
      headline = `You made following changes to your contact detail in ${get(
        suggestion,
        'partyUpdateBySelf.txnName'
      )}`;
    } else {
      headline = `${get(
        suggestion,
        'partyUpdateByTeam.suggestedBy'
      )} made following changes to
        your contact detail in ${get(suggestion, 'partyUpdateByTeam.txnName')}`;
    }

    return (
      <AppModal
        title="Update Profile Information"
        className={clsPrefix}
        width="600px"
        okText="Update Profile"
        onOk={this.onAccept}
        onCancel={this.onDecline}
        {...this.props}
        closable={false}
        maskClosable={false}
      >
        <div>
          {headline}. Would you like to update your profile with the suggested
          information?
        </div>
        {this.renderChanges()}
      </AppModal>
    );
  }
}

@inject('ui')
@observer
@withPusherEvents('close_suggestions_party')
class PartyUpdateModal extends Component {
  static propTypes = {
    ui: PropTypes.object,
    suggestion: PropTypes.object,
    onClose: PropTypes.func,
  };

  state = {
    status: '',
    error: '',
  };

  onOk = async () => {
    const { suggestion, onClose } = this.props;
    const { status } = this.state;
    if (!status) {
      this.setState({
        error: 'Please select an option',
      });
      return;
    }
    this.setState({
      error: '',
    });

    await api.auth.updatePartyContactSuggestionStatus(suggestion.id, {
      status,
    });
    onClose();
  };

  onSelect = async (e) => {
    this.setState({
      status: e.target.value,
    });
  };

  pusherEventsHandle = (eventName) => {
    if (eventName === 'close_suggestions_party') {
      this.props.onClose();
    }
  };

  render() {
    const { suggestion } = this.props;

    const isSelf = suggestion.ownerId === suggestion.userId;
    const pronoun = isSelf
      ? 'your'
      : `${suggestion.user.contact.firstName} ${suggestion.user.contact.lastName}'s`;

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    return (
      <AppModal
        title="Update Profile Information"
        className={clsPrefix}
        width="600px"
        footer={
          <div className="flex-row">
            <div className="flex-fixed red-text">{this.state.error}</div>
            <div className="flex-long">
              <AppButton type="primary" onClick={this.onOk}>
                {this.state.status === 'NONE' ? 'Done' : 'Update'}
              </AppButton>
            </div>
          </div>
        }
        {...this.props}
        closable={false}
        maskClosable={false}
      >
        <div className={`${clsPrefix}__party-container`}>
          <CheckCircleTwoTone
            className={`${clsPrefix}__party-done`}
            twoToneColor="#52c41a"
          />
          <div className={`${clsPrefix}__party-title`}>
            {capitalize(pronoun)} profile has been updated.
          </div>
          <div className={`${clsPrefix}__party-subtitle`}>
            Would you like to apply these changes to {pronoun} other
            transactions?
          </div>
          <Radio.Group
            buttonStyle="solid"
            onChange={this.onSelect}
            className={`${clsPrefix}__party-choices`}
          >
            <Radio style={radioStyle} value="ACTIVE_TRANSACTIONS">
              <strong>Yes</strong>, only active transactions
            </Radio>
            <Radio style={radioStyle} value="ALL_TRANSACTIONS">
              <strong>Yes</strong>, all transactions (including archived)
            </Radio>
            <Radio style={radioStyle} value="NONE">
              <strong>No</strong>, don’t update existing transactions
            </Radio>
          </Radio.Group>
        </div>
      </AppModal>
    );
  }
}

@observer
export default class ContactSuggestionModal extends Component {
  static propTypes = {
    suggestion: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { suggestion } = props;
    this.state = {
      status:
        suggestion.profileUpdateStatus === 'AWAITING' ? 'PROFILE' : 'PARTY',
      suggestionId: suggestion.id,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { suggestion } = props;
    if (suggestion.id === state.suggestionId) {
      // eslint-disable-line react/no-unused-state
      return state;
    }
    return {
      status:
        suggestion.profileUpdateStatus === 'AWAITING' ? 'PROFILE' : 'PARTY',
      suggestionId: suggestion.id,
    };
  }

  onProfileUpdateSuccess = () => {
    this.setState({
      status: 'PARTY',
    });
  };

  onClose = () => {
    this.setState({
      status: '',
    });
  };

  render() {
    const { suggestion } = this.props;
    const { status, suggestionId } = this.state;

    return (
      <React.Fragment>
        <ProfileUpdateModal
          suggestion={suggestion}
          onClose={this.onClose}
          onSuccess={this.onProfileUpdateSuccess}
          visible={status === 'PROFILE'}
          suggestionId={suggestionId}
        />
        <PartyUpdateModal
          suggestion={suggestion}
          onClose={this.onClose}
          visible={status === 'PARTY'}
        />
      </React.Fragment>
    );
  }
}
