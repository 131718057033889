import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { cancelable, clearCancelables } from 'src/utils/cancelable';
import { observer } from 'mobx-react';

@observer
export default class Lazy extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  state = {
    loaded: false,
  };

  componentDidMount() {
    cancelable(this.props.component(), this).promise.then(
      (module) => {
        this.component = module.default ? module.default : module;
        this.setState({
          loaded: true,
        });
      },
      () => {
        // pass, do not bubble up since there's no reason for this error to be seen anywhere
      }
    );
  }

  componentWillUnmount() {
    clearCancelables(this);
  }

  render() {
    const { loaded } = this.state;

    if (loaded) {
      const LoadedComponent = this.component;
      return <LoadedComponent {...this.props} />;
    }
    return <div style={this.props.style} />;
  }
}
