import { computed } from 'mobx';
import type { FormConfigItemCurrency } from 'src/types/proto/reform';
import BaseItemDelegate from './base';

export default class CurrencyItemDelegate extends BaseItemDelegate<FormConfigItemCurrency> {
  @computed
  get isPercentageEditable() {
    return this.params.isPercentageEditable;
  }

  @computed
  get percentageBoundFieldId() {
    return this.params.percentageBoundFieldId;
  }
}
