import scrollIntoView, {
  type ScrollIntoViewConfig,
} from 'dom-scroll-into-view';

export function computedStyle(el: Element, prop: string) {
  // TODO: From CSS-Query notes, we might need (node, null) for FF
  const style = window.getComputedStyle?.(el);
  if (style) {
    return style[
      // Switch to camelCase for CSSOM
      // DEV: Grabbed from jQuery
      // https://github.cmo/jquery/jquery/blob/1.9-stable/src/css.js#L191-L194
      // https://github.com/jquery/jquery/blob/1.9-stable/src/core.js#L593-L597
      prop.replace(/-(\w)/gi, (word, letter) => {
        return letter.toUpperCase();
      }) as keyof CSSStyleDeclaration
    ];
  }
  return undefined;
}

export function isScrollable(node: Element) {
  const overflowY = computedStyle(node, 'overflowY');
  return overflowY === 'auto' || overflowY === 'scroll';
}

export function getScrollableContainer(n: HTMLElement) {
  let node = n;
  let nodeName;
  /* eslint no-cond-assign:0 */
  while ((nodeName = node.nodeName.toLowerCase()) !== 'body') {
    if (node !== n && isScrollable(node)) {
      return node;
    }
    node = node.parentNode as HTMLElement;
  }
  return nodeName === 'body' ? node.ownerDocument : node;
}

export default function scrollTo(
  target: HTMLElement,
  options?: ScrollIntoViewConfig
) {
  const container = getScrollableContainer(target) as HTMLElement;
  setTimeout(() => scrollIntoView(target, container, options), 0);
}
