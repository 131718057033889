import FakeForm from 'src/components/common/fake-form';
import React from 'react';
import { Input } from 'antd';
import { ModalQuickForm } from 'src/components/forms/quick-form';
import { isEmail } from 'src/validators';
import { observer } from 'mobx-react';

export default observer(({ badPrimaryEmail, onSave }) => {
  return (
    <ModalQuickForm
      modalProps={{
        visible: true,
        cancelable: false,
        closable: false,
        title: 'Please update your email',
      }}
      getErrors={(currentValues) => {
        const errors = {};
        if (!currentValues.email || !isEmail(currentValues.email)) {
          errors.email = 'Invalid email address.';
        } else if (currentValues.email === badPrimaryEmail.email) {
          errors.email = 'Please enter a new email.';
        }
        return errors;
      }}
      key="fix-email-modal"
      onSave={async ({ email }) => onSave(email)}
      initialValue={{
        email: badPrimaryEmail.email,
      }}
    >
      {({ onChange, currentValues, errors }) => (
        <FakeForm>
          <p>
            We weren&apos;t able to deliver mail to your email address{' '}
            <strong>{badPrimaryEmail.email}</strong>, most likely because of a
            typo in this address. Please correct this email or enter different
            email, or you may lose access to your account.
          </p>
          <FakeForm.Item
            validateStatus={errors.email ? 'error' : null}
            help={errors.email}
          >
            <Input
              value={currentValues.email}
              onChange={(e) =>
                onChange({
                  email: e.target.value,
                })
              }
            />
          </FakeForm.Item>
        </FakeForm>
      )}
    </ModalQuickForm>
  );
});
