import { computed, makeObservable, override } from 'mobx';
import type {
  FormConfigItemDynamicExplanation,
  FormConfigItemDynamicExplanationStringChoiceLink,
} from 'src/types/proto/reform';
import type Item from '../item';
import BaseItemDelegate from './base';

class Link<
  ParentItem extends Item<
    FormConfigItemDynamicExplanation,
    BaseItemDelegate<FormConfigItemDynamicExplanation>
  >
> {
  data: FormConfigItemDynamicExplanationStringChoiceLink;
  item: ParentItem;

  constructor(
    item: ParentItem,
    json: FormConfigItemDynamicExplanationStringChoiceLink
  ) {
    makeObservable(this);
    this.data = json;
    this.item = item;
  }

  @computed
  get choices() {
    return this.data.choices || [];
  }

  @computed
  get requireNote() {
    return this.data.requireNote;
  }

  @computed
  get fieldId() {
    return this.data.fieldId;
  }

  @computed
  get isAdditional() {
    return this.data.isAdditional;
  }

  @computed
  get linkedItem() {
    return this.item.formConfig.itemsByFieldId[this.fieldId];
  }

  @computed
  get conditionFullfilled() {
    return this.choices.some((c) => this.linkedItem?.isValueEqual(c));
  }
}

export default class DynamicExplanationItemDelegate extends BaseItemDelegate<FormConfigItemDynamicExplanation> {
  @override
  get question() {
    return this.textLabel;
  }

  @computed
  get links() {
    return (this.params.links || []).map((link) => new Link(this.item, link));
  }

  @computed
  get textLabel() {
    return this.params.textLabel;
  }

  @computed
  get conditionFullfilled() {
    return this.links.some((link) => link.conditionFullfilled);
  }

  @computed
  get requireNote() {
    return this.links.some(
      (link) => link.requireNote && link.conditionFullfilled
    );
  }

  @override
  get isVisible() {
    return !this.isNa && this.conditionFullfilled;
  }

  @override
  get optional() {
    return !!this.field?.optional && !this.requireNote;
  }

  @computed
  get isLongAnswer() {
    return true;
  }
}
