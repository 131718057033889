import integerToWords from 'src/utils/integer-to-words';

export default function decimalToWords(decimal: string) {
  const parts = decimal.split('.');

  let decimalStr = integerToWords(parts[0]);

  if (parts[1] && parseInt(parts[1], 10) !== 0) {
    decimalStr = `${decimalStr} point`;

    const decimals = parts[1].split('');

    decimals.forEach((n) => {
      decimalStr = `${decimalStr} ${integerToWords(n)}`;
    });
  }

  return decimalStr;
}
