export interface Contact {
  firstName?: string;
  lastName?: string;
  email: string;
}

export default function getFullNameOrEmail(contact?: Partial<Contact>): string {
  const { firstName, lastName, email } = contact || {};
  let parts = [];
  if (firstName) {
    parts = [firstName, lastName];
  } else {
    parts = [email];
  }
  return parts
    .map((n) => n && n.trim())
    .filter((n) => n)
    .join(' ');
}
