import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { SnakeCasedProperties } from 'type-fest';
import type { Address } from 'src/types/proto/properties';
import type {
  SearchTransactionsRequest,
  UpdateTransactionCountyRequest,
} from 'src/types/proto/services/transaction_admin_service';
import type { TransactionList } from 'src/types/proto/transactions';
import Transactions from '../public/transactions';

export default class AdminTransactions extends Transactions {
  searchAllByTitle(
    args: Partial<SnakeCasedProperties<SearchTransactionsRequest>> // FIXME: Handle case transform inside this function
  ) {
    const params = omitBy(args, isNil);
    return this.get<TransactionList>(
      '/transactions/transactions_search_all_by_title',
      {
        params,
      }
    );
  }

  changeCounty({ transactionId, county }: UpdateTransactionCountyRequest) {
    return this.post<Address>('/transactions/update_county', {
      transactionId,
      county,
    });
  }

  getTransUrl(transactionId: string) {
    return `/transactions/${encodeURIComponent(transactionId)}`;
  }

  cleanTds(transactionId: string) {
    return this.post<void>(`${this.getTransUrl(transactionId)}/clean_tds`);
  }
  
  adminRunIntent(transactionId: string, intentInfo: Object) {
    return this.post<void>(`${this.getTransUrl(transactionId)}/admin_run_intent`, intentInfo);
  }
}
