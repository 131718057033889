import Field from './base';
import fieldOutput from './field-output';

export default class PhoneNumber extends Field {
  static outputs = [fieldOutput('as_string', 'string_format', 'string')];

  renderString(value) {
    if (!value) {
      return value;
    }

    /*
     * Some US numbers are lacking formatting because it wasn't
     * enforced at all. This is a naive solution to catch most instances
     * of this as it's not worth it and a potentially unsurmountable task
     * to chase and fix every possible value of "unformatted" phone numbers
     */
    if (value?.match && value.match(/^[\d ]+$/g)) {
      const v = value.replace(/\s/g, '');
      if (v.length === 10) {
        return `(${v.substr(0, 3)}) ${v.substr(3, 3)}-${v.substr(6, 4)}`;
      }
    }

    return value;
  }
}
