import type { EmbeddedFeature } from 'src/stores/embedded-app-store';
import type RouterStore from 'src/stores/router-store';
import type { Route } from 'src/types/proto/routes';

export interface NavigateOptions {
  route?: Route;
  path?: string;
  noBack?: boolean;
  search?: Record<string, unknown>;
  replace?: boolean;
  f?: unknown;
  /**
   * Since flow pages route name doesn't show which feature it is related, this
   * option indicates the target feature that we want to jump to, probably
   * another embedded app or even to iframeless apps.
   */
  feature?: EmbeddedFeature;
}

export default function navigateToFlow(
  router: RouterStore,
  flowId: string,
  options: NavigateOptions = {}
) {
  const route = options.route || (router.route as Route);
  /** @example `signatures.flow.page` */
  const routeName = `${options.feature ? `${options.feature}.` : ''}flow.page`;
  return router.navigatePromise(
    routeName,
    {
      flowId,
      path: options.path || 'root',
      back: options.noBack
        ? undefined
        : JSON.stringify({
            name: route.name,
            params: route.params,
          }),
      ...options.search,
    },
    {
      replace: options.replace,
    }
  );
}

export function flowRoute(
  router: RouterStore,
  flowId: string,
  options: NavigateOptions = {}
) {
  const route = options.route || (router.route as Route);
  return {
    name: 'flow.page',
    params: {
      flowId,
      path: options.path || 'root',
      back: options.noBack
        ? undefined
        : JSON.stringify({
            name: route.name,
            params: route.params,
          }),
      f: options.f,
      ...options.search,
    },
  };
}
