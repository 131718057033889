export default class AddressStore {
  static addressMap = new Map();
}

// HACK: Avoids needing to pass the address store into free floating
// functions that help create fieldOptions for forms.
export function ensureAddress(address) {
  if (!address || !address.id) {
    return undefined;
  }

  AddressStore.addressMap.set(address.id, address);
  return address.id;
}

export const ensureAddresses = (addresses) =>
  (addresses || []).map((a) => ensureAddress(a));

export function getAddress(addressId) {
  return AddressStore.addressMap.get(addressId);
}

export function reformToProtoAddress(address) {
  return {
    street: (address && address.street) || '',
    city: (address && address.city) || '',
    state: (address && address.state) || '',
    county: (address && address.county) || '',
    zipCode: (address && address.zip_code) || '',
    unit: (address && address.unit) || '',
    id: (address && address.address_id) || '',
  };
}

export function protoToReformAddress(address) {
  return {
    street: (address && address.street) || '',
    city: (address && address.city) || '',
    state: (address && address.state) || '',
    county: (address && address.county) || '',
    zip_code: (address && address.zipCode) || '',
    unit: (address && address.unit) || '',
    id: (address && address.id) || '',
    address_id: (address && address.id) || '',
    address,
  };
}
