import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Tooltip } from 'antd';
import { getColor } from 'src/utils/get-color';

const HelpIconTooltip = ({ title, style, ...otherProps }) => (
  <Tooltip title={title} trigger="hover">
    <Icon
      type="info-circle"
      style={{
        color: getColor('black-04-color'),
        ...style,
      }}
      {...otherProps}
    />
  </Tooltip>
);

HelpIconTooltip.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
};

export default HelpIconTooltip;
