import AppFullErrorMessage from './app-full-error-message';
import AppReleaseModal from './app-release-modal';
import Lazy from 'src/components/common/lazy';
import PolicyAcceptanceModal from 'src/components/account/policy-acceptance';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

export const DEFAULT_BOTTOM_BAR_HEIGHT = '45px';

@inject('ui', 'uploads', 'annotations')
@observer
export default class UiGlobals extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    uploads: PropTypes.object,
  };

  componentDidUpdate() {
    const rootEl = document.getElementById('root');
    Object.assign(rootEl, {
      style: this.props.ui.displayDocument ? 'overflow: hidden;' : '',
    });
  }

  handlePspdfkitLoaded = async (pspdfkitInstance) => {
    const { annotations } = this.props;
    await annotations.setPspdfkitInstance({ pspdfkitInstance });
  };

  render() {
    const { ui, uploads } = this.props;
    const { displayDocument, previewDocument, fullErrorMessage, fullUIError } =
      ui;

    const closeDocumentViewer = () => ui.setDisplayDocument(null);
    const closePreviewViewer = () => ui.setPreviewDocument(null);
    return (
      <React.Fragment>
        {ui.customModal}
        {uploads && uploads.fileUploaderBtn}
        {displayDocument && (
          <Lazy
            component={() =>
              import('src/components/documents/simple-document-viewer')
            }
            doc={displayDocument}
            onClose={closeDocumentViewer}
            onPspdfkitLoaded={this.handlePspdfkitLoaded}
            readOnly
          />
        )}
        {!!previewDocument && (
          <Lazy
            component={() =>
              import('src/components/documents/simple-preview-viewer')
            }
            onClose={closePreviewViewer}
            onPspdfkitLoaded={this.handlePspdfkitLoaded}
            {...previewDocument}
          />
        )}
        {fullErrorMessage && (
          <AppFullErrorMessage
            message={fullErrorMessage}
            fullError={fullUIError}
          />
        )}
        {!ui.isEmbedded && <AppReleaseModal />}
        {!ui.isEmbedded && <PolicyAcceptanceModal />}
      </React.Fragment>
    );
  }
}
