export default class OutputHandler {
  /*
    This is the class that defines the logic for a particular
    output Handler. Analagous to OutputHandler on the backend,
    all that must be defined here is how to compute the value
    given the outContext containig the aliased input values
  */
  constructor(id) {
    this.id = id;
  }

  getParams(outctx) {
    return outctx?.output?.params || {};
  }

  getValue(value) {
    return value;
  }

  getIdentityFieldName(_outctx) {
    return 'this';
  }

  inputKind() {}
}
