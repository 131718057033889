import isEqual from 'lodash/isEqual';
import { computed } from 'mobx';
import type { FormConfigItemBoolean } from 'src/types/proto/reform';
import BaseItemDelegate from './base';

export default class BooleanItemDelegate extends BaseItemDelegate<FormConfigItemBoolean> {
  @computed
  get isCheckbox() {
    return this.params.isCheckbox;
  }

  @computed
  get yesLabel() {
    return this.params.yesLabel;
  }

  @computed
  get yesValue() {
    return this.params.yesValue;
  }

  @computed
  get noLabel() {
    return this.params.noLabel;
  }

  @computed
  get noValue() {
    return this.params.noValue;
  }

  toBoundFieldValue(val: any) {
    let baseValue;
    let fieldValue;
    // Special condition required by the string_choice widget, which outputs an object
    if (val && typeof val === 'object') {
      baseValue = val.value;
    } else {
      baseValue = val;
    }
    if (typeof baseValue === 'string') {
      fieldValue = baseValue === this.yesValue;
    } else {
      fieldValue = baseValue;
    }
    return [fieldValue, { [this.fieldId]: fieldValue }];
  }

  toWidgetValue(val = this.value) {
    const fieldValue = {
      value: val ? this.yesValue : this.noValue,
    };
    return [fieldValue, { [this.flowKey]: fieldValue }];
  }

  setValue(val: any) {
    const [fieldValue] = this.toBoundFieldValue(val);
    this.topLinkedOutput?.setFields({
      this: fieldValue,
    });
  }

  isValueEqual(val: any) {
    if (this.isCheckbox && typeof val === 'string') {
      if (this.value) {
        return val === this.yesValue;
      }

      return val === this.noValue;
    }
    return isEqual(this.value, val);
  }
}
