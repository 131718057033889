export const APP_ROLE_UNKNOWN = 'UNKNOWN_APP_ROLE';

export const APP_ROLE_SUPPORT_ONLY = 'SUPPORT_ONLY';

export const APP_ROLE_SUPER_ADMIN = 'SUPERADMIN';

export const APP_ROLE_CAR_ADMIN = 'CAR_ADMIN';

export const APP_ROLE_ANNOTATOR = 'ANNOTATOR';

export const APP_ROLES = [
  APP_ROLE_UNKNOWN,
  APP_ROLE_SUPPORT_ONLY,
  APP_ROLE_SUPER_ADMIN,
  APP_ROLE_CAR_ADMIN,
  APP_ROLE_ANNOTATOR,
];

export const ACCESS_MODES = ['TMS', 'TMS_TC', 'AGENT', 'CLIENT'];
