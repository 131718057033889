import Field from './base';
import fieldOutput from './field-output';

export default class Time extends Field {
  static outputs = [
    fieldOutput('with_period', 'string_format', 'string'),
    fieldOutput('without_period', 'string_format', 'string'),
  ];

  renderString(value) {
    const format = this.params.format || 'without_period';
    const regex = /^(\d{1,2}:\d{2}(?::\d{2}){0,1}) {0,1}([aApP][mM]){0,1}$/;

    const withPeriod = (v = '') => {
      const matches = `${v}`.match(regex);

      if (!matches) {
        return '';
      }

      return `${matches[1]} ${(matches[2] || '').toUpperCase()}`.trim();
    };

    const withoutPeriod = (v = '') => {
      const matches = `${v}`.match(regex);

      if (!matches) {
        return '';
      }

      return `${matches[1]}`;
    };

    const formaters = {
      with_period: withPeriod,
      without_period: withoutPeriod,
    };

    return (formaters[format] || ((v) => v || ''))(value);
  }
}
