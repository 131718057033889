import type { SearchTemplatesRequest } from 'src/types/proto/services/template_admin_service';
import type { TransactionList } from 'src/types/proto/transactions';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import BaseApi from '../base-api';

const getBaseUrl = (id?: string) =>
  `/transaction_templates/${id ? `${id}/` : ''}`;

export default class TransactionTemplates extends BaseApi {
  search = (params: SearchTemplatesRequest) => {
    return this.get<TransactionList>(`${getBaseUrl()}search/`, {
      params: formatToSnakeCase(params),
    });
  };
}
