import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';
import toArray from 'src/utils/to-array';
import {
  isEmail as validatorsIsEmail,
  isUrl as validatorIsUrl,
} from 'src/validators';

// If there is an array of validation functions for a particular field,
// if any function returns `BREAK`, we won't run the rest.
export const BREAK = {};

export function runValidation(validation, ...args) {
  let error;
  if (isFunction(validation)) {
    error = validation(...args);
  } else if (isArray(validation)) {
    for (let i = 0; i < validation.length; i++) {
      error = validation[i](...args);
      if (error) {
        break;
      }
    }
  }

  if (error === BREAK) {
    return undefined;
  }

  return error;
}

export const Optional = () => {
  const fn = (v) => (!v ? BREAK : undefined);
  fn.required = false;
  return fn;
};

export const Required = (options) => {
  const fn = (v) => {
    const { message, label, trim } = {
      trim: true,
      ...options,
    };

    if (v) {
      if (isArray(v)) {
        if (v.length) {
          return undefined;
        }
      } else if (!trim || !v.trim || v.trim()) {
        return undefined;
      }
    }

    if (message) {
      return message;
    }

    if (label) {
      return `${label} is required.`;
    }

    return 'Field is required.';
  };

  fn.required = true;
  return fn;
};

function isRequiredValidator(fn) {
  return fn.required === true || fn.required === false;
}

export const Email =
  (message = 'Invalid email.') =>
  (v) =>
    validatorsIsEmail(v) ? undefined : message;

export const Url =
  (message = 'Invalid URL.') =>
  (v) =>
    validatorIsUrl(v) ? undefined : message;

export function ensureRequiredValidatorIsFirstAndOnly(validationSpec) {
  let lastRequiredValidator;
  const filteredValidationArr = toArray(validationSpec).filter((fn) => {
    if (isRequiredValidator(fn)) {
      lastRequiredValidator = fn;
      return false;
    }
    return true;
  });

  return [...toArray(lastRequiredValidator), ...filteredValidationArr];
}

export function isRequired(validationSpec) {
  const validationSpecArr = toArray(validationSpec);
  return (
    validationSpec &&
    validationSpecArr[0] &&
    validationSpecArr[0].required === true
  );
}

export const mergeValidation = (target, ...others) => {
  const res = {};

  [target, ...others].forEach((validation) =>
    Object.entries(validation || {}).forEach(([k, validationSpec]) => {
      res[k] = [...toArray(res[k]), ...toArray(validationSpec || undefined)];
    })
  );

  return mapValues(res, (validationArr) =>
    ensureRequiredValidatorIsFirstAndOnly(validationArr)
  );
};
