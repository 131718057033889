import { computed, makeObservable } from 'mobx';
import type TransactionApp from './items/transaction-app/index';
import type { ValidItemTransactionAppType } from './items/transaction-app/index';
import type Transaction from './transaction';
import type { TransactionStore } from './transaction';

export const { SELLER_DISCLOSURES_GUID, PROPERTY_PORTAL_GUID, OFFERS_GUID } =
  window.Glide.CONSTANTS;

export default class Apps {
  store: TransactionStore;
  transaction: Transaction;
  constructor(store: TransactionStore, transaction: Transaction) {
    makeObservable(this);
    this.store = store;
    this.transaction = transaction;
  }

  @computed
  get registry() {
    return this.transaction.meta?.appRegistry;
  }

  @computed
  get sellerDisclosuresApp(): TransactionApp<ValidItemTransactionAppType> {
    return this.get(SELLER_DISCLOSURES_GUID);
  }

  @computed
  get propertyPortalApp(): TransactionApp<ValidItemTransactionAppType> {
    return this.get(PROPERTY_PORTAL_GUID);
  }

  @computed
  get offersApp(): TransactionApp<ValidItemTransactionAppType> {
    return this.get(OFFERS_GUID);
  }

  get = (guid: string) => {
    const record = this.registry?.records.find((r) => r.guid === guid);
    return record && this.store.itemsById.get(record.itemId);
  };
}
