import { computed } from 'mobx';
import type { FormConfigItemStringOption } from 'src/types/proto/reform';
import BaseItemDelegate from './base';

const SHORT_ANSWER = 'SHORT_ANSWER';
const LONG_ANSWER = 'LONG_ANSWER';

export default class StringItemDelegate extends BaseItemDelegate<FormConfigItemStringOption> {
  @computed
  get variant() {
    return this.params.variant;
  }

  @computed
  get isShortAnswer() {
    return this.variant === SHORT_ANSWER;
  }

  @computed
  get isLongAnswer() {
    return this.variant === LONG_ANSWER;
  }
}
