import capitalize from 'lodash/capitalize';
import toLower from 'lodash/toLower';
import decimalToWords from 'src/utils/decimal-to-words';
import Field from './base';
import fieldOutput from './field-output';

export default class Percentage extends Field {
  static outputs = [
    fieldOutput('no_percent_sign', 'string_format', 'string'),
    fieldOutput('with_percent_sign', 'string_format', 'string'),
    fieldOutput('words', 'string_format', 'string'),
    fieldOutput('words_with_percent', 'string_format', 'string'),
  ];

  renderString(value) {
    const format = this.params.format || 'no_percent_sign';
    function words(v) {
      if (!v) {
        return '';
      }
      const clearValue = v.replace(/,/g, '');
      const toWords = decimalToWords(clearValue);
      const percentageStr = toLower(toWords).replace(/\w+/g, capitalize);
      return percentageStr;
    }

    const formaters = {
      no_percent_sign: (v) => v || '',
      with_percent_sign: (v) => (v || '') && `${v}%`,
      words,
      words_with_percent: (v) => (v ? `${words(v)} Percent` : ''),
    };
    return (formaters[format] || ((v) => v || ''))(value);
  }
}
