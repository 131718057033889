import Address from './address';
import Contact from './contact';
import Currency from './currency';
import Date_ from './date';
import Decimal from './decimal';
import Field from './base';
import Integer from './integer';
import Percentage from './percentage';
import PhoneNumber from './phone-number';
import PositiveInteger from './positive-integer';
import PositiveNonzeroInteger from './positive-nonzero-integer';
import RecurringFee from './recurring-fee';
import SimpleStringChoice from './simple-string-choice';
import StringList from './string-list';
import String_ from './string';
import Time from './time';
import Timestamp from './timestamp';

const KIND_CLASSES = {
  string: String_,
  string_list: StringList,
  simple_string_choice: SimpleStringChoice,
  address: Address,
  currency: Currency,
  date: Date_,
  time: Time,
  recurring_fee: RecurringFee,
  percentage: Percentage,
  contact: Contact,
  integer: Integer,
  positive_integer: PositiveInteger,
  positive_nonzero_integer: PositiveNonzeroInteger,
  decimal: Decimal,
  phone_number: PhoneNumber,
  timestamp: Timestamp,
};

export function getFieldOutputs(kind, outKind) {
  const FieldClass = KIND_CLASSES[kind];
  if (!FieldClass) {
    return [];
  }

  return (FieldClass.outputs || []).filter(
    (fo) => !outKind || fo.outKind === outKind
  );
}

export function getFieldInstance(kind, params, renderOptions) {
  const FieldClass = KIND_CLASSES[kind] || Field;
  return new FieldClass(kind, params, renderOptions);
}
