import Decimal from 'decimal.js';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import range from 'lodash/range';
import OutputHandler from './base';


function addCommas(nStr) {
  // eslint-disable-next-line no-param-reassign
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    // eslint-disable-next-line no-useless-concat
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

export default class Arithmetic extends OutputHandler {
  calcExpressionValue(expression, terms) {
    const OPS = {
      '+': {
        method: 'plus',
        precedence: 0,
      },
      '-': {
        method: 'minus',
        precedence: 0,
      },
      '/': {
        method: 'dividedBy',
        precedence: 1,
      },
      '*': {
        method: 'times',
        precedence: 1,
      },
    };
    const tokens =
      expression.match(/[+/*()-]|(?:term\d{1,2})|(?:\d+(?:\.{0,1}\d+)?)/g) ||
      [];
    const values = [];
    const operators = [];

    const isTerm = (token) => /^term\d{1,2}$/.test(token);

    const isConstant = (token) => /^\d+(?:\.{0,1}\d+)?$/.test(token);

    const peek = (stack) => stack[stack.length - 1];

    const apply = () => {
      const op = operators.pop();
      const right = values.pop();
      const left = values.pop();
      values.push(left[OPS[op].method](right));
    };

    const greaterPrecedence = (a, b) => {
      return OPS[a].precedence >= OPS[b].precedence;
    };

    tokens.forEach((token) => {
      let top;

      if (isTerm(token)) {
        const idx = parseInt(token.match(/\d{1,2}/), 10) - 1;
        values.push(terms[idx]);
      } else if (isConstant(token)) {
        values.push(new Decimal(token));
      } else if (token === '(') {
        operators.push(token);
      } else if (token === ')') {
        top = peek(operators);
        while (top && top !== '(') {
          apply();
          top = peek(operators);
        }
        operators.pop();
      } else {
        top = peek(operators);

        while (
          top &&
          !['(', ')'].includes(top) &&
          greaterPrecedence(top, token)
        ) {
          apply();
          top = peek(operators);
        }
        operators.push(token);
      }
    });

    while (peek(operators)) {
      apply();
    }

    return values[0];
  }

  pdfValue(outctx) {
    const params = this.getParams(outctx);
    const terms = [];
    const op = camelCase(get(params, 'op', 'plus'));
    const expression = get(params, 'expression', false);
    try {
      range(1, params.count + 1).forEach((i) => {
        const termVal = `${outctx.get(`term${i}`) || 0}`;
        const isPercentage = termVal.includes('%');
        terms.push(
          new Decimal(termVal.replace(/[,$%\s]/g, '')).times(
            isPercentage ? 0.01 : 1
          )
        );
      });
    } catch (e) {
      // this can happen if some of the inputs are unlinked
      return '';
    }

    let result;

    if (expression) {
      result = this.calcExpressionValue(expression, terms);
    } else {
      result = terms.reduce(
        (m, t) => m[op](t),
        terms.shift() || new Decimal(0)
      );
    }

    if (result.isNaN()) {
      return '';
    }

    const decimalPlaces = parseInt(params.decimal_places, 10);
    if (decimalPlaces >= 0) {
      result = result.toFixed(decimalPlaces, Decimal.ROUND_HALF_UP);
    } else {
      result = result.toString();
    }

    if (result === 'Infinity') {
      return '';
    }

    if (params.thousands_separator) {
      result = addCommas(result);
    }
    result = params.prefix ? `${params.prefix} ${result}` : result;
    result = params.postfix ? `${result} ${params.postfix}` : result;
    return result;
  }
}
