import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { action, computed, makeObservable, observable } from 'mobx';
import type { Field } from 'src/types/proto/reform';

export interface Source {
  namespace: string;
  getFieldValue(fieldId: string): Field;
  getFieldIsUnlinked(fieldId: string): boolean;
  getFieldIds?(): string[];
}

export interface Validation {
  status: 'error' | 'validating' | 'success';
  message?: string;
}

export default class Namespace {
  /*
     A Namespace is a abstraction for a container of data where
     fields can be staged. It depends on a Source, which is
     assumed to be live-updated object with a `getFieldValue`
     method. For Transaction Fields, this is a TransactionFields Item
  */
  @observable staged = new Map<string, Field>();
  @observable stagedIsUnlinked = new Map<string, boolean>();
  @observable stagedValidations = new Map<string, Validation>();
  source: Source;

  @computed
  get isDirty() {
    return !!this.staged.size || !!this.stagedIsUnlinked.size;
  }

  @computed
  get id() {
    return this.source.namespace;
  }

  constructor(source: Source) {
    makeObservable(this);
    this.source = source;
  }

  get(fieldId: string) {
    if (this.staged.has(fieldId)) {
      return this.staged.get(fieldId);
    }
    return this.source.getFieldValue(fieldId);
  }

  getIsUnlinked(fieldId: string) {
    if (this.stagedIsUnlinked.has(fieldId)) {
      return this.stagedIsUnlinked.get(fieldId);
    }
    return this.source.getFieldIsUnlinked(fieldId);
  }

  getStaged() {
    return Object.fromEntries(this.staged);
  }

  getStagedValidation(fieldId: string) {
    return this.stagedValidations.get(fieldId);
  }

  getStagedUnlinkedIds() {
    const res: string[] = [];
    this.stagedIsUnlinked.forEach((v, k) => {
      if (v) {
        res.push(k);
      }
    });
    return res;
  }
  getStagedLinkedIds() {
    const res: string[] = [];
    this.stagedIsUnlinked.forEach((v, k) => {
      if (!v) {
        res.push(k);
      }
    });
    return res;
  }

  @action
  stage(data: Record<string, Field>) {
    toPairs(data).forEach(([k, v]) => {
      const sourceUnlinked = this.source.getFieldIsUnlinked(k);
      const stagedUnlinked = this.stagedIsUnlinked.get(k);
      this.stagedValidations.delete(k);
      if (this.source.getFieldValue(k) === v) {
        this.staged.delete(k);

        if (
          (sourceUnlinked === true || sourceUnlinked === false) &&
          stagedUnlinked === sourceUnlinked
        ) {
          this.stagedIsUnlinked.delete(k);
        }
      } else {
        // this ensures the last edit wins by saying
        // ensuring any edit causes that field to
        // be considered newly marked as linked/unlinked
        this.staged.set(k, v);
        if (
          (sourceUnlinked === true || sourceUnlinked === false) &&
          !this.staged.has(k)
        ) {
          this.stagedIsUnlinked.set(k, sourceUnlinked);
        }
      }
    });
  }

  @action
  stageValidation(data: Record<string, Validation>) {
    Object.entries(data).forEach(([fieldId, fieldValue]) => {
      this.stagedValidations.set(fieldId, fieldValue);
    });
  }

  @action
  stageIsUnlinked(fieldId: string, unlinked: boolean) {
    const sourceUnlinked = this.source.getFieldIsUnlinked(fieldId);
    if (
      (sourceUnlinked === true || sourceUnlinked === false) &&
      unlinked === sourceUnlinked
    ) {
      this.stagedIsUnlinked.delete(fieldId);
    } else {
      this.stagedIsUnlinked.set(fieldId, !!unlinked);
    }
    if (!unlinked) {
      this.unstageField(fieldId);
    }
  }

  @action
  unstage() {
    this.staged.clear();
    this.stagedIsUnlinked.clear();
    this.stagedValidations.clear();
  }

  @action
  unstageField(fieldId: string) {
    if (this.staged.has(fieldId)) {
      this.staged.delete(fieldId);
    }
    if (this.stagedValidations.has(fieldId)) {
      this.stagedValidations.delete(fieldId);
    }
  }

  @action
  updateSource(source: Source) {
    this.source = source;
    this.unstageDuplicateValues();
  }

  @action
  unstageDuplicateValues() {
    this.staged.forEach((v, k) => {
      if (isEqual(v, this.source.getFieldValue(k))) {
        this.staged.delete(k);
        this.stagedValidations.delete(k);
      }
    });
    this.stagedIsUnlinked.forEach((v, k) => {
      if (isEqual(v, this.source.getFieldIsUnlinked(k))) {
        this.stagedIsUnlinked.delete(k);
      }
    });
  }
}
