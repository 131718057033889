import type { Association, RELicense } from 'src/types/proto/brokers';
import type {
  GetBrokersAssociationsRequest,
  GetBrokersAssociationsResponse,
  SetBrokersAssociationsMembershipsForMeResponse,
} from 'src/types/proto/services/broker_public_service';
import type { ApiResponseData } from 'src/types/utils';
import BaseApi from '../base-api';

export default class Brokers extends BaseApi {
  licensesVerify(org: string, id: string, lname = '') {
    return this.post<RELicense>(
      `/brokers/licenses/verify?org=${encodeURIComponent(
        org
      )}&id=${encodeURIComponent(id)}&lname=${encodeURIComponent(lname)}`,
      {}
    );
  }

  associationsList(params?: GetBrokersAssociationsRequest) {
    return this.get<ApiResponseData<GetBrokersAssociationsResponse>>(
      '/brokers/associations',
      {
        params,
      }
    );
  }

  associationsDetail(associationId: string) {
    return this.get<Association>(`/brokers/associations/${associationId}`);
  }

  associationMembershipsSetMe(associationIds: string[]) {
    return this.post<
      ApiResponseData<SetBrokersAssociationsMembershipsForMeResponse>
    >('/brokers/association_memberships/set_me/', {
      associationIds,
    });
  }
}
