import PropTypes from 'prop-types';
import { Children, Component } from 'react';

class RouterProvider extends Component {
  getChildContext() {
    return {
      router: this.props.router,
    };
  }

  render() {
    const { children } = this.props;
    return Children.only(children);
  }
}

RouterProvider.propTypes = {
  router: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

RouterProvider.childContextTypes = {
  router: PropTypes.object.isRequired,
};

export default RouterProvider;
