import type { SnakeCasedProperties } from 'type-fest';
import type {
  Action,
  FlowReflection,
  Layout,
  State,
  SuperFlowLayout,
} from 'src/types/proto/flows';
import type {
  CloseSuperFlowRequest,
  GetFlowReflectionRequest,
  LayoutSuperFlowRequest,
} from 'src/types/proto/services/flow_public_service';
import type { DeepPartial } from 'src/types/utils';
import BaseApi from '../base-api';

type ActionParamWithFlowId = DeepPartial<SnakeCasedProperties<Action>> & {
  flowId: Action['flowId'];
};

export default class Flows extends BaseApi {
  getLayout = (action: ActionParamWithFlowId) => {
    return this.post<Layout>(
      `/flows/${encodeURIComponent(action.flowId)}/layout`,
      action
    );
  };

  dispatch = (action: ActionParamWithFlowId) => {
    return this.post<State>(
      `/flows/${encodeURIComponent(action.flowId)}/dispatch`,
      action
    );
  };

  getReflection = (
    flowId: string,
    { vers }: Omit<GetFlowReflectionRequest, 'flowId'>
  ) => {
    return this.get<FlowReflection>(
      `/flows/${encodeURIComponent(flowId)}/reflection`,
      vers
        ? {
            params: {
              vers,
            },
          }
        : undefined
    );
  };

  getSuperFlowLayout = (
    sflowId: string,
    params: LayoutSuperFlowRequest['data']
  ) => {
    return this.post<SuperFlowLayout>(
      `/super_flows/${encodeURIComponent(sflowId)}/layout`,
      params
    );
  };

  closeSuperFlow = (sflowId: string, params: CloseSuperFlowRequest['data']) => {
    return this.post<SuperFlowLayout>(
      `/super_flows/${encodeURIComponent(sflowId)}/close`,
      params
    );
  };
}
