import Field from './base';
import fieldOutput from './field-output';

export default class IntegerBase extends Field {
  static outputs = [fieldOutput('as_string', 'string_format', 'string')];
  minInt = null;

  renderString(value) {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    const n = Number(value);
    if (Number.isNaN(n)) {
      return '';
    }
    if (this.minInt !== null && n < this.minInt) {
      return '';
    }
    return value.toString();
  }
}
