export default class Field {
  constructor(id, params, renderOptions) {
    this.id = id;
    this.params = params;
    this.renderOptions = renderOptions || {};
  }

  renderPdf(value) {
    return this.renderString(value);
  }

  renderString(value) {
    return value && value.trim ? value.trim() : value;
  }

  renderValue(value) {
    return this.renderString(value);
  }
}
