export const IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'gif'];
export const IMAGES_MIME_PREFIX = 'image/';
export const ALL_IMAGES_MIME = `${IMAGES_MIME_PREFIX}*`;
export const ALL_EXTENSIONS = [
  'pdf',
  'doc',
  'docx',
  'txt',
  ...IMAGE_EXTENSIONS,
];

export function extension(filename: string) {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return '';
  }
  return filename.substring(lastDotIndex + 1);
}

export function noExtension(filename: string) {
  const ext = extension(filename);
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1 || !ALL_EXTENSIONS.includes(ext)) {
    return filename;
  }
  return filename.substring(0, lastDotIndex);
}

export function doFilenameEdit(newFilename: string, previous: string) {
  const previousExtension = extension(previous) || 'pdf';
  return `${noExtension(newFilename)}.${previousExtension}`;
}

export const hasValidImageExtension = (filename: string) =>
  IMAGE_EXTENSIONS.includes(extension(filename).toLowerCase());
