import type {
  GetFlowActivitiesRequest,
  SetFlowActivitiesSeenRequest,
} from 'src/types/proto/services/flow_public_service';
import type {
  DeleteActivityRequest,
  GetActivitiesRequest,
  GetTransactionActivitiesRequest,
  GetTransactionActivitySummaryListRequest,
  GetTransactionItemActivitiesRequest,
  SetTransactionActivitiesSeenRequest,
  UpdateActivityRequest,
} from 'src/types/proto/services/transaction_public_service';
import type {
  Activity,
  ActivityList,
  ActivitySummaryList,
} from 'src/types/proto/transactions';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import BaseApi from '../base-api';

export default class Activities extends BaseApi {
  fetchActivity(
    transactionId: string,
    params: Partial<Omit<GetTransactionActivitiesRequest, 'transactionId'>>
  ) {
    return this.get<ActivityList>(
      `${this.getTransUrl(transactionId)}/activities`,
      {
        params,
      }
    );
  }

  fetchItemsActivity(
    transactionId: string,
    params: Partial<Omit<GetTransactionItemActivitiesRequest, 'transactionId'>>
  ) {
    return this.get<ActivityList>(
      `${this.getTransUrl(transactionId)}/activities/items`,
      {
        params: formatToSnakeCase(params),
      }
    );
  }

  fetchActivityByFlow(
    flowId: string,
    params: Omit<GetFlowActivitiesRequest, 'flowId'>
  ) {
    return this.get<ActivityList>(`/flows/${flowId}/activities`, {
      params,
    });
  }

  fetchMultiActivities(ids: GetActivitiesRequest['ids']) {
    return this.post<Activity[]>('/activities/multi', {
      ids,
    });
  }

  updateActivity(activityId: string, params: UpdateActivityRequest['data']) {
    return this.post<Activity>(
      `/activities/${activityId}`,
      formatToSnakeCase(params)
    );
  }

  deleteActivity(activityId: string, params: DeleteActivityRequest['data']) {
    return this.post<Activity>(`/activities/${activityId}/delete`, {
      ...params,
    });
  }

  getTransUrl(transactionId: string) {
    return `/transactions/${encodeURIComponent(transactionId)}`;
  }

  fetchActivitySummary(
    transactionId: string,
    params: Partial<
      Omit<GetTransactionActivitySummaryListRequest, 'transactionId'>
    >
  ) {
    return this.get<ActivitySummaryList>(
      `${this.getTransUrl(transactionId)}/activities/summary`,
      {
        params,
      }
    );
  }

  activitiesSetSeen(
    transactionId: string,
    params: SetTransactionActivitiesSeenRequest['data']
  ) {
    return this.post<Activity[]>(
      `${this.getTransUrl(transactionId)}/activities/set_seen`,
      {
        ...params,
      }
    );
  }

  activitiesSetSeenByFlow(
    flowId: string,
    params: SetFlowActivitiesSeenRequest['data']
  ) {
    return this.post<Activity[]>(`/flows/${flowId}/activities/set_seen`, {
      ...params,
    });
  }
}
