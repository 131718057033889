import Field from './base';
import camelCaseObject from 'src/utils/camel-case-object';
import getFullName from 'src/utils/get-full-name';
import { REQUIRES_ENTITY_NAME_ENTITY_TYPES as EntityTypesWithNames } from 'src/models/auth/entity-types';
import { getLegalName } from 'src/models/auth/contact';

export default class Contact extends Field {
  renderString(value) {
    const format = this.params.format || 'full_name';
    const FullName = (v) => {
      const firstName = v.first_name || '';
      const lastName = v.last_name || '';
      return getFullName({
        firstName,
        lastName,
      });
    };

    const formaters = {
      full_name: FullName,
      legal_name: (v) => {
        const legalNameFormat = this.renderOptions.legalNameFormat;
        return getLegalName(camelCaseObject(v), legalNameFormat);
      },
      entity_name: (v) => {
        return EntityTypesWithNames.includes(v.entity_type)
          ? v.entity_name
          : '';
      },
    };
    return (formaters[format] || FullName)(
      value || {
        first_name: '',
        last_name: '',
        cell_phone: '',
        office_phone: '',
        email: '',
        re_agent: {
          license_number: '',
        },
        entity_name: '',
      }
    );
  }
}
