import AppLoadingModal from 'src/components/common/app-loading-modal';
import PropTypes from 'prop-types';
import QuickForm from './quick-form';
import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
export default class ModalQuickForm extends Component {
  static propTypes = {
    modalProps: PropTypes.object,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    getForm: PropTypes.func,
    initialValue: PropTypes.object,
    getErrors: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.func,
    setFormRef: PropTypes.func,
  };

  setFormRef = (formRef) => {
    this.formRef = formRef;
    if (this.props.setFormRef) {
      this.props.setFormRef(this.formRef);
    }
  };

  submit = () => {
    return this.formRef.submit();
  };

  cancel = () => {
    return this.formRef.cancel();
  };

  render() {
    const {
      modalProps: { footer, ...restModalProps },
      ...quickFormProps
    } = this.props;
    return (
      <AppLoadingModal
        {...restModalProps}
        onCancel={this.cancel}
        onOk={this.submit}
      >
        <QuickForm
          key="quickform__"
          ref={this.setFormRef}
          {...quickFormProps}
          hideControls
        />
        {footer}
      </AppLoadingModal>
    );
  }
}
