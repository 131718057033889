import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

/*
Returns an object given a deeply nested object `obj` with
key/value pairs where the key is a string path to the value
compatible with get() / set().
 */
const flattenObject = (
  obj: Record<string, unknown> = {},
  options: { skipArrays?: boolean; [key: string]: unknown } = {}
) => {
  const result: Record<string, unknown> = {};

  const flatten = (
    collection: unknown[] | Record<string, unknown>,
    prefix = '',
    suffix = ''
  ) => {
    forEach(collection, (value, key) => {
      const path = `${prefix}${key}${suffix}`;

      if (isArray(value) && !options.skipArrays) {
        flatten(value, `${path}[`, ']');
      } else if (isPlainObject(value)) {
        flatten(value as Record<string, unknown>, `${path}.`);
      } else {
        result[path] = value;
      }
    });
  };

  flatten(obj);

  return result;
};

export default flattenObject;
