import PropTypes from 'prop-types';
import QuickForm from './quick-form';
import React, { Component } from 'react';
import invariant from 'invariant';
import { Popover } from 'antd';

export default class PopoverForm extends Component {
  static propTypes = {
    getForm: PropTypes.func,
    children: PropTypes.object,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    initialValue: PropTypes.object,
    placement: PropTypes.string,
    visible: PropTypes.bool,
    backgroundCancel: PropTypes.bool,
    transitionName: PropTypes.string,
    getErrors: PropTypes.func,
    trigger: PropTypes.any,
  };

  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
    placement: 'bottom',
    backgroundCancel: true,
    transitionName: null,
    trigger: 'click',
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      key: 0,
    };
  }

  setFormRef = (formRef) => {
    this.formRef = formRef;
  };

  submit = async () => {
    if (this.formRef) {
      await this.formRef.submit();
    }
  };

  handleVisibleChange = (visible) => {
    if (!visible && this.props.backgroundCancel) {
      this.cancel();
      return;
    }

    this.setState({
      visible: true,
    });
  };

  cancel = () => {
    const { onCancel } = this.props;
    onCancel();

    if (this.formRef) {
      this.formRef.resetValues();
    }

    this.setState({
      visible: false,
    });
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    invariant(
      (nextProps.visible === undefined && this.props.visible === undefined) ||
        (nextProps.visible !== undefined && this.props.visible !== undefined),
      'Changing controlled <-> uncontrolled for this component is not supported.'
    );
  }

  render() {
    const {
      children,
      getForm,
      onSave,
      initialValue,
      placement,
      transitionName,
      getErrors,
      trigger,
      ...quickFormProps
    } = this.props;

    const visible =
      this.props.visible === undefined
        ? this.state.visible
        : this.props.visible;

    return (
      <Popover
        placement={placement}
        content={
          <QuickForm
            {...quickFormProps}
            stopPropagation
            key={`quickform__${this.state.key}`}
            ref={this.setFormRef}
            getForm={({ submit, ...props }) =>
              getForm({
                submit: async (...args) => {
                  this.setState({
                    visible: true,
                  });
                  await submit(...args);
                },
                reopen: () => {
                  this.setState({
                    visible: true,
                    key: this.state.key + 1,
                  });
                },
                ...props,
              })
            }
            getErrors={getErrors}
            initialValue={initialValue}
            onSave={async (formValues, { resetValues }) => {
              await onSave(formValues);
              // A popover shouldn't come back with the same data
              // after a save.
              resetValues();
              this.setState({
                visible: false,
                key: this.state.key + 1,
              });
            }}
            onCancel={this.cancel}
          />
        }
        trigger={trigger}
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
        transitionName={transitionName}
      >
        {children}
      </Popover>
    );
  }
}
