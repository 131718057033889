import AppButton from 'src/components/common/app-button';
import AppModal from 'src/components/common/app-modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('ui')
@observer
export default class AppReleaseModal extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
  };

  handleCancel = () => {
    const { ui } = this.props;
    ui.clearNewReleaseModal();
  };

  refreshPage = () => {
    window.location.reload(true);
  };

  render() {
    const { ui } = this.props;
    const { hasNewRelease } = ui;
    if (!hasNewRelease) {
      return <div />;
    }
    return (
      <AppModal
        title="Glide needs to be reloaded"
        closable
        visible={hasNewRelease}
        width={650}
        onCancel={this.handleCancel}
        footer={
          <div>
            <AppButton onClick={this.handleCancel}>Cancel</AppButton>
            <AppButton type="primary" onClick={this.refreshPage}>
              Reload
            </AppButton>
          </div>
        }
      >
        <div>
          <p>
            Before you reload, please consider copying any text that you might
            have been working on.
          </p>
        </div>
      </AppModal>
    );
  }
}
