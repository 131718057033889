import AnchorButton from './anchor-button';
import AppButton from './app-button';
import React from 'react';
import bindLoading from './bind-loading';

export default bindLoading({
  loading: 'onClick',
})(({ loading, forceLoading, anchor, ...otherProps }) => {
  const Comp = anchor ? AnchorButton : AppButton;
  return <Comp loading={loading || forceLoading} {...otherProps} />;
});
