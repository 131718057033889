import OutputHandler from './base';

export default class Negate extends OutputHandler {
  negateValue(v) {
    if (v === undefined) {
      return undefined;
    }
    return !v;
  }

  getIdentityFieldName(_outctx) {
    return 'value';
  }

  getValue(value) {
    return this.negateValue(value);
  }

  pdfValue(outctx) {
    const val = outctx.get(this.getIdentityFieldName(outctx));
    return this.negateValue(val);
  }
}
