const PASSWORD_MIN_LENGTH = 8;

const USER_RE =
  /(^[-!#$%&'*+/=?^_`{}|~0-9A-ZÀ-ÖØ-öø-ÿ]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-ZÀ-ÖØ-öø-ÿ]+)*$|^"([\001-\010\013\014\016-\037!#-[\]-\177]|\\[\001-\011\013\014\016-\177])*"$)/i;

const DOMAIN_RE =
  /(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?$)|^\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/i;

const EMAIL_ILLEGAL_CHARS_RE = /^[^*,!'"#$%&/() ¿?¡~{}^`\\<>;:¡|¬°=[\]]*$/i;

function rsplit(s, sep, maxsplit = 1) {
  const split = s.split(sep);
  return maxsplit
    ? [split.slice(0, -maxsplit).join(sep)].concat(split.slice(-maxsplit))
    : split;
}

export function isEmail(_value) {
  const value = (_value || '').trim();
  if (!value) {
    return false;
  }
  if (value.indexOf('@') === -1) {
    return false;
  }

  if (!EMAIL_ILLEGAL_CHARS_RE.test(value)) {
    return false;
  }

  const [userPart, domainPart] = rsplit(value, '@', 1);
  if (!(USER_RE.test(userPart) && DOMAIN_RE.test(domainPart))) {
    return false;
  }
  return true;
}

export function emailRule(options = {}) {
  // eslint-disable-line
  return {
    message: 'Invalid email address.',
    validator(rule, value, callback) {
      const errors = [];
      if (!value) {
        callback([]);
        return;
      }

      if (!isEmail(value)) {
        errors.push(new Error('Invalid email address.'));
        callback(errors);
        return;
      }

      callback(errors);
    },
    ...options,
  };
}

export const passwordRule = {
  required: true,
  min: PASSWORD_MIN_LENGTH,
  message: `Password must be ${PASSWORD_MIN_LENGTH} characters or longer.`,
};

export const requiredRule = (message = '*required') => ({
  required: true,
  message,
});

export const simpleAddressRule = () => {
  return {
    validator(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }

      const trimmed = value.trim();
      const lines = (trimmed.match(/\r?\n/g) || '').length + 1;
      if (lines > 3) {
        callback(new Error('Cannot exceed three lines.'));
        return;
      }
      if (value.length > 255) {
        callback(new Error('Max 255 characters.'));
        return;
      }
      callback();
    },
  };
};

const URL_RE =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
export function isUrl(value) {
  const cleanValue = value?.trim();
  return !cleanValue || URL_RE.test(cleanValue);
}
