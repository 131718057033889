import type { EmbeddedFeature } from 'src/stores/embedded-app-store';

export const productPathFromEmbeddedFeature = (
  embeddedFeature: EmbeddedFeature | null | undefined
) => {
  if (!embeddedFeature) {
    return 'Unknown';
  }
  if (embeddedFeature === 'offers' || embeddedFeature === 'offers-sellside') {
    return 'Offers';
  }
  if (embeddedFeature === 'templates-and-clauses') {
    return 'Templates and Clauses';
  }
  return 'Files';
};
