import Field from './base';
import fieldOutput from './field-output';
import formatTimestamp from 'src/utils/format-timestamp';

export default class Timestamp extends Field {
  static outputs = [fieldOutput('as_string', 'string_format', 'string')];

  renderString(value) {
    const format = this.params.format || 'date';
    if (!value && value.ts) {
      return '';
    }
    const formaters = {
      date: (v) => {
        const { ts, timezone } = v;
        return formatTimestamp(ts, 'MM/DD/YYYY', timezone || 'US/Pacific');
      },
    };
    return (formaters[format] || ((v) => v || ''))(value);
  }
}
