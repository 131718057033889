import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
import { LRUMap } from 'lru_map';
import { toJS } from 'mobx';
import NProgress from 'nprogress';
import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';
import loggerPlugin from 'router5-plugin-logger';
import transitionPath from 'router5-transition-path';
import analytics from 'src/analytics';
import { iosAllowedRoutes } from 'src/entries/app/constants';
import logger from 'src/logger';
import { loadTxnDetailsModel } from 'src/models/transactions/transaction';
import handleChunkLoadFailure from 'src/utils/chunk-load-handler';
import { sendMessage as sendMobileBridgeMessage } from 'src/utils/mobile-bridge';
import { getScrollableContainer, isScrollable } from 'src/utils/scroll-to';

async function blockUntilStoreIsInitialized(store) {
  return store?.isInitialized ? store.isInitialized() : Promise.resolve(true);
}

export function namePaths(name) {
  // Returns an array of paths leading up to the name.
  // namePaths("a.route.like.this")
  // > ["a", "a.route", "a.route.like", "a.route.like.this"]
  const res = [];
  const parts = name.split('.');
  const curr = [];
  for (let i = 0; i < parts.length; i++) {
    curr.push(parts[i]);
    res.push(curr.join('.'));
  }
  return res;
}

export function buildNameMap(routes, fn) {
  // Returns a map of fn(route) by route name.

  function build(route, ancestors = []) {
    const name = [...ancestors, route.name].join('.');
    const map = {
      [name]: fn(route),
    };
    if (!route.children || !route.children.length) {
      return map;
    }

    const nextAncestors = [...ancestors, route.name];
    return route.children
      .map((child) => build(child, nextAncestors))
      .reduce((acc, val) => Object.assign(acc, val), map);
  }
  return routes
    .map((route) => build(route))
    .reduce((acc, val) => Object.assign(acc, val), {});
}

const withErrorHandling =
  (createMiddlewareFunc) =>
  (store, routes, ...otherCreateMiddlewareArgs) => {
    const middleware = createMiddlewareFunc(
      store,
      routes,
      ...otherCreateMiddlewareArgs
    );
    return (_router) => {
      const middlewareFunc = middleware(_router);
      return async (toState, fromState, ...otherMiddlewareArgs) => {
        const middlewareType = createMiddlewareFunc.name;
        try {
          return await middlewareFunc(
            toState,
            fromState,
            ...otherMiddlewareArgs
          );
        } catch (err) {
          // Unhandled routing error ocurred
          const errorData = {
            middlewareType,
            message: err.toString(),
            stack: err.stack,
            toState,
            fromState,
          };
          console.error(errorData);
          window.DD_RUM?.addError(err, errorData);
          store.ui.wentWrongFull(err);
          return null;
        }
      };
    };
  };

/*
This wrapper is designed to wrap `createPreloadComponentMiddleware` specifically.
This logic has to run after preloadComponent is finished and it's done this way
instead of creating a standlone middleware to: 1) not require that the individual
middleware to be placed after `createPreloadComponentMiddleware`
(which might be changed accidentally later on), and 2) to clearly separate the logic
*/
function withEmbeddedContentReady(createMiddlewareFunc) {
  return (store, routes, ...otherCreateMiddlewareArgs) => {
    const middleware = createMiddlewareFunc(
      store,
      routes,
      ...otherCreateMiddlewareArgs
    );
    return (_router) => {
      const middlewareFunc = middleware(_router);
      return async (toState, fromState, ...otherMiddlewareArgs) => {
        const res = await middlewareFunc(
          toState,
          fromState,
          ...otherMiddlewareArgs
        );

        // Inidicates to parent app in compass-app-bridge that the rendered content is ready
        // to be used (as this logic runs after the model data was fetched to render the route's
        // entry component)
        store?.embeddedApp?.fireContentReady();

        return res;
      };
    };
  };
}

export function createPreloadComponentMiddleware(store, routes) {
  const nameComponentMap = buildNameMap(routes, (route) => route.component);
  const nameIndexComponentMap = buildNameMap(
    routes,
    (route) => route.indexComponent
  );
  return (_router) => (toState, fromState) => {
    let loadedObserver;
    logger.log('route', toState, fromState);

    function sendMobileTransitionSuccessNotification() {
      // fullscreenmode is passed just for flow pages so the
      // native app can display flows on fullscreen
      const fullScreenMode = toState && toState.name === 'flow.page';
      sendMobileBridgeMessage({
        name: 'mobileapp',
        data: {
          event: 'onTransitionSuccess',
          toState,
          fromState,
          fullScreenMode,
        },
        dispose: false,
      });
    }

    const { toActivate } = transitionPath(toState, fromState);
    if (!toActivate.includes(toState.name)) {
      toActivate.push(toState.name);
    }

    logger.log('activating', toActivate);
    const componentFns = toActivate.map((name) => ({
      name,
      componentFn: nameComponentMap[name],
    }));
    componentFns.push({
      name: toState.name,
      componentFn: nameIndexComponentMap[toState.name],
    });
    return Promise.all(
      componentFns
        .filter(({ componentFn }) => !!componentFn)
        .map(({ name, componentFn }) => {
          const p = componentFn();
          if (!p.then) {
            // Not a lazy component.
            return Promise.resolve({
              name,
              module: p,
            });
          }
          return p.then((module) => ({
            name,
            module,
          }));
        })
    )
      .then(
        (modules) => {
          const components = modules.map(({ name, module }) => ({
            name,
            component: module.default ? module.default : module,
          }));

          logger.log('Components imported', components);

          const modelsMap = {};
          const componentsWithoutModels = components.map(
            ({ component }) => component
          );
          const componentsHavingModel = componentsWithoutModels.filter(
            (component) => typeof component.model === 'function'
          );

          const observeLoadedRoute = !!(
            store.ui.isGlideMobileApp && componentsHavingModel.length > 0
          );

          if (observeLoadedRoute) {
            /*
              Loaded observer: with model components add a dom object we
              watch for to delay the mobile transition success event as much
              as possible.

              According to Mozilla web api docs there's no need to worry
              about removing the observer since it will be when the dom
              element is:

              If the element being observed is removed from the DOM, and then
              subsequently released by the browser's garbage collection mechanism,
              the MutationObserver is likewise deleted.

              See components/common/with-model for the counterpart of this technique.
            */
            loadedObserver = new MutationObserver(() => {
              // TODO: get id from LoadedRoute
              const loaderId = `loaded-route-${toState.name}`;
              const el = document.getElementById(loaderId);
              if (el) {
                const rect = el.getBoundingClientRect();
                // NOTE: not sure if isVisible is really needed, added it just in case
                const isVisible =
                  rect.top >= 0 &&
                  rect.left >= 0 &&
                  rect.bottom <=
                    (window.innerHeight ||
                      document.documentElement.clientHeight) &&
                  rect.right <=
                    (window.innerWidth || document.documentElement.clientWidth);
                if (isVisible) {
                  setTimeout(
                    () => sendMobileTransitionSuccessNotification(),
                    0
                  );
                  loadedObserver.disconnect();
                }
              }
            });
            loadedObserver.observe(document, {
              attributes: false,
              childList: true,
              characterData: false,
              subtree: true,
            });
          }

          return blockUntilStoreIsInitialized(store).then(() =>
            componentsHavingModel
              .reduce((acc, component) => {
                return acc.then((models) =>
                  component.model(store, toState, fromState).then((model) => {
                    return {
                      ...models,
                      [component.withModelId]: model,
                    };
                  })
                );
              }, Promise.resolve(modelsMap))
              .then((modelsById) => {
                store.router.updateModelsById(modelsById);
              })
              .then(() => {
                return toState;
              })
              .catch((err) => {
                store.ui.wentWrongFull(err);
                throw err;
              })
          );
        },
        (err) => {
          if (!handleChunkLoadFailure(err)) {
            throw err;
          }
        }
      )
      .then(() => {
        setTimeout(() => {
          if (!loadedObserver) {
            sendMobileTransitionSuccessNotification();
          }
        }, 0);
      });
  };
}

const getRequiredTxnFieldsRedirect = (requiredTxnFieldsOutputIds) => {
  if (!requiredTxnFieldsOutputIds?.length) {
    return null;
  }

  return async (store, toState) => {
    const {
      params: { transactionId },
    } = toState;
    const { boundForm } = await loadTxnDetailsModel(store, {
      params: {
        transactionId,
      },
    });

    const boundOutputs = requiredTxnFieldsOutputIds.map((oid) =>
      boundForm.getBoundOutput(oid)
    );
    const missingOutputIds = boundOutputs
      .filter((boundOutput) => {
        // TODO support other values
        const value = toJS(boundOutput.getFieldValue('this'));
        return !value || isEmpty(value) || value.length === 0;
      })
      .map((o) => o.id);
    if (missingOutputIds.length) {
      return {
        ...toState,
        name: 'transactions.transaction',
        params: {
          transactionId,
          showRequiredFieldsModal: JSON.stringify({
            required: requiredTxnFieldsOutputIds,
            missing: missingOutputIds,
            redirect: toState,
          }),
        },
      };
    }

    return null;
  };
};

export function createTxnRequiredFieldsRedirectMiddleware(store, routes) {
  const nameRedirectMap = buildNameMap(
    routes,
    (route) => route.requiredTxnFields
  );
  return () => (toState) => {
    const requiredTxnFields = nameRedirectMap[toState.name];
    const reqTxnFieldsRedirect =
      getRequiredTxnFieldsRedirect(requiredTxnFields);
    if (!reqTxnFieldsRedirect || store.embeddedApp?.isEmbedded) {
      return Promise.resolve(toState);
    }
    return Promise.resolve(reqTxnFieldsRedirect(store, toState));
  };
}

export function createRedirectMiddleware(store, routes) {
  const nameRedirectMap = buildNameMap(routes, (route) => route.redirect);
  return () => (toState) => {
    const redirect = nameRedirectMap[toState.name];
    if (!redirect) {
      return Promise.resolve(toState);
    }
    const redirectPromise = async () => {
      // Ensure app-store is finished initializing before
      // evaluating any redirect handler, which might
      // include fetches to server that require session
      // to have been established
      await blockUntilStoreIsInitialized(store);
      const res = await redirect(store, toState);
      return res;
    };
    return Promise.resolve(redirectPromise());
  };
}

export function createPreventUnknownRouteIosMiddleware(store) {
  return () => (toState) => {
    if (!store.ui.isGlideMobileApp) {
      return Promise.resolve(toState);
    }
    if (toState.name in iosAllowedRoutes) {
      return Promise.resolve(toState);
    }
    if (toState.name === 'login') {
      // do not fail when redirecting to login, even when it's not an allowed route.
      // this would break further app navigation from ios, but at this point
      // (after a logout) the webview is useless and will involve hard reset from ios app
      return Promise.resolve(toState);
    }
    const errorMsg = `Route not supported: ${toState.name} [path=${toState.path}]`;
    return Promise.reject(new Error(errorMsg));
  };
}

// Control routing on Glide when ebmedded
function createEmbeddedMiddleware(store) {
  return () => (toState) => {
    if (!store.embeddedApp?.isEmbedded) {
      return Promise.resolve(toState);
    }

    const { newState, preventNavigation, errorMsg } =
      store.embeddedApp.embeddedFeatureRoute(toState);

    if (errorMsg) {
      const err = new Error(errorMsg);
      window.DD_RUM?.addError(err);
      console.error(err);
      // return Promise.reject(new Error(errorMsg));
    } else if (preventNavigation) {
      return Promise.reject();
    }

    return Promise.resolve(newState);
  };
}

export function createRequireFeaturesMiddleware(store, routes) {
  const nameFeaturesMap = buildNameMap(
    routes,
    (route) => route.requireFeatures
  );

  function hasRequiredFeatures(featureStore, features) {
    for (let i = 0; i < features.length; i++) {
      if (!featureStore.isActive(features[i])) {
        return false;
      }
    }
    return true;
  }

  return () => (toState, fromState) => {
    const { toActivate } = transitionPath(toState, fromState);

    for (let i = 0; i < toActivate.length; i++) {
      const requireFeatures = nameFeaturesMap[toState.name];
      if (
        requireFeatures &&
        !hasRequiredFeatures(store.features, requireFeatures)
      ) {
        return Promise.reject(new Error('Missing required features.'));
      }
    }

    return Promise.resolve(toState);
  };
}

class ProgressHelper {
  constructor(delay) {
    this.delay = delay;
    this.timer = null;
  }

  startMiddleware() {
    return () => (toState, fromState, done) => {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        NProgress.start();
      }, this.delay);
      done();
    };
  }

  stopMiddleware() {
    return () => (toState, fromState, done) => {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      NProgress.done();
      done();
    };
  }
}

export function createMiddlewares({ store, routes }) {
  const progressHelper = new ProgressHelper(150);
  return [
    withErrorHandling(createRequireFeaturesMiddleware)(store, routes),
    withErrorHandling(createPreventUnknownRouteIosMiddleware)(store),
    withErrorHandling(createEmbeddedMiddleware)(store),
    withErrorHandling(createTxnRequiredFieldsRedirectMiddleware)(store, routes),
    withErrorHandling(createRedirectMiddleware)(store, routes),
    progressHelper.startMiddleware(),
    () => (toState, fromState, done) => {
      if (window.postMessage) {
        setTimeout(() => {
          try {
            window.postMessage({
              event: 'routeStateChange',
              route: toState.name,
            });
          } catch (e) {
            //
          }
        });
      }
      done();
    },
    withErrorHandling(
      withEmbeddedContentReady(createPreloadComponentMiddleware)
    )(store, routes),
    progressHelper.stopMiddleware(),
  ];
}

export function createStoreListener(store, routes) {
  const scrollStateMap = new LRUMap(64);

  const nameScrollToTopMap = buildNameMap(routes, (route) =>
    route.scrollToTop === undefined ? true : route.scrollToTop
  );

  let restoreScrollTimeout;

  function saveFromStateScrollPosition(fromState, getScrollElement) {
    if (fromState && fromState.path) {
      const element = getScrollElement();
      scrollStateMap.set(fromState.path, element.scrollTop);
    }
  }

  function restoreScrollPosition(toState, fromState, getScrollElement) {
    const shouldScrollToTop =
      fromState &&
      toState.path !== fromState.path &&
      !toState?.meta.options?.replace &&
      nameScrollToTopMap[toState.name] &&
      nameScrollToTopMap[fromState.name];

    const fromElement = getScrollElement();
    if (shouldScrollToTop) {
      fromElement.scrollTop = 0;
    }
    if (restoreScrollTimeout) {
      clearTimeout(restoreScrollTimeout);
    }
    restoreScrollTimeout = setTimeout(() => {
      const element = getScrollElement();
      if (
        toState?.meta?.source === 'popstate' &&
        scrollStateMap.has(toState.path)
      ) {
        const scrollTop = scrollStateMap.get(toState.path);
        element.scrollTop = Math.min(scrollTop, element.scrollHeight);
      }

      if (!scrollStateMap.has(toState.path)) {
        scrollStateMap.set(toState.path, element.scrollTop);
      }
    }, 0);
  }

  return function storeListener(toState, fromState) {
    const { router } = store;

    function getScrollElement() {
      let targetScrollElement;
      if (!router.scrollElement) {
        targetScrollElement = document.documentElement;
      } else if (typeof router.scrollElement === 'string') {
        targetScrollElement = document.getElementById(router.scrollElement);

        if (!targetScrollElement) {
          return document.documentElement;
        }
      } else {
        targetScrollElement = router.scrollElement;
      }

      if (
        targetScrollElement === document.documentElement ||
        isScrollable(targetScrollElement)
      ) {
        return targetScrollElement;
      }
      return getScrollableContainer(targetScrollElement);
    }

    saveFromStateScrollPosition(fromState, getScrollElement);
    router.updateRoute(toState, fromState);
    restoreScrollPosition(toState, fromState, getScrollElement);

    if (toState && toState.name !== 'flow.page') {
      analytics().page(toState.name, {
        ...(toState.params
          ? mapKeys(toState.params, (_unusedValue, k) => `_${k}`)
          : null),
        fromRoute: fromState && fromState.name,
      });
    }
  };
}

const makeGlideMobileAppPlugin = (store) => () => ({
  onTransitionStart: (toState, fromState) => {
    // didNavigateToUnknownRoute needs to be sent instead of
    // onTransitionStart, not independently of it
    if (store.ui.isGlideMobileApp && !(toState.name in iosAllowedRoutes)) {
      sendMobileBridgeMessage({
        data: {
          event: 'didNavigateToUnknownRoute',
          toState,
        },
        dispose: false,
      });
      return;
    }
    sendMobileBridgeMessage({
      data: {
        event: 'onTransitionStart',
        toState,
        fromState,
      },
      dispose: false,
    });
  },
  onTransitionError: (toState, fromState, error) => {
    if (error && error.code !== 'SAME_STATES') {
      sendMobileBridgeMessage({
        data: {
          event: 'onTransitionError',
          toState,
          fromState,
        },
        dispose: false,
      });
    }
  },
  onTransitionCancel: (toState, fromState) => {
    sendMobileBridgeMessage({
      data: {
        event: 'onTransitionCancel',
        toState,
        fromState,
      },
      dispose: false,
    });
  },
});

const makeInterceptRedirectPlugin =
  ({ router }) =>
  () => ({
    onTransitionSuccess: () => {
      const interceptRedirectRoute = router.interceptRedirectRoute;
      if (interceptRedirectRoute) {
        router.clearInterceptRedirectRoute();
        router.navigate(
          interceptRedirectRoute.name,
          interceptRedirectRoute.params || {}
        );
      }
    },
  });

// urls should use dashes "-" NOT underscores "_"!

function removePreloadSpin() {
  /* Preload spin (see templates/spin.html) will be hidden anyways since it
   will be overlayed by other elements but we don't want to leave
  junk around in the DOM. */
  const preloadSpin = document.getElementById('preload-spin');
  if (preloadSpin) {
    preloadSpin.remove();
  }
}

export function configureRouter(store, routes, options) {
  const router = createRouter(routes, {
    trailingSlash: true,
    queryParamsMode: 'loose',
    ...options,
  });
  router.usePlugin(
    // TODO: do not use glideMobileAppPlugin if not isGlideMobileApp
    makeGlideMobileAppPlugin(store),
    makeInterceptRedirectPlugin(store),
    loggerPlugin,
    browserPlugin(),
    listenersPlugin()
  );
  router.addListener(createStoreListener(store, routes));
  router.addListener(removePreloadSpin);
  router.useMiddleware(
    ...createMiddlewares({
      store,
      routes,
    })
  );

  /** Set the root route to the micro app's base path. */
  if (window.__MICRO_APP_BASE_ROUTE__) {
    router.setRootPath(window.__MICRO_APP_BASE_ROUTE__);
  }

  store.router.router = router;

  return router;
}
