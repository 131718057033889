import type {
  AddVendorRequest,
  DeleteVendorRequest,
  GetVendorsResponse,
  MakeVendorPrimaryRequest,
  SearchVendorResponse,
} from 'src/types/proto/services/vendor_public_service';
import type { ApiResponseData } from 'src/types/utils';
import BaseApi from '../base-api';

export default class Vendors extends BaseApi {
  search(query: string) {
    return this.get<SearchVendorResponse>(
      `/vendors/search?query=${encodeURIComponent(query)}`
    );
  }
  addVendor(code: string, isPrimaryVendor: boolean) {
    return this.post<void>('/vendors/add', {
      code,
      isPrimaryVendor,
    } as AddVendorRequest);
  }
  list() {
    return this.get<ApiResponseData<GetVendorsResponse>>('/vendors/list');
  }
  makePrimary(code: string) {
    return this.post<void>('/vendors/make_primary', {
      code,
    } as MakeVendorPrimaryRequest);
  }
  remove(code: string) {
    return this.post<void>('/vendors/remove', {
      code,
    } as DeleteVendorRequest);
  }
}
