import { AxiosError } from 'axios';

export default (statuses?: number[], path?: string) =>
  (f: (...args: unknown[]) => void) => {
    return async (...args: unknown[]) => {
      try {
        return await f(...args);
      } catch (err) {
        if (
          (err as AxiosError).response &&
          (statuses || [404]).includes((err as AxiosError).response!.status)
        ) {
          window.location.replace(path || '/auth/login');
          await new Promise(() => null);
        } else {
          throw err;
        }
        return null; // FIXME: it seems a unreachable branch
      }
    };
  };
