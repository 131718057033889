import { action, computed, makeObservable, observable } from 'mobx';
import Model from 'src/models/base';
import type BoundForm from 'src/models/fields/bound-form';
import type PDFAnnotationsStore from 'src/stores/pdf-annotations-store';
import type { FillConfig } from 'src/types/proto/documents';
import type { FormConfig as FormConfigType } from 'src/types/proto/reform';
import type Item from './item';
import Section from './section';

interface FormConfigOptions {
  fillConfig?: FillConfig;
  overrides?: {
    permissions?: string[];
  };
  boundForm?: BoundForm;
}

export default class FormConfig extends Model<FormConfigType> {
  id: string;
  options: FormConfigOptions;
  store: PDFAnnotationsStore;
  @observable searchText = '';
  @observable hideEmptySections = false;

  constructor(
    store: any,
    json: FormConfigType,
    options: FormConfigOptions = {}
  ) {
    super(json);
    makeObservable(this);
    this.id = `${Math.round(Math.random() * 10000)}`;
    this.options = options;
    this.store = store.parent?.annotations ?? store.annotations;
  }

  get boundForm() {
    return this.options.boundForm ?? this.store?.boundForm ?? undefined;
  }

  get fillConfig() {
    return this.options.fillConfig;
  }

  get overrides() {
    return this.options.overrides ?? {};
  }

  @computed
  get formId() {
    return this.data.formId;
  }

  @computed
  get sections() {
    return (this.data.items || []).map(
      (item, idx) => new Section(this, item, `${this.id}-${idx + 1}`)
    );
  }

  @computed
  get sectionsById(): Record<string, Section> {
    return this.sections.reduce(
      (allSections, section) => ({
        ...allSections,
        [section.id]: section,
      }),
      {}
    );
  }

  @computed
  get itemsById(): Record<string, Item<any>> {
    return this.flattenedItems.reduce(
      (allItems, item) => ({
        ...allItems,
        [item.id]: item,
      }),
      {}
    );
  }

  @computed
  get itemsByFieldId(): Record<string, Item<any>> {
    return this.flattenedItems
      .filter((item) => item.fieldIds.length > 0)
      .reduce(
        (allItems, item) => ({
          ...allItems,
          ...item.fieldIds.reduce(
            (itemFields, fieldId) => ({
              ...itemFields,
              [fieldId]: item,
            }),
            {}
          ),
        }),
        {}
      );
  }

  @computed
  get itemsByFlowKey(): Record<string, Item<any>> {
    return this.flattenedItems
      .filter((item) => !!item.flowKey)
      .reduce(
        (allItems, item) => ({
          ...allItems,
          [item.flowKey]: item,
        }),
        {}
      );
  }

  @computed
  get allFieldIds() {
    return this.flattenedItems.map((item) => item.fieldIds).flat();
  }

  @computed
  get isAvailable() {
    return (this.data?.items?.length ?? 0) > 0;
  }

  @computed
  get flattenedTerms() {
    return this.sections.map((section) => section.terms).flat();
  }

  @computed
  get flattenedItems() {
    return this.flattenedTerms.map((term) => term.items).flat();
  }

  @computed
  get visibleSections() {
    return this.sections.filter((s) => s.isVisible);
  }

  @computed
  get isVisible() {
    return this.sections.some((s) => s.isVisible);
  }

  @computed
  get filteredSections() {
    return this.visibleSections.filter((s) => s.matchesSearch);
  }

  @computed
  get showAllSections() {
    return !this.hideEmptySections && this.searchText.length === 0;
  }

  @action
  setSearchText = (searchText: string) => {
    this.searchText = searchText;
  };

  @action
  setHideEmptySections = (hideEmptySections: boolean) => {
    this.hideEmptySections = hideEmptySections;
  };

  clearHighlight() {
    return this.store.clearAllHighlighted();
  }
}
