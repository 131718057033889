
declare global {
    interface Window {
        heap?: any;
    }
}

type NestedParams = Record<string, any>

type Primitives = number | string | boolean | null


const flatten = (params: NestedParams | null) => {
    /* 
        Heap does not support nested parameters like Segment.
        so we adjust payloads like:
        {
            'city': 'Bikini Bottom',
            'character': {
                'age': 36,
                'name': 'Spongebob'
            }
        }

        {
            'city': 'Bikini Bottom',
            'character.age': 36,
            'character.name`: 'Spongebob'
        }
    */

    let result: Record<string, Primitives> = {};

    if (!params) {
        return result;
    }

    Object.keys(params).forEach(key => {
        if (params[key] == null) {
            return;
        } else if (['number', 'string', 'boolean'].includes(typeof params[key])) {
            result[key] = params[key];
        } else if (Array.isArray(params[key])) {
            result[key] = JSON.stringify(params[key]);
        } else {
            const flattened = flatten(params[key]);
            Object.keys(flattened).forEach(lastKey => 
                result[`${key}.${lastKey}`] = flattened[lastKey]
            );
        }
    })

    return result;
}

export const heapAdapter = {
    ...window.heap,
    track: (event: string, options: NestedParams) => {
        return window.heap.track(event, flatten(options));
    },
    identify: async (identifier: string, data: Record<string, string>, options: NestedParams) => {
        const payload = {
            ...data,
            ...flatten(options),
        }

        await window.heap.identify(identifier);
        return await window.heap.addUserProperties(payload);
    },
    page: (pageName: string, options: NestedParams) => {
        return window.heap.track(`Viewed ${pageName} Page`, flatten(options));
    },
}

export const getAdapter = () => window.heap ? heapAdapter : {
    identify: (_name: string, _data: any, _options: any) => {},
    track: () => {},
    page: () => {},
    user: () => null,
}
