import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import { computed, makeObservable } from 'mobx';
import type BoundForm from 'src/models/fields/bound-form';
import type PDFAnnotationsStore from 'src/stores/pdf-annotations-store';
import type FormConfig from '..';
import type Item from '../item';

export default class BaseItemDelegate<Params = any> {
  item: Item<Params, BaseItemDelegate<Params>>;
  store: PDFAnnotationsStore;
  formConfig: FormConfig;
  boundForm?: BoundForm;

  constructor(item: Item<Params, BaseItemDelegate<Params>>) {
    makeObservable(this);
    this.item = item;
    this.store = item.store;
    this.formConfig = item.formConfig;
    this.boundForm = item.boundForm;
  }

  @computed
  get fieldId() {
    return this.item.fieldId;
  }

  @computed
  get fieldIds() {
    return this.fieldId ? [this.fieldId] : [];
  }

  @computed
  get flowKey() {
    return this.fieldId;
  }

  @computed
  get question() {
    return '';
  }

  @computed
  get boundOutput() {
    return this.item.boundOutput;
  }

  @computed
  get topLinkedOutput() {
    return this.item.topLinkedOutput;
  }

  @computed
  get params(): Params {
    return this.item.data[camelCase(this.item.kind)];
  }

  @computed
  get mainFieldKey() {
    return Object.keys(this.topLinkedOutput?.bindings || {})[0];
  }

  @computed
  get field() {
    if (this.mainFieldKey) {
      return this.topLinkedOutput?.getField(this.mainFieldKey);
    }
    return undefined;
  }

  @computed
  get value() {
    if (this.mainFieldKey) {
      return this.topLinkedOutput?.getFieldValue(this.mainFieldKey);
    }
    return undefined;
  }

  @computed
  get pdfValue() {
    return this.boundOutput?.pdfValue || '';
  }

  @computed
  get widgetValue() {
    return this.toWidgetValue()[0];
  }

  @computed
  get isNa() {
    return this.boundOutput?.isNa;
  }

  @computed
  get isVisible() {
    return !this.isNa && !this.isReadOnly;
  }

  @computed
  get optional() {
    return !!this.field?.optional;
  }

  @computed
  get isReadOnly() {
    return (
      this.field &&
      this.field.canSetValue &&
      !this.field.canSetValue(
        this.formConfig.overrides.permissions || this.store.permissions
      )
    );
  }

  toBoundFieldValue<T>(val: T) {
    let fieldValue: T;
    if (val && typeof val === 'object') {
      fieldValue = {
        ...(this.value || {}),
        ...val,
      };
    } else {
      fieldValue = val;
    }
    return [fieldValue, { [this.fieldId]: fieldValue }];
  }

  toWidgetValue(val = this.value) {
    return [val, { [this.fieldId]: val }];
  }

  setValue(val: any) {
    const [fieldValue] = this.toBoundFieldValue(val);
    this.topLinkedOutput?.setFields({
      [this.mainFieldKey]: fieldValue,
    });
  }

  isValueEqual(val: any) {
    return isEqual(this.value, val);
  }
}
