import React, { Component, createRef, ComponentClass, RefObject } from 'react';
import { Channel } from 'pusher-js';
import { inject, observer } from 'mobx-react';
import pusher from 'src/utils/pusher';

type UserChannelWrappedComponent = ComponentClass & {
  pusherEventsHandle: (eventName: string, ...args: any[]) => void;
};

type PusherEventsProps = {
  account: {
    user?: {
      uuid: string;
    };
  };
};

type PusherEventHandle = (...params: any[]) => void;

export function withPusherEvents<T extends UserChannelWrappedComponent>(
  ...eventNames: string[]
) {
  return (WrappedComponent: T) => {
    const WithPusherEvents = React.forwardRef<PusherEventsProps, T>(
      (props, ref) => {
        @inject('account')
        class WithPusherEventsComponent extends Component {
          pusherEventsChannel: Channel;
          componentRef: RefObject<T>;
          eventNames = eventNames;
          props: PusherEventsProps;
          bindHandles: {
            eventName: string;
            handle: PusherEventHandle;
          }[] = [];

          constructor(props: PusherEventsProps) {
            super(props);
            this.props = props;

            this.componentRef = ref || createRef<T>();
            this.pusherEventsChannel = pusher.subscribe(
              String(props.account.user?.uuid)
            );
          }

          componentDidMount() {
            this.eventNames.forEach((eventName) => {
              const handle: PusherEventHandle = (...params) => {
                const componentHandle =
                  this.componentRef.current?.pusherEventsHandle;
                if (componentHandle) {
                  componentHandle(eventName, ...params);
                }
              };
              this.pusherEventsChannel?.bind(eventName, handle);

              this.bindHandles.push({ eventName, handle });
            });
          }

          componentWillUnmount() {
            this.bindHandles.forEach(({ eventName, handle }) => {
              this.pusherEventsChannel?.unbind(eventName, handle);
            });
          }

          render() {
            return <WrappedComponent ref={this.componentRef} {...this.props} />;
          }
        }

        return <WithPusherEventsComponent {...props} />;
      }
    );

    return WithPusherEvents;
  };
}
