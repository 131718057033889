import type { Association } from 'src/types/proto/brokers';
import type {
  GenerateUsageReportAssociationsRequest,
  SetBrokersAssociationsMembershipsResponse,
} from 'src/types/proto/services/broker_admin_service';
import type { ApiRequestBody, ApiResponseData } from 'src/types/utils';
import PublicBrokers from '../public/brokers';

export default class Brokers extends PublicBrokers {
  associationsCreate(data: Association) {
    return this.post<Association>('/brokers/associations', data);
  }

  associationsUpdate(associationId: string, data: Association) {
    return this.post<Association>(
      `/brokers/associations/${associationId}`,
      data
    );
  }

  associationsRemove(associationId: string) {
    return this.post<void>('/brokers/associations/remove', {
      associationId,
    });
  }

  associationMembershipsSet(userId: string, associationIds: string[]) {
    return this.post<
      ApiResponseData<SetBrokersAssociationsMembershipsResponse>
    >('/brokers/association_memberships/set/', {
      userId,
      associationIds,
    });
  }

  generateAssociationsUsageReport(
    data: ApiRequestBody<GenerateUsageReportAssociationsRequest>
  ) {
    return this.post<void>(`/brokers/associations/usage`, data);
  }
}
