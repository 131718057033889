import isEmpty from 'lodash/isEmpty';
import Field from './base';
import fieldOutput from './field-output';

export default class Address extends Field {
  static outputs = [
    fieldOutput('single_line', 'string_format', 'string'),
    fieldOutput('single_line_no_unit', 'string_format', 'string'),
    fieldOutput('multi_line', 'string_format', 'string'),
    fieldOutput('street_line', 'string_format', 'string'),
  ];

  renderString(value) {
    const format = this.params.format || 'single_line';
    const addressSingleLine = (v) => {
      if (!v || isEmpty(v)) {
        return '';
      }
      const street = `${v.street} ${v.unit || ''}`.trim();
      return [street, `${v.city}, ${v.state} ${v.zip_code}`].join(', ');
    };
    const addressSingleLineNoUnit = (v) => {
      if (!v || isEmpty(v)) {
        return '';
      }
      return [`${v.street}`, `${v.city}, ${v.state} ${v.zip_code}`].join(', ');
    };
    const formaters = {
      single_line: addressSingleLine,
      single_line_no_unit: addressSingleLineNoUnit,
      multi_line: (v) => {
        if (!v || isEmpty(v)) {
          return '';
        }
        const street = `${v.street} ${v.unit || ''}`.trim();
        return [street, `${v.city}, ${v.state} ${v.zip_code}`].join('\n');
      },
      street_line: (v) => {
        if (!v || isEmpty(v)) {
          return '';
        }
        return `${v.street} ${v.unit || ''}`.trim();
      },
    };
    return (formaters[format] || addressSingleLine)(value);
  }
}
