export function getFieldId(name, formName) {
  return formName ? `${formName}_${name}` : name;
}

export function joinName(namePrefix, name) {
  if (!namePrefix) {
    return name;
  }
  return [namePrefix, name].join('.');
}
