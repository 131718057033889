import React, { Fragment } from 'react';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import Loadable from 'react-loadable';

type Theme = 'filled' | 'outlined' | 'twoTone';
type DynamicAntdIconProps = {
  type: string;
  theme?: Theme;
  className?: string;
  rotate?: number;
  spin?: boolean;
  style?: React.CSSProperties;
  twoToneColor?: string;
};

function getAntdIconName(type: string, theme?: Theme) {
  return (
    type &&
    upperFirst(camelCase(type)) + (theme ? upperFirst(theme) : 'Outlined')
  );
}

const DynamicAntdIcon: React.FC<DynamicAntdIconProps> = Loadable({
  loader: () => import('./compound-antd-icons'),
  render(loaded, { type, theme, ...restProps }) {
    if (type) {
      const componentName = getAntdIconName(type, theme);
      const Component = loaded[componentName];

      if (Component) {
        return <Component {...restProps} />;
      }

      const error = new Error(
        `There is no component named ${componentName} exported in file "src/components/common/dynamic-antd-icon/compound-antd-icons"!`
      );
      console.error(error);
      window.DD_RUM?.addError(error);
    }

    return null;
  },
  loading: () => null,
});

export default DynamicAntdIcon;
