import { isGlideAndroidApp } from './browsers';

/* Sends a message to Glide iOS app */
export function sendMessage(options) {
  const { data, dispose = false } = options;
  const name = 'mobileapp';
  const isAndroidWebView = isGlideAndroidApp();

  let channel;
  if (isAndroidWebView) {
    channel = window.AndroidBridge;
  } else {
    channel =
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers[name];
  }

  if (channel) {
    try {
      if (isAndroidWebView) {
        channel?.sendMessage(JSON.stringify(data));
      } else {
        channel.postMessage(data);
      }
      if (dispose && window.Glide.mobileBridgeListeners) {
        delete window.Glide.mobileBridgeListeners[name];
      }
    } catch (e) {
      window.DD_RUM?.addError(e);
    }
  }
}

/* Enables Glide iOS app to send us messages. */
export function receiveMessage(options) {
  const { name, cb, permanent } = options;
  if (!window.Glide.mobileBridgeListeners) {
    window.Glide.mobileBridgeListeners = {};
  }
  window.Glide.mobileBridgeListeners[name] = (...args) => {
    if (!permanent && window.Glide.mobileBridgeListeners) {
      delete window.Glide.mobileBridgeListeners[name];
    }
    return cb(...args);
  };
}

/* Remember to call disposeListener(names) on componentWillUnmount()
  and anytime some listeners are no longer needed
  for a proper cleanup of callbacks.
  It will delete window.Glide.mobileBridgeListeners[name] for each name in names.
  If names is null, then all the listeners will be removed */
export function disposeListener(names) {
  if (!window.Glide.mobileBridgeListeners) {
    return;
  }
  if (names) {
    names.forEach((name) => {
      delete window.Glide.mobileBridgeListeners[name];
    });
    return;
  }
  delete window.Glide.mobileBridgeListeners;
}
