import AppModal from 'src/components/common/app-modal';
import React from 'react';
import loadingWrap from './loading-wrap';
import preventDefault from 'src/utils/prevent-default';

export default loadingWrap((props) => (
  <AppModal
    {...props}
    confirmLoading={props.loading}
    onOk={props.handleAsync(async () => {
      await props.onOk();
    })}
  >
    <form
      onSubmit={preventDefault(
        props.handleAsync(async () => {
          if (props.onOk) {
            await props.onOk();
          }
        })
      )}
    >
      <input type="hidden" />
      {props.children}
    </form>
  </AppModal>
));
