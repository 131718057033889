import { FormConfigItemKind } from 'src/types/proto/reform';
import type Item from '../item';
import BaseItemDelegate from './base';
import BooleanItemDelegate from './boolean';
import CurrencyItemDelegate from './currency';
import DynamicExplanationItemDelegate from './dynamic-explanation';
import LegalDisclaimerItemDelegate from './legal-disclaimer';
import MultipleSelectItemDelegate from './multiple-select';
import NumericItemDelegate from './numeric';
import StringItemDelegate from './string';
import StringChoiceItemDelegate from './string-choice';

const DELEGATES_BY_KIND: Partial<
  Record<FormConfigItemKind, new (item: Item) => BaseItemDelegate>
> = {
  BOOLEAN: BooleanItemDelegate,
  STRING_CHOICE: StringChoiceItemDelegate,
  MULTIPLE_SELECT: MultipleSelectItemDelegate,
  STRING: StringItemDelegate,
  DYNAMIC_EXPLANATION: DynamicExplanationItemDelegate,
  LEGAL_DISCLAIMER: LegalDisclaimerItemDelegate,
  CURRENCY: CurrencyItemDelegate,
  NUMERIC: NumericItemDelegate,
};

export default function getDelegate<T extends BaseItemDelegate>(item: Item): T {
  const Cls = DELEGATES_BY_KIND[item.kind] || BaseItemDelegate;
  return new Cls(item) as T;
}
