import isNil from 'lodash/isNil';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { ValueOf } from 'type-fest';
import type { PermissionEnum } from 'src/types/proto/permissions';
import type { Condition, Field, Output } from 'src/types/proto/reform';
import type BoundField from '../bound-field';
import { Validation } from '../namespace';
import type { RenderOptions } from '../render-options';
import type Arithmetic from './handlers/arithmetic';
import type GroupJoinedName from './handlers/group-joined-name';
import type Identity from './handlers/identity';
import type Negate from './handlers/negate';
import type Part from './handlers/part';

const COMPUTED_ONLY_COMPONENTS = ['arithmetic'];

const normalizedKind = {
  text: 'text',
  string: 'text',
  string_list: 'checkbox',
  boolean: 'checkbox',
  simple_string_choice: 'radio',
  radio: 'radio',
  checkbox: 'checkbox',
  date: 'date',
  address: 'address',
  time: 'time',
  percentage: 'percent',
  integer: 'number',
  contact: 'person',
  phone_number: 'phone number',
  positive_integer: 'number',
  positive_nonzero_integer: 'number',
  recurring_fee: 'recurring fee',
  currency: 'currency',
  decimal: 'decimal number',
} as const;

type NormalizedKind = ValueOf<typeof normalizedKind>;
// eslint-disable-next-line
export type OutputWithOutKind = { out_kind?: string } & Output;
export type OutputHandler =
  | Arithmetic
  | GroupJoinedName
  | Identity
  | Negate
  | Part;

class OutContext {
  /*
    This is equivalent to OutContext on the backend, it is passed
    to the output kind to compute the output's value
  */
  bindings: Record<string, BoundField>;
  output: Output;
  @observable renderOptions: RenderOptions;

  constructor(
    bindings: Record<string, BoundField>,
    output: Output,
    renderOptions: RenderOptions
  ) {
    makeObservable(this);
    this.bindings = bindings;
    this.output = output;
    this.renderOptions = renderOptions;
  }

  get(key: string) {
    return this.bindings[key].value;
  }

  setRenderOptions = (renderOptions: RenderOptions) => {
    this.renderOptions = renderOptions;
  };
}

export default class BoundOutput {
  /*
    A BoundOutput provides the PdfOutput component access
    to the (appropriately aliased) data and interface it needs

    Most importantly, you can get the current value and
    set the bound fields, which dynamically update the value
  */
  outContext: OutContext;
  bindings: Record<string, BoundField>;
  outputHandler: OutputHandler;
  output: OutputWithOutKind;
  renderOptions: RenderOptions;
  baseOutput?: Output;

  constructor(
    bindings: Record<string, BoundField>,
    outputHandler: OutputHandler,
    output: OutputWithOutKind,
    renderOptions: RenderOptions,
    baseOutput?: Output
  ) {
    makeObservable(this);
    this.outContext = new OutContext(bindings, output, renderOptions);
    this.outputHandler = outputHandler;
    this.output = output;
    this.baseOutput = baseOutput;
    this.bindings = bindings;
    this.renderOptions = renderOptions;
    reaction(() => this.isNa, this.setNaValue);
    this.setNaValue(this.isNa);
  }

  withBaseOutput = (baseOutput: Output) => {
    return new BoundOutput(
      this.bindings,
      this.outputHandler,
      this.output,
      this.renderOptions,
      baseOutput
    );
  };

  @action
  setRenderOptions = (renderOptions: RenderOptions) => {
    this.outContext.setRenderOptions(renderOptions);
    this.renderOptions = renderOptions;
  };

  @computed
  get isIdentity() {
    return this.handler === 'identity';
  }

  get id() {
    return this.output.id;
  }

  @computed
  get boundNamespaces() {
    return uniq(values(this.outContext.bindings).map((f) => f.namespace.id));
  }

  @computed
  get linkedOutput(): BoundOutput | null | undefined {
    if (this.isIdentity) {
      return this.getField('this').linkedOutput;
    }
    return undefined;
  }

  @computed
  get topLinkedOutput(): BoundOutput {
    if (!this.isLinked) {
      return this;
    }
    return this.linkedOutput?.topLinkedOutput ?? this;
  }

  @computed
  get isLinked() {
    if (!this.linkedOutput) {
      return null;
    }
    return !this.getField('this').isUnlinked;
  }

  setNaValue = (isNa: boolean) => {
    if (!isNa || isNil(this.naValue)) {
      return;
    }

    if (this.pdfValue !== this.naValue) {
      this.setFields({
        this: this.naValue,
      });
    }

    if (this.isLinked && this.linkedOutput?.pdfValue !== this.naValue) {
      this.linkedOutput?.setFields({
        this: this.naValue,
      });
    }
  };

  setIsUnlinked = (value: boolean) => {
    if (!this.linkedOutput) {
      return;
    }
    const current = this.getField('this').isUnlinked;
    if (current === value) {
      return;
    }
    this.setFields({
      this: this.pdfValue,
    });
    this.getField('this').setIsUnlinked(value);
  };

  @computed
  get delegateOutput(): BoundOutput | null | undefined {
    return this.isLinked ? this.linkedOutput : this;
  }

  @computed
  get label() {
    return this.output.label;
  }

  @computed
  get handler() {
    return this.outputHandler.id;
  }

  @computed
  get outKind() {
    // this is going to be 'string'
    // for text fields because, well, the
    // output is a string
    return this.output.out_kind;
  }

  @computed
  get inputKind() {
    return this.outputHandler.inputKind(this.outContext);
  }

  @computed
  get normalizedKind(): NormalizedKind | null {
    if (this.linkedOutput) {
      return this.linkedOutput.normalizedKind;
    }
    return (
      normalizedKind[this.inputKind as keyof typeof normalizedKind] || null
    );
  }

  @computed
  get identityFieldName() {
    return this.outputHandler.getIdentityFieldName(this.outContext);
  }

  @computed
  get pdfValue() {
    if (this.isNa && isNil(this.naValue)) {
      return undefined;
    }
    return this.outputHandler.pdfValue(this.outContext);
  }

  @computed
  get readOnlyValue() {
    return this.pdfValue;
  }

  @computed
  get params() {
    return this.outputHandler.getParams(this.outContext);
  }

  @computed
  get outputParams() {
    return this.output.params;
  }

  getFieldValue = (key: string) => {
    return this.outContext.get(key);
  };

  @computed
  get boundFields() {
    return values(this.outContext.bindings);
  }

  getField = (key: string) => {
    return this.outContext.bindings[key];
  };

  canSetValue = (permissions: PermissionEnum[]) => {
    return (
      !this.isNa &&
      Object.values(this.outContext.bindings).every((binding) => {
        return binding.canSetValue(permissions);
      })
    );
  };

  @computed
  get isComputedOnly() {
    return COMPUTED_ONLY_COMPONENTS.includes(this.delegateOutput?.handler);
  }

  @computed
  get isNa(): boolean {
    if (this.linkedOutput && this.linkedOutput.isNa) {
      return true;
    }
    return Object.values(this.outContext.bindings).every((binding) => {
      return binding.isNa;
    });
  }

  @computed
  get naValue(): Condition['value'] | undefined {
    const field = this.getField('this');
    if (!isNil(field?.condition?.value)) {
      return this.outputHandler.getValue(field?.condition?.value);
    }
    return undefined;
  }

  setFields = (data: Record<string, Field>) => {
    toPairs(data).forEach(([k, v]) => {
      this.outContext.bindings[k].set(this.outputHandler.getValue(v));
    });
  };

  setFieldsValidation = (data: Record<string, Validation>) => {
    Object.entries(data).forEach(([k, v]) => {
      this.outContext.bindings[k].setValidation(v);
    });
  };

  @computed
  get isName(): boolean {
    return (
      this.normalizedKind === 'person' || this.handler === 'group_joined_name'
    );
  }
}
