// Example:
// <input onKeyPress={filterKeyPress('Enter')((e) => doSomething())} />

import React from 'react';

type WrappedFn = (e: React.KeyboardEvent, ...args: unknown[]) => unknown;

export default function filterKeyPress(...keys: string[]) {
  return function wrapFilterKeyPress(wrappedFn: WrappedFn) {
    return function filterKeyPressEventHandler(
      e: React.KeyboardEvent,
      ...args: unknown[]
    ) {
      if (keys.includes(e.key)) {
        return wrappedFn(e, ...args);
      }
      return undefined;
    };
  };
}

export function filterEnterKeyPress(wrappedFn: WrappedFn) {
  return filterKeyPress('Enter')(wrappedFn);
}

export function filterEscapeKeyPress(wrappedFn: WrappedFn) {
  return filterKeyPress('Escape')(wrappedFn);
}

export function filterBackspaceKeyDown(wrappedFn: WrappedFn) {
  return filterKeyPress('Delete', 'Backspace')(wrappedFn);
}
