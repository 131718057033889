import Field from './base';
import fieldOutput from './field-output';

export default class Date extends Field {
  static outputs = [fieldOutput('short', 'string_format', 'string')];

  renderString(value) {
    const format = this.params.format || 'standard';
    const formatters = {
      short: (v) => v || '',
    };
    return (formatters[format] || ((v) => v || ''))(value);
  }
}
