export const UploadErrorCode = {
  INVALID_PDF: 'INVALID_PDF',
  DOCUSIGN_FAILURE: 'DOCUSIGN_FAILURE',
  CONVERSION_ERROR: 'CONVERSION_ERROR',
  FILE_TOO_LARGE: 'FILE_TOO_LARGE',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
} as const;
export type UploadErrorCode =
  typeof UploadErrorCode[keyof typeof UploadErrorCode];

/**
 * Map of `UploadErrorCode`s to their message generator functions.
 */
export const ERROR_CODE_MESSAGES: {
  [code in UploadErrorCode]: (data?: unknown) => string;
} = {
  INVALID_PDF: () => 'Invalid PDF, please check your file and try again.',
  DOCUSIGN_FAILURE: () => 'Failed to download file from docusign.',
  CONVERSION_ERROR: () => 'Only PDF, Docs and image files are allowed.',
  FILE_TOO_LARGE: (data) => {
    const typedData = data as { size: number };
    let sizeMessage = '';
    if (typedData?.size !== undefined && typedData?.size > 0) {
      const sizeInMB = Math.floor(typedData.size / (1024 * 1024) /* 1 MB */);
      sizeMessage = ` Max allowed is ${sizeInMB} MB.`;
    }
    return `File is too large.${sizeMessage}`;
  },
  UNKNOWN_ERROR: () => GENERIC_ERROR_MESSAGE,
};

const GENERIC_ERROR_MESSAGE = 'Something went wrong. Try again later.';

/**
 * A fileservice upload error. Different types are represented by their `code` field.
 */
export class UploadError extends Error {
  code: UploadErrorCode;

  /**
   * Constructs an `UploadError`. By default, the error code is `UNKNOWN_ERROR` unless
   * a different code is provided via `details.code` parameter.
   * @param details Optional details to provide in the error.
   */
  constructor(details?: { code?: UploadErrorCode; message?: string }) {
    super(details?.message);
    this.code = details?.code ?? UploadErrorCode.UNKNOWN_ERROR;
  }

  /**
   * Create a default `UploadError` for those cases where you just don't know what happened.
   */
  static getUnknownError(): UploadError {
    return new UploadError({
      code: UploadErrorCode.UNKNOWN_ERROR,
      message: GENERIC_ERROR_MESSAGE,
    });
  }

  /**
   * Check if a given string is a valid `UploadErrorCode`.
   */
  static isValidErrorCode(code: string): boolean {
    return Object.values(UploadErrorCode).includes(code as UploadErrorCode);
  }

  /**
   * Create an `UploadError` from a glide file-service response, with appropriate messages based on the error code.
   * If the passed response is not an error, this will return `null`.
   */
  static from(response: unknown): UploadError | null {
    try {
      let parsedResponse = response;
      if (typeof response === 'string') {
        parsedResponse = JSON.parse(response);
      }
      const errCode = (parsedResponse as { error: string })?.error;

      if (errCode === undefined || typeof errCode !== 'string') {
        return null;
      }

      let typeCheckedCode: UploadErrorCode = UploadErrorCode.UNKNOWN_ERROR;
      if (UploadError.isValidErrorCode(errCode)) {
        typeCheckedCode = errCode as UploadErrorCode;
      }

      return new UploadError({
        code: typeCheckedCode,
        message: ERROR_CODE_MESSAGES[typeCheckedCode](parsedResponse),
      });
    } catch (err) {
      return new UploadError({
        code: UploadErrorCode.UNKNOWN_ERROR,
        message: ERROR_CODE_MESSAGES[UploadErrorCode.UNKNOWN_ERROR](),
      });
    }
  }
}
