import HelpIconTooltip from 'src/components/common/help-icon-tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Form } from '@ant-design/compatible';

import { QuickFormContext } from './context';
import { getFieldId } from './utils';

const clsPrefix = 'app-quick-form-item';

const MemoInput = React.memo(
  ({ children }) => children,
  (prev, next) =>
    prev.value === next.value && prev.placeholder === next.placeholder
);
MemoInput.propTypes = {
  children: PropTypes.any,
};

function defaultGetValueFromEvent(valuePropName, ...args) {
  const event = args[0];

  if (event && event.target && valuePropName in event.target) {
    return event.target[valuePropName];
  }

  return event;
}

function QuickFormItem({
  className,
  children,
  name,
  memo,
  noStyle,
  trigger,
  valuePropName,
  getValueFromEvent,
  validate,
  validateTriggers,
  hasFieldSubmitCallback,
  getMemoKey,
  getValue,
  label: rawLabel,
  labelTooltip,
  ...otherProps
}) {
  const {
    getFieldValue,
    isFieldRequired,
    onFieldChange,
    formName,
    errors,
    registerFieldSubmitCallback,
    validate: validateFields,
  } = React.useContext(QuickFormContext);

  const fieldId = getFieldId(name, formName);

  let childNode = null;
  let boundFormItemProps;

  if (React.isValidElement(children)) {
    if (name) {
      // Props for the Form.Item
      boundFormItemProps = {
        htmlFor: fieldId,
        help: errors[name],
        validateStatus: errors[name] ? 'error' : null,
        required: isFieldRequired(name),
      };

      // New props for the child input
      const val = getFieldValue(name);
      const value = getValue ? getValue(val) : val;
      const childProps = {
        id: fieldId,
        [valuePropName]: value,
        [trigger]: (...args) => {
          const myGetValueFromEvent =
            getValueFromEvent ||
            defaultGetValueFromEvent.bind(null, valuePropName);
          const v = myGetValueFromEvent(...args);
          onFieldChange(name, v, {
            validate: (validateTriggers || []).includes(trigger)
              ? validate
              : false,
          });
          if (children.props[trigger]) {
            children.props[trigger](...args);
          }
        },
        ...(hasFieldSubmitCallback
          ? {
              registerFieldSubmitCallback: registerFieldSubmitCallback.bind(
                null,
                name
              ),
            }
          : null),
      };

      // Bind validationTrigger events (except for the field on change event)
      (validateTriggers || []).forEach((eventName) => {
        if (eventName === trigger) {
          return;
        }
        childProps[eventName] = (...args) => {
          validateFields([name]);
          if (children.props[eventName]) {
            return children.props[eventName](...args);
          }
          return undefined;
        };
      });

      childNode = React.cloneElement(children, childProps);
      if (memo) {
        // For performance reasons, we can avoid re-rendering inputs if the value
        // hasn't changed.
        childNode = (
          <MemoInput
            value={
              getMemoKey
                ? getMemoKey(childProps[valuePropName], childProps)
                : childProps[valuePropName]
            }
            placeholder={childNode.props.placeholder}
          >
            {childNode}
          </MemoInput>
        );
      }
    } else {
      childNode = children;
    }

    if (noStyle) {
      return childNode;
    }
  } else {
    childNode = children;
  }

  const label = labelTooltip ? (
    <span>
      {rawLabel}
      <HelpIconTooltip
        title={labelTooltip}
        style={{
          marginLeft: 8,
        }}
      />
    </span>
  ) : (
    rawLabel
  );

  return (
    <Form.Item
      className={classNames(
        clsPrefix,
        className,
        name ? `test__qf-item__${name.replace('.', '-')}` : undefined
      )}
      {...boundFormItemProps}
      {...otherProps}
      label={label}
    >
      {childNode}
    </Form.Item>
  );
}

QuickFormItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  name: PropTypes.string,
  memo: PropTypes.bool,
  noStyle: PropTypes.bool,
  trigger: PropTypes.string,
  valuePropName: PropTypes.string,
  getValueFromEvent: PropTypes.func,
  validateTriggers: PropTypes.array,
  validate: PropTypes.oneOf([true, false, 'all']),
  hasFieldSubmitCallback: PropTypes.bool,
  getMemoKey: PropTypes.func,
  getValue: PropTypes.func,
  label: PropTypes.any,
  labelTooltip: PropTypes.string,
};

QuickFormItem.defaultProps = {
  memo: true,
  noStyle: false,
  trigger: 'onChange',
  valuePropName: 'value',
  validate: true,
  validateTriggers: ['onChange', 'onBlur'],
  hasFieldSubmitCallback: false,
};

export default QuickFormItem;
