import Arithmetic from './arithmetic';
import GroupJoinedName from './group-joined-name';
import Identity from './identity';
import Negate from './negate';
import Part from './part';

export const OUTPUT_HANDLERS = {
  part: Part,
  identity: Identity,
  group_joined_name: GroupJoinedName,
  string_format: Identity,
  arithmetic: Arithmetic,
  negate: Negate,
};
