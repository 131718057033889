import get from 'lodash/get';
import Field from './base';

export default class SimpleStringChoice extends Field {
  render(type, value) {
    const choice = this.params.choices.find((c) => c.value === value);
    return get(choice, type);
  }

  renderString(value) {
    return this.render('label', value);
  }

  renderValue(value) {
    return this.render('value', value);
  }
}
