

import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import AppIcon from 'src/components/common/app-icon';
import hideBars from 'src/components/common/hide-bars';
import PoweredByGlide from 'src/components/common/powered-by-glide';
import type FeaturesStore from 'src/stores/features-store';
import type UiStore from 'src/stores/ui-store';
import { getErrorAsJson } from 'src/utils/getErrorAsJson';

const clsPrefix = 'app-full-error-message';

export interface AppFullErrorMessageProps {
  message: string;
  fullPage?: boolean;
  fullError?: Error;
  suppressLogs?: boolean;
}

export interface AppFullErrorMessageOwnProps extends AppFullErrorMessageProps {
  ui: UiStore;
}

interface InnerErrorMessageProps
  extends Omit<AppFullErrorMessageOwnProps, 'fullError' | 'suppressLogs'> {
  features: FeaturesStore;
}

const InnerErrorMessage: FC<InnerErrorMessageProps> = observer(
  ({ message, fullPage }) => (
    <div
      className={classNames(clsPrefix, 'flex-column flex-center', {
        'full-page': Boolean(fullPage),
      })}
    >
      <div className={`${clsPrefix}__wrapper`}>
        <AppIcon
          className={`${clsPrefix}__icon`}
          type="cx"
          name="triangleWithExclamationPoint"
        />
        <h3 className={`${clsPrefix}__message`}>{message}</h3>
        <p>
          <a href="" className={`${clsPrefix}__reload`}>
            Reload page
          </a>
        </p>
      </div>
    </div>
  )
);

const AppFullErrorMessage: FC<AppFullErrorMessageOwnProps> = inject('ui')(
  observer(
    ({ message, fullPage = true, fullError, suppressLogs = false, ui }) => {
      // Do not use appStore directly!
      // The actual appStore can also be client store. It will be a different store from agent store
      const embeddedApp = ui.parent.embeddedApp;

      useEffect(() => {
        if (ui.isEmbedded && !suppressLogs) {
          embeddedApp?.sendError(message, {
            fullError: getErrorAsJson(fullError),
          });
        }
      }, []);

      const Inner = fullPage
        ? hideBars()(InnerErrorMessage)
        : InnerErrorMessage;
      return <Inner fullPage={fullPage} message={message} />;
    }
  )
);

export default AppFullErrorMessage as FC<AppFullErrorMessageProps>;
