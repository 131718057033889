export interface Contact {
  firstName: string;
  lastName: string;
  email?: string;
  cellPhone?: string;
  reAgent?: {
    licenseNumber?: string;
  };
}

export default function getFullName({ firstName, lastName }: Contact) {
  const parts = [firstName, lastName];
  return parts
    .map((n) => n && n.trim && n.trim())
    .filter((n) => n)
    .join(' ');
}
