// Generate or wrap an event handler that stops the propagation
// of the incoming event.
//
// Usage:
//    <a href="#" onClick={stopPropagation} />
//    <a href="#" onClick={stopPropagation(myHandler)} />

export default function stopPropagation(handler) {
  if (handler && handler.stopPropagation) {
    // handler is an event.
    handler.stopPropagation();
    return undefined;
  }

  return (
    handler &&
    ((e, ...args) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      } else if (e && e.domEvent && e.domEvent.stopPropagation) {
        e.domEvent.stopPropagation();
      }
      return handler(e, ...args);
    })
  );
}
