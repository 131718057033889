/*
 * JS Error properties are non-enumerable. In certain cases, we would like
 * them to be JSON readable, so we force that here.
 */
export const getErrorAsJson = (error: any) =>
  error && typeof error === 'object'
    ? Object.getOwnPropertyNames(error).reduce(
        (errObject: any, curr: string) => {
          errObject[curr] = error[curr];
          return errObject;
        },
        {}
      )
    : null;
