import { documentUploadedFromComputer } from '@uc/analytics-definitions';
import { notification } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import throttle from 'lodash/throttle';
import toPairs from 'lodash/toPairs';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import showDocumentNotification, {
  END_TIME,
  FILE_NOTIFICATION_KEY,
} from 'src/components/documents/upload-notification';
import { UploadError } from 'src/errors/documents/file-upload-error';
import logger from 'src/logger';
import {
  createTDV,
  uploadNewDocumentsIntent,
} from 'src/models/transactions/intents';
import { productPathFromEmbeddedFeature } from 'src/utils/analytics/product-path';
import { upload } from 'src/utils/pick-file';
import pusher from 'src/utils/pusher';

// time after which toast will dismiss after all file are uploaded
const TOAST_DISMISS_TIME = 500; // .5 seconds

export default class DocumentsUploadStore {
  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  @observable docs = new Map(); // former uploadStatus
  @observable documentPayload = new Map();
  @observable fileUploadAccepts = '.pdf';
  @observable fileUploaderBtnRef = null;
  @observable fileUploadHandler = null;
  @observable fileUploaderBtn = null;

  initialize(options) {
    const uuid = get(options, 'user.uuid');
    this.channelKey = `document-upload-${uuid}`;
    this.channel = pusher.subscribe(this.channelKey);
  }

  @computed
  get embeddedApp() {
    return this.parent.embeddedApp;
  }

  updateNotification = (fileName, progress = false) => {
    if (!this.embeddedApp?.isEmbedded) {
      const allComplete = this.areAllComplete();

      showDocumentNotification(
        this.docs,
        allComplete,
        this.hideNotification,
        progress,
        this.parent.ui,
        this.parent.features
      );
    }
  };

  /**
   * Updates the associated data for a given in progress upload (i.e. { status, asPDF, error})
   * */
  updateDocInfo = (filename, updates, addIfNotPresent = false) => {
    const doc = this.docs.get(filename);
    if (doc !== undefined || addIfNotPresent) {
      runInAction(() => {
        const updatedDoc = Object.assign(doc || {}, updates);
        this.docs.set(filename, updatedDoc);
      });
    }
  };

  // Embedded toasts to send to parent
  //  status: 'start' | 'success' | 'error'
  handleEmbeddedToast = (status, filename = undefined) => {
    if (!this.embeddedApp?.isEmbedded) {
      return;
    }

    const fileCount = [...Array.from(this.docs.values())].length;
    switch (status) {
      case 'start':
        this.embeddedApp.addToast({
          variant: 'info',
          message: 'Started uploading files...',
        });
        break;

      case 'success':
        this.embeddedApp.addToast({
          variant: 'success',
          message: `Successfully finished uploading ${fileCount} file${
            fileCount > 1 ? 's' : ''
          }...`,
        });
        this.docs.clear();
        break;

      case 'error': {
        const docObject = this.docs.get(filename);
        const errMessage =
          docObject?.error?.message ?? UploadError.getUnknownError().message;
        const message = `${
          filename ? `Error uploading ${filename}:` : 'Error:'
        } ${errMessage}`;

        this.embeddedApp.addToast({
          variant: 'error',
          message,
          duration: 0,
        });

        this.docs.clear();
        break;
      }

      default:
        break;
    }
  };

  isOffer = () => {
    return this.extraUploadOptions?.includes('isOffer');
  };

  areAllNotStarted = () => {
    // if there are no docs initialized yet, none has started upload
    const allStatus = [...Array.from(this.docs.values()).map((v) => v.status)];
    return allStatus.length === 0;
  };

  areAllComplete = () => {
    const allStatus = [...Array.from(this.docs.values()).map((v) => v.status)];
    return allStatus.every((s) => s <= 0);
  };

  areAllSuccessful = () => {
    const allStatus = [...Array.from(this.docs.values()).map((v) => v.status)];
    const allErrorStatus = [
      ...Array.from(this.docs.values()).map((v) => v.error),
    ];
    return (
      allStatus.every((s) => s === END_TIME) &&
      allErrorStatus.every((e) => e === undefined || e === null)
    );
  };

  hideNotification = () => {
    if (this.areAllComplete() && !this.embeddedApp.isEmbedded) {
      notification.close(FILE_NOTIFICATION_KEY);
      this.docs.clear();
    }
  };

  syncDocuments = async () => {
    const documents = cloneDeep([...this.documentPayload.keys()]);
    const payloads = documents.map((d) => {
      const payload = this.documentPayload.get(d);
      runInAction(() => {
        this.documentPayload.delete(d);
      });
      return payload;
    });
    if (payloads && payloads.length > 0) {
      const payloadsByTransId = groupBy(payloads, 'transId');
      toPairs(payloadsByTransId).forEach(async ([transId, transPayloads]) => {
        const versions = transPayloads.map((p) => p.version);
        try {
          await this.parent.transactions.dispatch(
            transId,
            createTDV({
              versions,
            })
          );
          documentUploadedFromComputer({
            product: 'documents',
            sub_product: 'document_files',
            product_path: productPathFromEmbeddedFeature(
              this.embeddedApp.embeddedFeature
            ),
          });
          documents.forEach((d) => {
            this.uploadComplete(d);
          });
        } catch (e) {
          documents.forEach((d) => {
            this.uploadFailed(d);
          });
        }
      });
    }
  };

  throttledSyncDocuments = throttle(this.syncDocuments, 3000);

  documentCreated = (filename, payload) => {
    runInAction(() => {
      this.documentPayload.set(filename, payload);
    });
    this.throttledSyncDocuments();
  };

  uploadStarted = (filename, asPDF) => {
    if (this.areAllNotStarted() && !this.isOffer()) {
      // for offer skip start embedded toast otherwise it delays background offer OCR toasts
      this.handleEmbeddedToast('start');
    }
    this.updateNotification(filename);
    // Update doc info after notifications, because `areAllNotStarted()` depends on the docs list being empty
    this.updateDocInfo(
      filename,
      {
        status: moment(),
        ...(asPDF ? { asPDF } : null),
      },
      true
    );
  };

  uploadProgressUpdated = (filename, progress) => {
    this.updateDocInfo({ status: moment() });
    this.updateNotification(filename, progress);
  };

  uploadComplete = (filename) => {
    this.updateDocInfo(filename, { status: END_TIME });
    this.updateNotification(filename);
    if (this.areAllSuccessful()) {
      if (!this.isOffer()) {
        // skip success embedded toast otherwise it delays background offer OCR toasts
        this.handleEmbeddedToast('success');
      }
      setTimeout(this.hideNotification, TOAST_DISMISS_TIME);
    }
  };

  uploadFailed = (filename, error) => {
    this.updateDocInfo(filename, { status: END_TIME, error });
    this.updateNotification(filename);
    this.handleEmbeddedToast('error', filename);
  };

  asyncUploadOne = async ({
    transactionId,
    folderId,
    file,
    tdvId,
    asPDF,
    taskId,
    extraUploadOptions = [],
  }) => {
    this.extraUploadOptions = extraUploadOptions;
    this.uploadStarted(file.name, asPDF);

    const setUploadStatus = (progress) => {
      this.uploadProgressUpdated(file.name, progress);
    };

    try {
      const f = await upload({
        file,
        asPDF,
        setUploadStatus,
        features: this.parent.features,
      });
      const res = uploadNewDocumentsIntent({
        folderId,
        tdvId,
        files: [f],
        taskId,
      });
      this.documentCreated(file.name, {
        transId: transactionId,
        version: res.createTransactionDocumentVersions.newVersions[0],
      });
    } catch (e) {
      logger.error(`Error when trying to upload ${file.name}`);
      this.uploadFailed(file.name, e);
    }
  };

  upload = async ({ transactionId, folderId, files, tdvId, asPDF, taskId }) => {
    const uploaded = [];

    // upload in series
    await files
      .map((file) => async () => {
        try {
          this.uploadStarted(file.name, asPDF);
          uploaded.push({
            name: file.name,
            ...(await upload({
              file,
              asPDF,
              features: this.parent.features,
            })),
          });
        } catch (err) {
          this.uploadFailed(file.name, err);
        }
      })
      .reduce((p, f) => p.then(f), Promise.resolve());

    const res = await this.parent.transactions.dispatch(
      transactionId,
      uploadNewDocumentsIntent({
        folderId,
        tdvId,
        files: uploaded,
        taskId,
      })
    );
    documentUploadedFromComputer({
      product: 'documents',
      sub_product: 'document_files',
      product_path: productPathFromEmbeddedFeature(
        this.embeddedApp.embeddedFeature
      ),
    });

    uploaded.forEach(({ name }) => {
      this.uploadComplete(name);
    });

    return res.result;
  };
}
