

import ErrorBoundary from 'src/components/common/error-boundary';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

const clsPrefix = 'app-match';

@inject('router')
@observer
export default class Match extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    routeName: PropTypes.string.isRequired,
    activeStrict: PropTypes.bool,
    routeParams: PropTypes.object,
    children: PropTypes.node,
    render: PropTypes.func,
    ignoreParams: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    route: PropTypes.object,
    captureErrors: PropTypes.bool,
    paramKey: PropTypes.bool,
    fragment: PropTypes.bool,
  };

  static defaultProps = {
    activeStrict: false,
    routeParams: null,
    children: null,
    ignoreParams: false,
    captureErrors: true,
    fragment: true,
  };

  constructor(props, context) {
    super(props, context);
    this.router = this.props.router;
  }

  getRoute() {
    return this.props.route !== undefined
      ? this.props.route
      : this.router.hotRoute;
  }

  isActiveIgnoreParams() {
    if (!this.router.hasRoute) {
      return false;
    }

    const route = this.getRoute();

    const activeName = route.name;
    const matchName = this.props.routeName;

    if (activeName === matchName) {
      return true;
    } else if (this.props.activeStrict) {
      return false;
    }

    const regex = new RegExp(`^${matchName}\\.(.*)$`);
    return regex.test(activeName);
  }

  isActive() {
    if (this.props.ignoreParams) {
      return this.isActiveIgnoreParams();
    }

    return this.router.isActive(
      this.props.routeName,
      this.props.routeParams,
      this.props.activeStrict
    );
  }

  renderChildren(route) {
    const {
      className,
      render,
      children: children_,
      captureErrors,
      fragment,
      style,
      paramKey,
    } = this.props;

    const children = render ? render(route) : children_;
    const content = captureErrors ? (
      <ErrorBoundary>{children}</ErrorBoundary>
    ) : (
      children
    );
    let key;
    if (paramKey) {
      key = JSON.stringify(route.params);
    } else {
      key = undefined;
    }

    if (fragment) {
      return <Fragment key={key}>{content}</Fragment>;
    }

    return (
      <div
        key={key}
        className={classNames(clsPrefix, className)}
        style={{
          ...style,
        }}
      >
        {content}
      </div>
    );
  }

  render() {
    return this.isActive() ? this.renderChildren(this.getRoute()) : null;
  }
}
