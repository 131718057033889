import { override } from 'mobx';
import { FormConfigItemLegalDisclaimer } from 'src/types/proto/reform';
import BaseItemDelegate from './base';

export default class LegalDisclaimerItemDelegate extends BaseItemDelegate<FormConfigItemLegalDisclaimer> {
  @override
  get isVisible() {
    return false;
  }
}
