// From: https://github.com/cape-io/lodash-humps

import {
  camelCase,
  isArray,
  isObjectLike,
  isPlainObject,
  map,
} from 'lodash/fp';
import set from 'lodash/set';
import transform from 'lodash/transform';
import type { CamelCasedPropertiesDeep } from 'type-fest';

type Converter = (string: string) => string;

function createIteratee(
  converter: Converter,
  self: <T>(node: T) => CamelCasedPropertiesDeep<T>
) {
  return (result: Record<string, any>, value: unknown, key: string) =>
    set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

function createHumps(keyConverter: Converter) {
  return function humps<T = unknown>(node: T): CamelCasedPropertiesDeep<T> {
    if (isArray(node)) {
      return map(humps, node) as unknown as CamelCasedPropertiesDeep<T>;
    }
    if (isPlainObject(node)) {
      return transform(
        node as Record<string, any>,
        createIteratee(keyConverter, humps)
      ) as CamelCasedPropertiesDeep<T>;
    }
    return node as unknown as CamelCasedPropertiesDeep<T>;
  };
}

export default createHumps(camelCase);
