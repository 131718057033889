

import AppIcon from 'src/components/common/app-icon';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import loadingWrap from './loading-wrap';
import preventDefault from 'src/utils/prevent-default';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const clsPrefix = 'app-anchor-button';

export const types = [
  'default',
  'danger',
  'green',
  'gray',
  'primary',
  'purple',
  'label',
];

const targets = ['_blank'];

function AnchorButton({
  onClick,
  children,
  className,
  disabled,
  underline,
  loading,
  type,
  href,
  style,
  target,
  title,
  tooltip,
}) {
  const clsName = classNames(className, clsPrefix, `${clsPrefix}--${type}`, {
    [`${clsPrefix}--disabled`]: disabled,
    [`${clsPrefix}--underline`]: underline,
  });
  const sharedProps = {
    className: clsName,
    children,
    style,
    disabled,
    target,
    title,
  };
  if (href) {
    return <a href={href} {...sharedProps} />;
  }

  const btn = (
    <div
      onClick={
        disabled || loading ? null : preventDefault(onClick || (() => {}))
      }
      role="button"
      {...sharedProps}
    >
      {children}
      {loading && (
        <AppIcon
          className={`${clsPrefix}__loading`}
          type="antd"
          name="loading"
        />
      )}
    </div>
  );

  return tooltip ? <Tooltip {...tooltip}>{btn}</Tooltip> : btn;
}

AnchorButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  underline: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(types),
  href: PropTypes.string,
  target: PropTypes.oneOf(targets),
  title: PropTypes.string,
  tooltip: PropTypes.object,
};

AnchorButton.defaultProps = {
  disabled: false,
  type: 'default',
};

export const LoadingAnchorButton = loadingWrap(
  ({ onClick, loading, handleAsync, children, ...props }) => {
    let body = children;
    if (loading) {
      body = <LoadingOutlined />;
    }
    const wrappedOnClick = handleAsync(onClick);
    return (
      <AnchorButton {...props} onClick={wrappedOnClick}>
        {body}
      </AnchorButton>
    );
  }
);

export default AnchorButton;

export const story = (book) =>
  book.of.add(
    'default',
    book.withInfo()(() => (
      <AnchorButton
        underline={book.knobs.boolean('underline', false)}
        disabled={book.knobs.boolean('disabled', false)}
        type={book.knobs.select('type', types)}
        onClick={() => book.action('click')}
        target={book.knobs.select('target', targets)}
      >
        Click Me
      </AnchorButton>
    ))
  );
