

import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import { Button } from 'antd';
import classNames from 'classnames';
import scrollIntoView from 'dom-scroll-into-view';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import set from 'lodash/set';
import PropTypes from 'prop-types';
import logger from 'src/logger';
import cloneDeep from 'src/utils/clone-deep';
import { filterEnterKeyPress } from 'src/utils/filter-key-press';
import flattenObject from 'src/utils/flatten-object';
import preventDefault from 'src/utils/prevent-default';
import { getScrollableContainer } from 'src/utils/scroll-to';
import stopPropagation from 'src/utils/stop-propagation';
import { QuickFormContext } from './context';
import QuickFormItem from './quick-form-item';
import { getFieldId } from './utils';
import { isRequired, runValidation } from './validation';


const clsPrefix = 'app-quick-form';

export default class QuickForm extends Component {
  static propTypes = {
    name: PropTypes.string,
    initialValue: PropTypes.object,
    getForm: PropTypes.func,
    getErrors: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onSubmittingChange: PropTypes.func,
    className: PropTypes.string,
    hideControls: PropTypes.bool,
    saveText: PropTypes.string,
    cancelText: PropTypes.string,
    submitOnEnter: PropTypes.bool,
    stopPropagation: PropTypes.bool,
    getSaveDisabled: PropTypes.func,
    formRef: PropTypes.func,
    children: PropTypes.func,
    controlsStyle: PropTypes.object,
    saveButtonProps: PropTypes.object,
    cancelButtonProps: PropTypes.object,
    storageKey: PropTypes.string,
    container: PropTypes.any,
    validation: PropTypes.any,
  };

  static defaultProps = {
    saveText: 'Save',
    cancelText: 'Cancel',
    submitOnEnter: false,
    container: 'div',
    getErrors: () => ({}),
    onChange: () => {},
    onSubmittingChange: () => {},
  };

  static Item = QuickFormItem;

  static Section = ({ className, title, children, ...props }) => (
    <div className={classNames(`${clsPrefix}__section`, className)} {...props}>
      {title && <h3 className={`${clsPrefix}__section-title`}>{title}</h3>}
      {children}
    </div>
  );

  constructor(props) {
    super(props);

    this.initialValue = this._getClone(props.initialValue);

    const savedFormValues =
      props.storageKey &&
      JSON.parse(window.sessionStorage.getItem(props.storageKey));
    this.state = {
      formValues: {
        ...(savedFormValues || this.initialValue),
      },
      fieldErrors: {},
      errors: {},
      submitting: false,
      cancelling: false,
    };

    this.fieldSubmitCallbacks = {};
  }

  _getClone = (v = {}) => {
    return cloneDeep(v);
  };

  _getValidationObject = () => {
    const { validation } = this.props;
    return isFunction(validation)
      ? validation(this.state.formValues)
      : validation;
  };

  _fieldValidate = (_names) => {
    const validation = this._getValidationObject();

    if (!validation) {
      return {
        ...this.state.fieldErrors,
      };
    }

    const names = _names !== undefined ? _names : Object.keys(validation);

    const errors = {
      ...this.state.fieldErrors,
    };

    // TODO: support async validation
    const errorsArr = names.map((name) => {
      return validation[name]
        ? runValidation(
            validation[name],
            get(this.state.formValues, name),
            this.state.formValues
          )
        : undefined;
    });

    names.forEach((name, index) => {
      if (!name) {
        return;
      }

      if (errorsArr[index]) {
        errors[name] = errorsArr[index];
      } else {
        delete errors[name];
      }
    });

    return errors;
  };

  _scrollToName = (name, { scrollContainer }) => {
    try {
      const fieldId = getFieldId(name, this.props.name);
      const target = document.getElementById(fieldId);
      if (target) {
        scrollIntoView(
          target,
          scrollContainer || getScrollableContainer(target),
          {
            onlyScrollIfNeeded: true,
          }
        );
      }
    } catch (err) {
      logger.error(err);
    }
  };

  validate = (names, options) => {
    const { scroll, scrollContainer } = {
      scroll: false,
      scrollContainer: null,
      ...options,
    };

    // Returns a promise to be future proof when we implement async validations.
    return new Promise((resolve, reject) => {
      try {
        const fieldErrors = this._fieldValidate(names) || {};
        const formErrors =
          this.props.getErrors(this.state.formValues, {
            fieldErrors,
          }) || {};
        const errors = {
          ...fieldErrors,
          ...formErrors,
        };

        if (scroll && !isEmpty(errors)) {
          this._scrollToName(Object.keys(errors)[0], {
            scrollContainer,
          });
        }

        this.setState(
          {
            fieldErrors,
            errors,
          },
          () => resolve([this.state.formValues, this.state.errors])
        );
      } catch (err) {
        reject(err);
      }
    });
  };

  validateAll = (...args) => this.validate(undefined, ...args);

  _setValuesState = (nextFormValues, validatePaths) => {
    const { storageKey, onChange } = this.props;
    if (storageKey) {
      window.sessionStorage.setItem(storageKey, JSON.stringify(nextFormValues));
    }

    return new Promise((resolve) => {
      this.setState(
        {
          formValues: nextFormValues,
        },
        () => {
          this.validate(validatePaths, {
            scroll: false,
          });
          onChange(nextFormValues); // TODO: Is this in the right place?
          resolve();
        }
      );
    });
  };

  setValues = (values, replace = false) => {
    const { formValues } = this.state;

    if (!values) {
      return null;
    }
    let nextFormValues;
    const namesToValidate = [];

    if (replace) {
      // Ignore the previous values. Use only the values provided.
      nextFormValues = this._getClone(values);
    } else {
      nextFormValues = this._getClone(formValues);

      // Flatten the potentially nested object `values` into key, value pairs
      // where key is a string object path to the value.
      // We do this because each path represents a potential "field" in the form
      // and is thus potentially subject to field-level validation.
      const flattenedValues = flattenObject(values, {
        skipArrays: true,
      });
      Object.entries(flattenedValues).forEach(([name, value]) => {
        set(nextFormValues, name, value);
        namesToValidate.push(name);
      });
    }
    return this._setValuesState(nextFormValues, namesToValidate);
  };

  getFieldValue = (name) => {
    return get(this.state.formValues, name);
  };

  getFieldError = (name) => {
    return this.state.errors[name];
  };

  isFieldRequired = (name) => {
    const validation = this._getValidationObject();
    return validation && isRequired(validation[name]);
  };

  setFieldValue = (
    name,
    value,
    { validate } = {
      validate: true,
    }
  ) => {
    const nextFormValues = {
      ...this.state.formValues,
    };
    set(nextFormValues, name, value);

    let validatePaths = [name];
    if (!validate) {
      validatePaths = [];
    } else if (validate === 'all') {
      validatePaths = undefined;
    }
    return this._setValuesState(nextFormValues, validatePaths);
  };

  registerFieldSubmitCallback = (name, cb) => {
    this.fieldSubmitCallbacks[name] = cb;
  };

  resetValues = () => {
    const { onSubmittingChange } = this.props;

    if (!this.unmounted) {
      if (this.props.storageKey) {
        window.sessionStorage.removeItem(this.props.storageKey);
      }
      onSubmittingChange(false);
      this.setState({
        formValues:
          {
            ...this.initialValue,
          } || {},
        fieldErrors: {},
        errors: {},
        submitting: false,
        cancelling: false,
      });
    }
  };

  cancel = async () => {
    try {
      this.setState({
        cancelling: true,
      });
      const { onCancel } = this.props;
      const res = await onCancel(this.state.formValues, this.resetValues);
      return res;
    } finally {
      if (!this.unmounted) {
        this.setState({
          cancelling: false,
        });
      }
    }
  };

  submit = async (e) => {
    const { onSave, onSubmittingChange } = this.props;
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.state.submitting) {
      return null;
    }

    try {
      if (!this.unmounted) {
        onSubmittingChange(true);
        this.setState({
          submitting: true,
        });
      }

      const [formValues, errors] = await this.validateAll({
        scroll: true,
      });

      if (!isEmpty(errors)) {
        logger.warn('Field validation errors:', errors);
        return null;
      }

      // Allow fields to register a callback that allows them to prepare for submit
      // and/or cancel a submit.
      const fieldCallbackResults = await Promise.all(
        Object.values(this.fieldSubmitCallbacks).map((cb) => (cb ? cb() : true))
      );

      if (
        fieldCallbackResults.length &&
        fieldCallbackResults.some((r) => r === false)
      ) {
        return null;
      }

      if (onSave) {
        const res = await onSave(formValues, this.getFormContext());
        return res;
      }

      return formValues;
    } finally {
      if (!this.unmounted) {
        this.props.onSubmittingChange(false);
        this.setState({
          submitting: false,
        });
      }
    }
  };

  handlePressEnter = () => {
    const { submitOnEnter } = this.props;
    if (submitOnEnter) {
      this.submit();
    }
  };

  getFormContext = () => {
    const { name } = this.props;
    const { submitting, cancelling, errors, formValues } = this.state;

    return {
      formName: name,
      onChange: this.setValues,
      submit: this.submit,
      save: this.submit, // backwards compatibility
      cancel: this.cancel,
      currentValues: formValues,
      errors,
      submitting,
      cancelling,
      resetValues: this.resetValues,
      setValues: this.setValues,
      onFieldChange: this.setFieldValue,
      getFieldValue: this.getFieldValue,
      getFieldError: this.getFieldError,
      isFieldRequired: this.isFieldRequired,
      validateField: this.validateField,
      validate: this.validate,
      registerFieldSubmitCallback: this.registerFieldSubmitCallback,
    };
  };

  componentDidMount() {
    if (this.props.formRef) {
      this.props.formRef(this);
    }
  }

  componentWillUmmount() {
    this.unmounted = true;
  }

  render() {
    const {
      name,
      getForm,
      onSave,
      onCancel,
      className,
      hideControls,
      saveText,
      cancelText,
      getSaveDisabled,
      controlsStyle,
      saveButtonProps,
      cancelButtonProps,
      children,
      stopPropagation: toStopPropagation,
    } = this.props;
    const formContext = this.getFormContext();
    const { submitting, cancelling, formValues } = formContext;
    const saveDisabled = getSaveDisabled ? getSaveDisabled(formValues) : false;
    const getFormFunc = getForm || children;
    const form = getFormFunc(formContext);
    let Container = this.props.container;
    if (this.props.container === 'form') {
      Container = Form;
    }

    return (
      <Container
        className={classNames(clsPrefix, className)}
        role="presentation"
        onClick={toStopPropagation ? stopPropagation : undefined}
        onSubmit={this.submit}
        name={name}
        {...(this.props.container === 'form'
          ? {
              layout: 'vertical',
              onSubmit: preventDefault(this.submit),
              className: classNames(clsPrefix, className),
            }
          : {
              onKeyPress: filterEnterKeyPress(this.handlePressEnter),
            })}
      >
        <QuickFormContext.Provider value={formContext}>
          {form}
        </QuickFormContext.Provider>
        {/* trigger submitOnEnter which is default behavior for forms. */}
        {Container === 'form' && (
          <input
            type="submit"
            style={{
              display: 'none',
            }}
          />
        )}
        {hideControls ? null : (
          <div
            style={{
              paddingTop: '8px',
              ...controlsStyle,
            }}
          >
            {!!onSave && (
              <Button
                size="small"
                type="primary"
                onClick={this.submit}
                style={{
                  marginRight: '4px',
                }}
                loading={submitting}
                disabled={saveDisabled}
                {...saveButtonProps}
              >
                {saveText}
              </Button>
            )}
            {!!onCancel && (
              <Button
                size="small"
                type="secondary"
                onClick={this.cancel}
                loading={cancelling}
                {...cancelButtonProps}
              >
                {cancelText}
              </Button>
            )}
          </div>
        )}
      </Container>
    );
  }
}
