import type {
  GetStaticFormsResponse,
  GetStaticFormsOutputsResponse,
} from 'src/types/proto/services/form_public_service';
import type {
  GetLibrariesFormsCountRequest,
  GetLibrariesFormsCountResponse,
} from 'src/types/proto/services/library_public_service';
import type { ApiResponseData } from 'src/types/utils';
import BaseApi from '../base-api';

export default class Forms extends BaseApi {
  staticForms() {
    return this.get<ApiResponseData<GetStaticFormsResponse>>('/forms/static');
  }

  staticFormsOutputs() {
    return this.get<ApiResponseData<GetStaticFormsOutputsResponse>>(
      '/forms/static/outputs'
    );
  }

  getFormCountByLibraryUuids(
    libraryUuids: GetLibrariesFormsCountRequest['libraryUuids']
  ) {
    return this.get<ApiResponseData<GetLibrariesFormsCountResponse>>(
      '/libraries/forms_count',
      {
        params: {
          library_uuids: libraryUuids,
        },
      }
    );
  }
}
