import type { Clause } from 'src/types/proto/clauses';
import type { GetClausesResponse } from 'src/types/proto/services/clause_public_service';
import type { ApiResponseData, DeepPartial } from 'src/types/utils';
import BaseApi from '../base-api';

export default class Clauses extends BaseApi {
  list() {
    return this.get<ApiResponseData<GetClausesResponse>>('/clauses');
  }

  create(clause: DeepPartial<Clause>) {
    return this.post<Clause>('/clauses', clause);
  }

  update(id: string, clause: DeepPartial<Clause>) {
    return this.post<Clause>(`/clauses/${id}`, clause);
  }

  deleteClause(id: string) {
    return this.delete<void>(`/clauses/${id}`);
  }
}
