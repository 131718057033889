import { getCookieValue, setCookie } from 'src/utils/cookies';

const MAX_FORCED_RELOADS = 1;
export const TIME_LAPSE = 180; // seconds
export const FORCED_RELOADS_COOKIE_NAME = 'forced-reloads';

/* eslint-disable consistent-return */
export default function handleChunkLoadFailure(err: Error) {
  /* Returns true if a chunk loading error is to be handled here. False
    otherwise.

    Will only allow one forced reload every 3 mins;
  */

  if (!err.message.match(/Loading chunk \d* failed/)) {
    return false;
  }

  const value = parseInt(getCookieValue(FORCED_RELOADS_COOKIE_NAME) || '0', 10);

  if (value >= MAX_FORCED_RELOADS) {
    // eslint-disable-next-line no-console
    console.warn('MAX RELOADS REACHED');
    return false;
  }

  setCookie(FORCED_RELOADS_COOKIE_NAME, value + 1, TIME_LAPSE);
  window.DD_RUM?.addAction('Reloading due to chunk loading failure');
  window.location.reload();

  return true;
}
