import lodashCloneDeep from 'lodash/cloneDeep';
import { isObservable, toJS } from 'mobx';

/* A safe but quick&dirty way to clone objects that can be
 * either regular objects, mobx observables or immutablejs objects.
 *
 * The later two are obviously the annoying ones. Calling
 * toJS() blindly on immutableJS objects breaks them, and
 * calling cloneDeep() on observables breaks those. The
 * other annoying part is that either to them can
 * be present within a regular js object.
 *
 * Hence, this.
 */
type ObjectType = {
  constructor: {
    new (...args: unknown[]): Record<string, unknown>;
  };
  [key: string]: unknown;
};

export default function cloneDeep<T = unknown>(v: T = {} as T): T {
  const recurse = <V = unknown>(_v: V): V => {
    if (typeof _v !== 'object' || _v === null) {
      return _v;
    }

    if (isObservable(_v)) {
      return toJS(_v);
    }
    if (typeof (_v as Record<string, unknown>).toJS === 'function') {
      // TODO not a great way to identify immutablejs objects
      return lodashCloneDeep(_v);
    }
    const _c =
      typeof (_v as Record<string, unknown>).constructor === 'function'
        ? new (_v as unknown as ObjectType).constructor()
        : {};
    Object.getOwnPropertyNames(_v).forEach((propName) => {
      _c[propName] = recurse((_v as Record<string, unknown>)[propName]);
    });
    return _c as V;
  };

  return lodashCloneDeep(recurse<T>(v));
}
