import BaseApi from '../base-api';
import invariant from 'invariant';
import type {
  ContactsAutocompleteRequest,
  ContactsAutocompleteResponse,
  UserContact,
} from 'src/types/proto/auth';
import type {
  ContactsImportGoogleRequest,
  ContactsImportGoogleResponse,
  GetContactsRequest,
  GetContactsResponse,
} from 'src/types/proto/services/contact_public_service';
import type { DeepPartial } from 'src/types/utils';

type SearchOptions = Pick<GetContactsRequest, 'cursor' | 'limit'> & {
  orderField?: string;
  orderDir?: string;
};

export default class Contacts extends BaseApi {
  autocomplete = (request: DeepPartial<ContactsAutocompleteRequest>) => {
    return this.post<ContactsAutocompleteResponse>(
      '/contacts/autocomplete',
      request
    );
  };

  search = (q: string, options: SearchOptions = {}) => {
    const {
      orderField = 'created_at',
      orderDir = 'desc',
      cursor,
      limit,
    } = options;
    return this.get<GetContactsResponse>('/contacts', {
      params: {
        q,
        cursor,
        limit,
        order: `${orderField},${orderDir}`,
      },
    });
  };

  create = (data: DeepPartial<UserContact>) => {
    return this.post<UserContact>('/contacts', data);
  };

  save = (data: DeepPartial<UserContact>) => {
    invariant(data?.id, 'User contact must have id to be saved.');
    return this.post<UserContact>(`/contacts/${data.id}`, data);
  };

  deleteMulti = (ids: string[]) => {
    return this.post<void>('/contacts/delete', {
      ids,
    });
  };

  importGoogle = ({ tokenId }: ContactsImportGoogleRequest) => {
    return this.post<ContactsImportGoogleResponse>(
      `/contacts/import/google/${tokenId}/`
    );
  };
}
