import { INDIVIDUAL, UNKNOWN } from 'src/models/auth/entity-types';
import {
  LegalNameOptions,
  type LegalNameValues,
} from 'src/models/fields/render-options';
import { type Contact } from 'src/types/proto/contacts';
import getFullName from 'src/utils/get-full-name';

type Group = {
  singleName?: string;
  names?: string[];
  entityName?: string;
  entityType?: string;
};

export const BROKERAGE_FIELDS = [
  'reAgent.companyName',
  'reAgent.companyAddress',
  'reAgent.companyWebsite',
  'reAgent.companyPhoneNumber',
  'reAgent.companyLicenseNumber',
];

export const getLegalName = (contact: Contact, format?: LegalNameValues) => {
  const { firstName, lastName, title, entityName } = contact;
  const entityType = contact.entityType || UNKNOWN;
  const fullName = getFullName({
    firstName,
    lastName,
  });
  if (!entityType || [INDIVIDUAL, UNKNOWN].includes(entityType)) {
    return fullName;
  }
  const nameAndTitle = [fullName, title].filter((p) => p).join(', ');
  if (
    format === LegalNameOptions.LEGAL_NAME_INCLUDE_ENTITY &&
    contact.entityName
  ) {
    return `${entityName} (${nameAndTitle})`;
  }
  if (
    format === LegalNameOptions.LEGAL_NAME_ENTITY_ONLY &&
    contact.entityName
  ) {
    return `${entityName}`;
  }

  return nameAndTitle;
};

export const getJoinedLegalName = (
  contacts: Contact[],
  format?: LegalNameValues
) => {
  if (
    format === LegalNameOptions.LEGAL_NAME_INCLUDE_ENTITY ||
    format === LegalNameOptions.LEGAL_NAME_ENTITY_ONLY
  ) {
    const groups: Group[] = [];
    contacts.forEach((contact) => {
      const { firstName, lastName, title, entityName } = contact;
      const entityType = contact.entityType || UNKNOWN;
      const fullName = getFullName({
        firstName,
        lastName,
      });
      const nameAndTitle = [fullName, title].filter((p) => p).join(', ');

      if (!entityType || [INDIVIDUAL, UNKNOWN].includes(entityType)) {
        groups.push({
          singleName: fullName,
        });
      } else if (!entityName) {
        groups.push({
          singleName: nameAndTitle,
        });
      } else {
        const group = groups.find((g) => {
          return (
            g &&
            g.entityName &&
            g.entityName.toLowerCase().trim() ===
              entityName.toLowerCase().trim() &&
            g.entityType === entityType
          );
        });
        if (group) {
          group.names?.push(nameAndTitle);
        } else {
          groups.push({
            entityName,
            names: [nameAndTitle],
            entityType,
          });
        }
      }
    });
    return groups
      .filter((g) => g)
      .map((g) => {
        if (g.entityName) {
          if (format === LegalNameOptions.LEGAL_NAME_INCLUDE_ENTITY) {
            return `${g.entityName} (${g.names?.join('; ')})`;
          }
          return `${g.entityName}`;
        }
        return g.singleName;
      })
      .join(', ');
  }
  return contacts.map((v) => getLegalName(v)).join(', ');
};
