import Field from './base';
import fieldOutput from './field-output';

export default class Decimal extends Field {
  static outputs = [fieldOutput('as_string', 'string_format', 'string')];

  renderString(value) {
    return value && `${value}`;
  }
}
