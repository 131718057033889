import { SESSION_CAPTURE_ROLES } from './constants';

const BT_FULLSTORY_SCRIPT = `window['_fs_run_in_iframe'] = true;
window['_fs_debug'] = false;
window['_fs_host'] = 'fullstory.com';
window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
window['_fs_org'] = 'K23WH';
window['_fs_namespace'] = 'FullStory';
(function (m, n, e, t, l, o, g, y) {
  if (e in m) {
    if (m.console && m.console.log) {
      m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
    }
    return;
  }
  g = m[e] = function (a, b, s) {
    g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
  };
  g.q = [];
  o = n.createElement(t);
  o.async = 1;
  o.crossOrigin = 'anonymous';
  o.src = 'https://' + _fs_script;
  y = n.getElementsByTagName(t)[0];
  y.parentNode.insertBefore(o, y);
  g.identify = function (i, v, s) {
    g(l, { uid: i }, s);
    if (v) g(l, v, s);
  };
  g.setUserVars = function (v, s) {
    g(l, v, s);
  };
  g.event = function (i, v, s) {
    g('event', { n: i, p: v }, s);
  };
  g.shutdown = function () {
    g('rec', !1);
  };
  g.restart = function () {
    g('rec', !0);
  };
  g.log = function (a, b) {
    g('log', [a, b]);
  };
  g.consent = function (a) {
    g('consent', !arguments.length || a);
  };
  g.identifyAccount = function (i, v) {
    o = 'account';
    v = v || {};
    v.acctId = i;
    g(o, v);
  };
  g.clearUserCookie = function () {};
})(window, document, window['_fs_namespace'], 'script', 'user');`;

export function setupFullstory(user: any = null) {
  console.log('setupFullstory', window.Glide?.user);
  if (
    shouldCaptureSessionFS(
      user?.selectedRoles || window.Glide?.user?.selectedRoles
    )
  ) {
    const fsScript = document.createElement('script');
    fsScript.innerHTML = BT_FULLSTORY_SCRIPT;
    document.head.appendChild(fsScript);
  }
}

function shouldCaptureSessionFS(selectedRoles: string[]): boolean {
  return (selectedRoles || []).some((role: string) =>
    SESSION_CAPTURE_ROLES.includes(role)
  );
}
