import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import set from 'lodash/set';
import snakeCase from 'lodash/snakeCase';
import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  autorun,
} from 'mobx';
import moment from 'moment';
import type AggregateStore from 'src/stores/aggregate-store';
import type { AppStore } from 'src/stores/app-store';
import type TransactionOriginStore from 'src/stores/transaction-store';
import type { UserFeatures } from 'src/types/proto/auth';
import { PermissionEnum } from 'src/types/proto/permissions';
import {
  Transaction as TransactionProto,
  type TransactionCancellationMeta,
  type TransactionMetaGlideFields,
  type TransactionState,
  type Item,
} from 'src/types/proto/transactions';
import { getColor } from 'src/utils/get-color';
import type { GetFetch } from 'src/utils/get-fetch';
import getStateConfig from 'src/utils/get-state-config';
import mockable from 'src/utils/mockable';
import Aggregate, { type AggregateJson } from '../aggregates/aggregate';
import type BoundForm from '../fields/bound-form';
import Apps from './apps';
import { PURCHASE_STATUSES } from './constants';
import type Folder from './items/folder';
import type PropertyInfo from './items/property-info';
import Parties from './parties';
import { LISTING_TEAM_ROLES } from './roles';

export const CATEGORY_ACTIVE = 'active';
export const CATEGORY_CLOSED = 'closed';
export const CATEGORY_ARCHIVED = 'archived';
export const CATEGORY_TEMPLATE = 'template';

export const REVIEW_OUTSTANDING = 'outstanding';
export const REVIEW_COMPLETED = 'completed';

export const ASSIGNED_SELF = 'self';
export const ASSIGNED_OTHERS = 'others';

export const TABLE_VIEW = 'LIST';
export const GRID_VIEW = 'GRID';

export const OPERATION_ARCHIVE = 'archive';
export const OPERATION_UNARCHIVE = 'unarchive';
export const OPERATION_MARK_CLOSED = 'mark_closed';
export const OPERATION_MARK_TEMPLATE = 'mark_template';
export const OPERATION_MARK_UNDER_CONTRACT = 'mark_under_contract';
export const OPERATION_MARK_PRE_OFFER = 'mark_pre_offer';
export const OPERATION_SET_STATUS = 'set_stage';
export const OPERATION_EMAIL_DOCUMENT = 'email_document';
export const OPERATION_APPLY_TEMPLATES = 'apply_templates';
export const OPERATION_SAVE_AS_TEMPLATE = 'save_as_template';
export const OPERATION_CLONE_TRANSACTION = 'clone_transaction';
export const OPERATION_LEAVE_TRANSACTION = 'leave_transaction';
export const OPERATION_CANCEL = 'cancel';
export const OPERATION_APPROVE_CANCELLATION = 'approve_cancellation';
export const OPERATION_REACTIVATE = 'reactivate';
export const OPERATION_COPY_TRANSACTION_EMAIL = 'copy_email';
export const OPERATION_AUTOTAB = 'autotab';
export const OPERATION_AUTOSPLIT = 'autosplit';

export const OPERATION_CREATE_GFP = 'create_gfp';
export const OPERATION_EDIT_INVOICE = 'edit_invoice';
export const OPERATION_EDIT_INVOICE_ADMIN = 'edit_invoice_admin';
export const OPERATION_EDIT = 'edit';
export const OPERATION_LINK_ZF = 'link_zf';
export const TRANSACTION_SALE_SIDE = 'SALE';

export const TRANSACTION_STATES = {
  PRE_OFFER: 'Pre Offer',
  UNDER_CONTRACT: 'Under Contract',
  CLOSED: 'Closed',
  TEMPLATE: 'Template',
};

export const LISTING_STATUSES = [
  'PROSPECT',
  'PREPARING',
  'ACTIVE',
  'UNDER_CONTRACT',
  'CLOSED',
];

export const NEW_PURCHASE_STATUSES = ['PROSPECT', ...PURCHASE_STATUSES];
export const PRE_UNDER_CONTRACT = ['PROSPECT', 'PRE_OFFER'];

export const STATUSES_BY_SIDE = {
  SALE: LISTING_STATUSES,
  PURCHASE: NEW_PURCHASE_STATUSES,
};

export const CANCEL_STATUSES = ['CANCEL_REQUESTED', 'CANCELLED'];

export const STATUS_TEXT = {
  PROSPECT: {
    label: 'Nurturing',
    help: 'Pitching a potential client or opportunity.',
    color: getColor('darker-border-gray-color'),
  },
  PREPARING: {
    label: 'Preparing to List',
    help: 'Preparing the property for sale but the listing is not yet on the market.',
    color: '#BE57C2',
  },
  ACTIVE: {
    label: 'Property Listed',
    help: 'Listing is on the market and/or accepting offers',
    color: '#FB9933',
  },
  PRE_OFFER: {
    label: 'Actively Searching',
    help: 'Offer has not been accepted yet.',
    color: '#FB9933',
  },
  UNDER_CONTRACT: {
    label: 'Under Contract',
    help: 'An offer / contract was accepted but has not closed yet.',
    color: getColor('blue-4'),
  },
  CLOSED: {
    label: 'Closed',
    help: 'The transaction has closed and the property has been transferred to the buyer.',
    color: '#52C41A',
  },
  CANCELLED: {
    label: 'Cancelled',
    help: 'The transaction has been cancelled and is inactive.',
  },
  CANCEL_REQUESTED: {
    label: 'Cancel Requested',
    help: 'The transaction is pending cancellation.',
  },
};

export const CANCELLATION_REASONS = {
  LISTING_CANCELLATION: 'Listing Cancellation',
  BUYER_CONTINGENCY_FAILURE: 'Buyer Contingency',
  SELLER_CONTINGENCY_FAILURE: 'Seller Contingency',
  OTHER: 'Other',
};

const STATE_OPERATIONS: Record<string, string> = {
  [OPERATION_MARK_CLOSED]: 'CLOSED',
  [OPERATION_MARK_UNDER_CONTRACT]: 'UNDER_CONTRACT',
  [OPERATION_MARK_TEMPLATE]: 'TEMPLATE',
  [OPERATION_MARK_PRE_OFFER]: 'PRE_OFFER',
};

export type TransactionJson = Omit<AggregateJson, 'meta'> &
  Required<TransactionProto>;

export type TransactionStore = Omit<
  TransactionOriginStore,
  'fetchDataForAggregate'
> &
  Pick<AggregateStore, 'fetchDataForAggregate'>;

class Transaction<
  TStore extends TransactionStore = TransactionStore,
  TJson extends TransactionJson = TransactionJson
> extends Aggregate<TStore, TJson> {
  public original: unknown;
  public apps!: Apps;

  resolvedItems = ['propertyInfo', 'listingInfo', 'purchaseInfo'];

  parties!: Parties;

  @observable lastVisitedDetailsTabCode?: string;
  static LOCAL_STORAGE_LAST_VISITED_DETAILS_TAB_CODE_ITEM_KEY = 'lvdtc';

  @observable heroCollapsed?: boolean;
  static LOCAL_STORAGE_HERO_COLLAPSED_KEY = 'hc';

  constructor(store: TStore, json: TJson) {
    // @ts-expect-error Remove the super() or add store and json to the super will crash
    super();
    makeObservable(this);
    this.store = store;
    this.updateFromJson(json);
  }

  @computed
  get isTemplate() {
    return this.state === 'TEMPLATE';
  }

  @override
  get meta() {
    return this.data.meta;
  }

  @computed
  get cancellationMeta(): Partial<TransactionCancellationMeta> {
    return (this.data.meta && this.data.meta.cancellationMeta) || {};
  }

  @computed
  get cancellationReasonLabel() {
    return CANCELLATION_REASONS[
      (this.cancellationMeta
        ?.reason as unknown as keyof typeof CANCELLATION_REASONS) ?? 'OTHER'
    ];
  }

  @computed
  get key() {
    return `transaction-item-${this.id}`;
  }

  @override
  get id() {
    return this.data.id;
  }

  @computed
  get creatorId() {
    return this.meta.creatorId;
  }

  @computed
  get primaryAgentEmail() {
    return this.parties?.primaryAgent?.email;
  }

  @override
  get title() {
    return this.meta.title;
  }

  @computed
  get emailAddress() {
    return this.meta.emailAddress;
  }

  get permissions() {
    return this.data.permissions;
  }

  @computed
  get forwardEmailAddresses() {
    return this.meta.forwardEmailAddresses || [];
  }

  @computed
  get address() {
    return this.meta.address;
  }

  @computed
  get hasAddress() {
    return Boolean(this.address?.id);
  }

  @computed
  get hasSecondaryProperty() {
    return this.getProperties().some((p) => p.isSecondary && p.address);
  }

  @computed
  get dateFields() {
    // @ts-expect-error FIXME: The kindItem property definition is not exist in TransactionMeta
    return this.meta.dateFields;
  }

  @computed
  get addressLine1() {
    if (!this.address) {
      return '';
    }
    return `${this.address.street} ${this.address.unit || ''}`.trim();
  }

  @computed
  get addressLine2() {
    if (!this.address) {
      return '';
    }

    return `${this.address.city}, ${this.address.state} ${this.address.zipCode}`.trim();
  }

  @computed
  get fullAddress() {
    if (!this.address) {
      return '';
    }
    return `${this.addressLine1}, ${this.addressLine2}`.trim();
  }

  getAddressForTd(td?: { folder: Folder }): unknown;
  getAddressForTd() {
    return this.fullAddress;
  }

  @computed
  get state() {
    return this.meta.state;
  }

  @computed
  get status() {
    return this.meta.state;
  }

  @computed
  get isPromoted() {
    return this.meta.state === 'UNDER_CONTRACT' || this.meta.state === 'CLOSED';
  }

  @computed
  get isCompassTransaction() {
    return this.meta.transactionOrigin === 'COMPASS';
  }

  @computed
  get is3PTC() {
    return this.isCompassTransaction && this.isTC;
  }

  @computed
  get isTC() {
    return this.parties?.getByUserId(this.userId)?.isTC;
  }

  @computed
  get statusLabel() {
    return STATUS_TEXT[this.meta.state as keyof typeof STATUS_TEXT].label;
  }

  @computed
  get nonCancelledStatus() {
    // FIXME: The condition seems always be false
    if (!this.isCancelled && !this.isCancelRequested) {
      return this.status;
    }
    return this.cancellationMeta?.prevStatus;
  }

  @computed
  get archived() {
    return this.meta.archived;
  }

  @computed
  get voided() {
    return this.meta.voided;
  }

  @computed
  get reState() {
    return this.meta.reState;
  }

  @computed
  get tds() {
    return (
      this.meta.tds.map((i) => i.transactionDocument) ||
      this.store.getItems(this.id, 'TRANSACTION_DOCUMENT')
    );
  }

  @computed
  get deactivatedForms() {
    return this.tds.reduce(
      (prev, curr) =>
        curr!.isDeactivatedForm &&
        !curr!.archived &&
        // @ts-expect-error FIXME: The kindItem property definition is not exist in TransactionDocument(this.tds)
        (curr!.folderKind || curr!.kindItem?.folderKind) !== 'TRASH'
          ? prev + 1
          : prev,
      0
    );
  }

  @computed
  get hasDeactivatedForms() {
    return this.deactivatedForms > 0;
  }

  @computed
  get stateConfig() {
    const state =
      this.reState ||
      this.address?.state ||
      get(this.store.parent, 'account.user.accountState') ||
      'CA';
    return getStateConfig(state) || {};
  }

  @computed
  get archivedOrVoided() {
    return this.voided || this.archived;
  }

  @computed
  get propertyInfoId() {
    return this.meta.propertyInfoId;
  }

  @computed
  get propertyInfo(): PropertyInfo | {} {
    return this.store.itemsById.get(this.propertyInfoId) || {};
  }

  @computed
  get listingInfoId() {
    return this.meta.listingInfoId;
  }

  @computed
  get listingInfo(): Partial<Item> {
    return this.store.itemsById.get(this.listingInfoId) || {};
  }

  @computed
  get purchaseInfoId() {
    return this.meta.purchaseInfoId;
  }

  @computed
  get purchaseInfo() {
    return this.store.itemsById.get(this.purchaseInfoId) || {};
  }

  @computed
  get libraryUuids() {
    // @ts-expect-error FIXME: The kindItem property definition is not exist in TransactionMeta
    return this.meta.libraryUuids;
  }

  @computed
  get ownerId() {
    return this.meta.ownerId;
  }

  @computed
  get owner() {
    return this.store.itemsById.get(this.ownerId);
  }

  @computed
  get checklistBoardId() {
    return this.meta.checklistBoardId;
  }

  @computed
  get ticketBoardId() {
    return this.meta.ticketBoardId;
  }

  @computed
  get ticketBoard() {
    return this.store.itemsById.get(this.ticketBoardId);
  }

  @computed
  get coverPhotoId() {
    return this.meta.coverPhotoId;
  }

  @computed
  get coverPhotoUrl() {
    return this.meta.coverPhotoUrl;
  }

  @computed
  get coverPhotoUrlSm() {
    return this.meta.coverPhotoUrlSm;
  }

  @computed
  get hasCustomCoverPhoto() {
    return !!this.meta.customCoverPhotoUrl;
  }

  @computed
  get brokerLogoUrl() {
    return get(this.parties.primaryAgent, 'contact.reAgent.companyLogoUrl');
  }

  @computed
  get discPacketId() {
    return (
      (this.apps.sellerDisclosuresApp &&
        this.apps.sellerDisclosuresApp.typeItem.discPacketId) ||
      this.meta.discPacketId
    );
  }

  @computed
  get glideFields(): Partial<TransactionMetaGlideFields> {
    return this.meta.glideFields || {};
  }

  @computed
  get isTest() {
    return this.glideFields.isTest;
  }

  getFeatures = mockable<Partial<UserFeatures>>('features', 'features', () => {
    return this.meta.features || {};
  });

  @computed
  get features() {
    return this.getFeatures();
  }

  @computed
  get isTms() {
    return this.isRealTms;
  }

  @computed
  get isRealTms() {
    return Boolean(this.stateConfig.tms) || this.isTemplate;
  }

  @computed
  get isOrgTms() {
    return this.isRealTms && !!this.org;
  }

  isFeatureDisabled(feature: keyof UserFeatures) {
    const disabledFeatures = this.features.disabledFeatures;
    const formattedFeature = snakeCase(feature);
    return (
      disabledFeatures &&
      Object.keys(disabledFeatures).some((key) => {
        return disabledFeatures[key].features?.includes(formattedFeature);
      })
    );
  }

  @computed
  get zfLink() {
    return this.meta.zfLink || {};
  }

  @computed
  get isZfLinked() {
    return !!get(this.meta, 'zfLink.txnId');
  }

  @computed
  get zfTxnName() {
    return get(this.meta, 'zfLink.txnName');
  }

  @computed
  get canArchive() {
    return !this.archivedOrVoided;
  }

  @computed
  get canUnarchive() {
    return this.archived && !this.voided;
  }

  @computed
  get canSetStatus() {
    return (
      !this.archivedOrVoided && !this.isCancelled && !this.isCancelRequested
    );
  }

  @computed
  get isCancelled() {
    return this.state === 'CANCELLED';
  }

  @computed
  get isCancelRequested() {
    return this.state === 'CANCEL_REQUESTED';
  }

  @computed
  get userId() {
    return get(this.store.parent, 'account.user.id');
  }

  @computed
  get teamIds() {
    return this.meta.teamIds || [];
  }

  canMarkState(state: TransactionState) {
    return this.state !== state && !this.archivedOrVoided;
  }

  @computed
  get canVoid() {
    return (
      this.store.parent.account.isAdmin &&
      this.glideFields.isFullService &&
      !this.voided
    );
  }

  @computed
  get canUnvoid() {
    return (
      this.store.parent.account.isAdmin &&
      this.glideFields.isFullService &&
      this.voided
    );
  }

  @computed
  get category() {
    if (this.archived) {
      return CATEGORY_ARCHIVED;
    }
    if (this.state === 'CLOSED') {
      return CATEGORY_CLOSED;
    }
    if (['UNDER_CONTRACT', 'PRE_OFFER'].includes(this.state)) {
      return CATEGORY_ACTIVE;
    }
    if (this.isTemplate) {
      return CATEGORY_TEMPLATE;
    }
    return null;
  }

  @computed
  get side() {
    return this.meta.side;
  }

  @computed
  get isLease() {
    return Boolean(this.meta.isLease);
  }

  @computed
  get isPurchase() {
    return this.side === 'PURCHASE';
  }

  @computed
  get isSale() {
    return this.side === 'SALE';
  }

  @computed
  get isListing() {
    return this.isSale;
  }

  @computed
  get isReferral() {
    return this.data.meta.isReferral;
  }

  @computed
  get sideLabel() {
    if (this.isLease) {
      return this.isSale ? 'Lease Listing' : 'Lease';
    }
    return this.isSale ? 'Listing' : 'Purchase';
  }

  @computed
  get otherSideLabel() {
    if (this.isLease) {
      return this.isSale ? 'Lease' : 'Lease Listing';
    }
    return this.isSale ? 'Purchase' : 'Listing';
  }

  @computed
  get partySideLabel() {
    if (this.isLease) {
      return this.isSale ? 'Landlord' : 'Tenant';
    }
    return this.isSale ? 'Listing' : 'Buyer';
  }

  @computed
  get otherPartySideLabel() {
    if (this.isLease) {
      return this.isSale ? 'Tenant' : 'Landlord';
    }
    return this.isSale ? 'Buyer' : 'Listing';
  }

  // Data store version for this instance. Gets updated by the backend
  // on changes in data.
  // Can be `undefined` if this instance is not based on backend data.
  @computed
  get vers() {
    return this.data.vers;
  }

  @computed
  get canEdit() {
    return !!this.parties.me;
  }

  @computed
  get orgId() {
    return this.meta.orgId;
  }

  @computed
  get org() {
    return this.meta.org;
  }

  @computed
  get officeId() {
    return this.meta.officeId;
  }

  @computed
  get reviewState() {
    return this.meta.reviewState || 'PENDING';
  }

  @computed
  get isActive() {
    return this.category === CATEGORY_ACTIVE;
  }

  @computed
  get reviewStateUser() {
    return this.parties.getByUserId(this.meta.reviewStateUserId);
  }

  @computed
  get reviewStateChangedAt() {
    window.moment = moment;
    const m = moment(+this.meta.reviewStateChangedAt);
    return !!m && m.isValid() ? m : undefined;
  }

  @computed
  get pendingCliCount() {
    return this.meta.pendingCliCount || 0;
  }

  @computed
  get createdAt() {
    return this.data.createdAt;
  }

  @computed
  get folders() {
    return this.getFolders();
  }

  @computed
  get mainFolders() {
    return this.getFolders(true);
  }

  getFolders(onlyMain?: boolean): Folder[];
  getFolders(): Folder[] {
    return this.store.getItems(this.id, 'FOLDER') as Folder[];
  }

  getProperties(): PropertyInfo[] {
    return (this.store.getItems(this.id, 'PROPERTY_INFO') ||
      []) as PropertyInfo[];
  }

  hasProperties() {
    return (this.getProperties() || []).some(Boolean);
  }

  hasValidProperties() {
    // Filter out empty placeholder property
    return this.getProperties().some((property) => property.address);
  }

  @computed
  get createdAtNumber() {
    return Number(this.createdAt);
  }

  @computed
  get pipelineDate() {
    return this.meta.pipelineDate ? moment(this.meta.pipelineDate, 'l') : null;
  }

  getPipelineDateFieldName(short = false) {
    if (
      this.isListing &&
      !['CLOSED', 'UNDER_CONTRACT'].includes(this.nonCancelledStatus!)
    ) {
      return short ? 'Expiration' : 'Listing Expiration';
    }

    return short ? 'CoE' : 'Close of Escrow';
  }

  @computed
  get lastVisitedDetailsTab() {
    return (
      this.lastVisitedDetailsTabCode ||
      this.getLocalStorageItem(
        Transaction.LOCAL_STORAGE_LAST_VISITED_DETAILS_TAB_CODE_ITEM_KEY
      )
    );
  }

  @action
  setLastVisitedDetailsTab(tab: string) {
    this.lastVisitedDetailsTabCode = tab;
    this.setLocalStorageItem(
      Transaction.LOCAL_STORAGE_LAST_VISITED_DETAILS_TAB_CODE_ITEM_KEY,
      tab
    );
  }

  @computed
  get heroCollapsedState() {
    return (
      this.heroCollapsed ||
      this.getLocalStorageItem(Transaction.LOCAL_STORAGE_HERO_COLLAPSED_KEY)
    );
  }

  @action
  setHeroCollapsedState(isCollapsed: boolean) {
    this.heroCollapsed = isCollapsed;
    this.setLocalStorageItem(
      Transaction.LOCAL_STORAGE_HERO_COLLAPSED_KEY,
      isCollapsed
    );
  }

  getRoute() {
    return {
      name: 'transactions.transaction',
      params: {
        transactionId: this.id,
      },
    };
  }

  @action
  setTitle(title: string) {
    this.meta.title = title;
  }

  @override
  updateFromJson(data: TJson) {
    this.data = data;
    this.original = undefined;

    if (!this.parties) {
      this.parties = new Parties(this.store, this);
    } else {
      this.parties.updateFromJson(this);
    }

    if (!this.apps) {
      this.apps = new Apps(this.store, this);
    }
  }

  @computed
  get showDisclosures() {
    return this.stateConfig.seller_disclosures;
  }

  get localStorageData() {
    return this.store.getLocalStorageData(this.id);
  }
  getLocalStorageItem = (itemPath: string) =>
    get(this.localStorageData, itemPath);
  setLocalStorageData = (data: unknown) =>
    this.store.setLocalStorageData(this.id, data);
  setLocalStorageItem = (itemPath: string, data: unknown) => {
    const newData = {
      ...this.localStorageData,
    };
    set(newData, itemPath, data);
    this.store.setLocalStorageData(this.id, newData);
  };

  removeLocalStorageData = () => this.store.removeLocalStorageData(this.id);

  userHasPerm = (perm: PermissionEnum) => {
    const { account } = this.store.parent;
    return account.hasPermissionForTeamIds(perm, this.teamIds);
  };

  can(op: string) {
    const { account, features } = this.store.parent;
    const { user } = account;
    const userIsTransactionAdmin = this.permissions.includes(
      PermissionEnum.ACT_AS_ADMIN
    );

    if (this.store.parent.account.accessMode === 'CLIENT') {
      return false;
    }

    if (op === OPERATION_LEAVE_TRANSACTION) {
      const thisSideTeamParties =
        this.parties.thisSideTeamPartiesForTxmpPartyModal;
      return (
        this.parties.me &&
        thisSideTeamParties.filter(
          (p) => p.userId !== this.store.parent.account.user.id
        ).length
      );
    }

    if (op === OPERATION_EMAIL_DOCUMENT) {
      const amPartyAndThisSide =
        this.parties.me && this.parties.me.isThisSideTeam;
      return this.isRealTms && (amPartyAndThisSide || userIsTransactionAdmin);
    }

    if (op === OPERATION_ARCHIVE) {
      return this.canArchive;
    }
    if (op === OPERATION_UNARCHIVE) {
      return this.canUnarchive;
    }
    if (STATE_OPERATIONS[op]) {
      return this.canMarkState(
        STATE_OPERATIONS[op] as unknown as TransactionState
      );
    }
    if (op === OPERATION_CREATE_GFP) {
      return (
        this.side === TRANSACTION_SALE_SIDE &&
        (!this.parties.me ||
          intersection(this.parties.me.roles, LISTING_TEAM_ROLES).length > 0 ||
          userIsTransactionAdmin)
      );
    }
    if (op === OPERATION_EDIT) {
      return this.canEdit;
    }
    if (op === OPERATION_EDIT_INVOICE) {
      return user.isAdmin;
    }
    if (op === OPERATION_EDIT_INVOICE_ADMIN) {
      return user.isAdmin;
    }
    if (op === OPERATION_LINK_ZF) {
      return this.stateConfig.zipform && !this.isTms;
    }
    if (op === OPERATION_SET_STATUS) {
      return this.canSetStatus;
    }
    if (op === OPERATION_CANCEL) {
      return this.canSetStatus && this.isRealTms;
    }
    if (op === OPERATION_APPROVE_CANCELLATION) {
      return (
        this.isCancelRequested &&
        this.userHasPerm('APPROVE_CANCELLATIONS') &&
        this.isOrgTms
      );
    }
    if (op === OPERATION_REACTIVATE) {
      return (
        (this.isCancelled || this.isCancelRequested) &&
        (this.userHasPerm('APPROVE_CANCELLATIONS') || !this.org)
      );
    }
    if (op === OPERATION_APPLY_TEMPLATES) {
      return !this.archived && this.isRealTms;
    }
    if (op === OPERATION_SAVE_AS_TEMPLATE) {
      return !this.archived && this.isRealTms;
    }
    if (op === OPERATION_CLONE_TRANSACTION) {
      return (
        !this.archived &&
        this.isRealTms &&
        this.getProperties().filter((pi) => pi.address).length < 2
      );
    }
    if (op === OPERATION_AUTOTAB) {
      return (
        this.features.autotabbing ||
        (this.parties.me && !this.parties.me.isAgent) ||
        userIsTransactionAdmin
      );
    }
    if (op === OPERATION_AUTOSPLIT) {
      return (
        this.features.autosplitting ||
        (this.parties.me && !this.parties.me.isAgent) ||
        !features.variation('gate_autosplitting') ||
        userIsTransactionAdmin
      );
    }

    return false;
  }

  isActionable() {
    const allOps = [
      OPERATION_ARCHIVE,
      OPERATION_UNARCHIVE,
      OPERATION_MARK_UNDER_CONTRACT,
      OPERATION_MARK_CLOSED,
      OPERATION_SET_STATUS,
    ];
    return findIndex(allOps, (op) => this.can(op)) !== -1;
  }

  get isInCalifornia() {
    return !!this.address && this.address.state === 'CA';
  }

  get isZfEnabled() {
    return this.isInCalifornia && !this.isTms;
  }

  get fieldsOverrides() {
    return this.reState
      ? {
          'property/address': {
            state: this.reState,
          },
        }
      : undefined;
  }

  emit = (...args: unknown[]) => this.store.emit(this.id, ...args);

  validateFields = (boundForm: BoundForm, fieldIds: string[] = []) => {
    return new Promise((resolve, reject) => {
      const disposer = autorun(
        () => {
          const boundFields = fieldIds.map((fieldId) =>
            boundForm.getBoundFieldById(fieldId)
          );
          const hasError = boundFields.some(
            (boundField) => boundField?.validation?.status === 'error'
          );
          if (hasError) {
            disposer();
            reject(Error('Some fields are invalid'));
          }
          const allSuccess = boundFields.every(
            (boundField) =>
              !boundField?.validation ||
              boundField.validation.status === 'success'
          );
          if (allSuccess) {
            disposer();
            resolve(true);
          }
        },
        // We set the delay to
        // 1. simply make the onblur event triggered before validation
        // 2. make sure we can read `disposer`
        { delay: 10 }
      );
    });
  };
}

async function loadAccountModel(store: AppStore) {
  const user = await store.account.fetchMe();

  return {
    user,
    zfToken: store.account.user?.zfToken, // deprecated
    zfTokens: store.account.user?.zfTokens,
    zfExternalId: store.account.user?.zfExternalId,
  };
}

const { TRANSACTION_NAMESPACE: NAMESPACE } = window.Glide.CONSTANTS;

export interface LoadTxnDetailsModelState {
  params: {
    transactionId: string;
    expand?: unknown;
  };
}

export interface LoadTxnDetailsModelProps {
  namespaces: unknown[];
  formNamespace: unknown;
}

interface GetFetchTransactionReformFormsParams {
  transactionId: string;
  namespaces: unknown[];
}

export async function loadTxnDetailsModel(
  store: AppStore,
  state: LoadTxnDetailsModelState,
  otherProps: LoadTxnDetailsModelProps
) {
  const { namespaces, formNamespace } = otherProps || {};
  const { transactionId, expand } = state.params;
  const [accountModel, transactionReformForms] = await Promise.all([
    loadAccountModel(store),
    (
      store.transactionFields
        .getFetchTransactionReformForms as unknown as GetFetch<
        GetFetchTransactionReformFormsParams,
        Record<string, BoundForm>
      >
    ).getOrFetch({
      transactionId,
      namespaces: namespaces ?? [NAMESPACE],
    }),
  ]);
  return {
    accountModel,
    boundForm: transactionReformForms[formNamespace ?? NAMESPACE],
    expand,
  };
}

export default Transaction;
