

import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppIcon from 'src/components/common/app-icon';
import iconSkipped from 'src/images/icons/status-circle-arrow-right.svg';
import iconCheck from 'src/images/icons/status-circle-check.svg';
import iconComplete from 'src/images/icons/status-circle-complete.svg';
import iconInProgress from 'src/images/icons/status-circle-in-progress.svg';

const clsPrefix = 'app-status-circle';

const ICON_CONFIGS = {
  LOADING: {
    type: 'ant',
    name: 'loading',
    size: 30,
  },
  IN_PROGRESS: {
    type: 'svg',
    svg: iconInProgress,
    size: 30,
  },
  ARCHIVED: {
    type: 'antd',
    name: 'delete',
  },
  CLOSED: {
    type: 'svg',
    svg: iconComplete,
    size: 30,
  },
  SKIPPED: {
    type: 'svg',
    svg: iconSkipped,
    size: 30,
  },
  ERROR: {
    type: 'antd',
    name: 'close-circle',
    size: 26,
  },
  NOT_STARTED: {
    type: 'antd',
    name: 'minus-circle',
    size: 26,
  },
  DEFAULT: {
    type: 'svg',
    svg: iconCheck,
    size: 30,
  },
};

const StatusCircle = observer((props) => {
  const { status, className, highlight, loading, ...otherProps } = props;
  const iconProps =
    (loading ? ICON_CONFIGS.LOADING : ICON_CONFIGS[status]) ||
    ICON_CONFIGS.DEFAULT;

  return (
    <AppIcon
      {...iconProps}
      {...otherProps}
      className={classNames(clsPrefix, className, {
        [`${clsPrefix}--open`]: status === 'OPEN',
        [`${clsPrefix}--closed`]: status === 'CLOSED',
        [`${clsPrefix}--in-progress`]: status === 'IN_PROGRESS',
        [`${clsPrefix}--archived`]: status === 'ARCHIVED',
        [`${clsPrefix}--skipped`]: status === 'SKIPPED',
        [`${clsPrefix}--error`]: status === 'ERROR',
        [`${clsPrefix}--highlight`]: highlight,
        [`${clsPrefix}--loading`]: loading,
      })}
      alt="status circle"
    />
  );
});

StatusCircle.propTypes = {
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  highlight: PropTypes.bool,
};

export default StatusCircle;
