import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import snakeCaseKeys from 'snakecase-keys';

const getText = (text, transformText) => {
  const t = (text || '').trim();
  return transformText ? transformText(t) : t;
};

export const getAddressLabelLine1 = (address, transformText) => {
  if (!address) {
    return '';
  }
  const street = getText(address.street, transformText);
  const unit = getText(address.unit, transformText);
  return `${street} ${unit}`.trim();
};

export const getAddressLabelLine2 = (address, transformText) => {
  if (!address) {
    return '';
  }
  const city = getText(address.city, transformText);
  const state = getText(address.state, transformText);
  const zipCode = getText(address.zip_code || address.zipCode, transformText);
  return `${city ? `${city}, ` : ''}${state || ''} ${zipCode || ''}`.trim();
};

export const getAddressLabel = (address, transformText) => {
  const line1 = getAddressLabelLine1(address, transformText);
  const line2 = getAddressLabelLine2(address, transformText);
  return line1 && line2 ? `${line1}, ${line2}` : line1 || line2;
};

export default function AddressLabel({
  address,
  style,
  transformText,
  className,
  oneLiner,
}) {
  // check is snake case
  if (!address) {
    return null;
  }
  if (!address.zip_code) {
    address = snakeCaseKeys(address); // eslint-disable-line no-param-reassign
  }

  if (address.street && address.city && address.state && address.zip_code) {
    return (
      <span
        className={classNames('app-address-label', className)}
        style={style}
      >
        {oneLiner ? (
          getAddressLabel(address, transformText)
        ) : (
          <Fragment>
            {getAddressLabelLine1(address, transformText)}
            <br />
            {getAddressLabelLine2(address, transformText)}
          </Fragment>
        )}
      </span>
    );
  }
  return null;
}

AddressLabel.propTypes = {
  address: PropTypes.object,
  style: PropTypes.any,
  className: PropTypes.string,
  transformText: PropTypes.func,
  oneLiner: PropTypes.bool,
};

export const story = (book) =>
  book.of.add(
    'default',
    book.withInfo()(() => (
      <AddressLabel
        address={{
          street: '1 California St',
          unit: '2725',
          city: 'San Francisco',
          zip_code: '94108',
          state: 'CA',
        }}
      />
    ))
  );
