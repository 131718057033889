// Adapted from https://github.com/marlun78/number-to-words/blob/master/numberToWords.js

const TEN = 10;
const ONE_HUNDRED = 100;
const ONE_THOUSAND = 1000;
const ONE_MILLION = 1000000;
const ONE_BILLION = 1000000000; //         1.000.000.000 (9)
const ONE_TRILLION = 1000000000000; //     1.000.000.000.000 (12)
const ONE_QUADRILLION = 1000000000000000; // 1.000.000.000.000.000 (15)
const MAX_SAFE_INTEGER = 9007199254740992; // 9.007.199.254.740.992 (15)

const LESS_THAN_TWENTY = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
];

const TENTHS_LESS_THAN_HUNDRED = [
  'zero',
  'ten',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
];

function isSafeNumber(value: unknown) {
  return typeof value === 'number' && Math.abs(value) <= MAX_SAFE_INTEGER;
}

function generateWords(number: number, words?: string[]): string {
  let remainder: number | undefined;
  let word: string | undefined;
  let number_ = number;

  // We’re done
  if (number === 0) {
    return !words ? 'zero' : words.join(' ').replace(/,$/, '');
  }
  // First run
  const words_ = words || [];
  // If negative, prepend “minus”
  if (number < 0) {
    words_.push('minus');
    number_ = Math.abs(number);
  }

  if (number_ < 20) {
    remainder = 0;
    word = LESS_THAN_TWENTY[number_];
  } else if (number_ < ONE_HUNDRED) {
    remainder = number_ % TEN;
    word = TENTHS_LESS_THAN_HUNDRED[Math.floor(number_ / TEN)];
  } else if (number_ < ONE_THOUSAND) {
    remainder = number_ % ONE_HUNDRED;
    word = `${generateWords(Math.floor(number_ / ONE_HUNDRED))} hundred`;
  } else if (number_ < ONE_MILLION) {
    remainder = number_ % ONE_THOUSAND;
    word = `${generateWords(Math.floor(number_ / ONE_THOUSAND))} thousand`;
  } else if (number_ < ONE_BILLION) {
    remainder = number_ % ONE_MILLION;
    word = `${generateWords(Math.floor(number_ / ONE_MILLION))} million`;
  } else if (number_ < ONE_TRILLION) {
    remainder = number_ % ONE_BILLION;
    word = `${generateWords(Math.floor(number_ / ONE_BILLION))} billion`;
  } else if (number_ < ONE_QUADRILLION) {
    remainder = number_ % ONE_TRILLION;
    word = `${generateWords(Math.floor(number_ / ONE_TRILLION))} trillion`;
  } else if (number_ <= MAX_SAFE_INTEGER) {
    remainder = number_ % ONE_QUADRILLION;
    word = `${generateWords(
      Math.floor(number_ / ONE_QUADRILLION)
    )} quadrillion`;
  }
  if (number_ >= ONE_THOUSAND && remainder && remainder >= ONE_HUNDRED) {
    word += ',';
  }

  words_.push(word!);
  return generateWords(remainder!, words_);
}

export default function toWords(number: number | string): string {
  const num = parseInt(number as string, 10);

  if (!Number.isFinite(num)) {
    throw new TypeError(`Not a finite number: ${number} (${typeof number})`);
  }
  if (!isSafeNumber(num)) {
    throw new RangeError(
      "Input is not a safe number, it's either too large or too small."
    );
  }
  return generateWords(num);
}
