import get from 'lodash/get';
import merge from 'lodash/merge';
import { getFieldInstance } from '../fields';
import OutputHandler from './base';

export default class Identity extends OutputHandler {
  getParams(outctx) {
    const fieldParams = get(outctx, 'bindings.this.field.params', {});
    const outputParams = get(outctx.output, 'params', {});

    return merge({}, fieldParams, outputParams);
  }

  inputKind(outctx) {
    return get(outctx, 'bindings.this.field.kind', null);
  }

  pdfValue(outctx) {
    const params = this.getParams(outctx);
    const kind = this.inputKind(outctx);
    const fieldInstance = getFieldInstance(kind, params, outctx.renderOptions);
    const value = outctx.get('this');
    return fieldInstance.renderValue(value);
  }
}
