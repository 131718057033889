import { computed } from 'mobx';
import {
  type FormConfigItemNumeric,
  FormConfigItemNumericNumericKind,
} from 'src/types/proto/reform';
import BaseItemDelegate from './base';

export default class NumericItemDelegate extends BaseItemDelegate<FormConfigItemNumeric> {
  @computed
  get hasMin() {
    return this.params.hasMin;
  }

  @computed
  get hasMax() {
    return this.params.hasMax;
  }

  @computed
  get minValue() {
    const minVal = this.params.minValue ?? 0;
    return this.hasMin ? minVal : undefined;
  }

  @computed
  get maxValue() {
    const maxVal = this.params.maxValue ?? 0;
    return this.hasMax ? maxVal : undefined;
  }

  @computed
  get kind() {
    return this.params.kind ?? FormConfigItemNumericNumericKind.INTEGER;
  }
}
