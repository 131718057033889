const getStateConfig = (state: string) => {
  const { STATE_CONFIG } = window.Glide.CONSTANTS;

  return {
    ...STATE_CONFIG.DEFAULT,
    ...(STATE_CONFIG[state] || {}),
  };
};

export default getStateConfig;
