type CancelerType = { promise: Promise<unknown>; cancel: () => void };
export type ParentType = {
  _cancelables?: CancelerType[];
  [key: string]: any;
};

export function cancelable(
  promise: Promise<unknown>,
  parent?: ParentType
): CancelerType {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => {
        if (hasCanceled) {
          reject(new Error('promise has been canceled'));
        } else {
          resolve(val);
        }
        return val;
      },
      (error) => {
        if (hasCanceled) {
          reject(new Error('promise has been canceled'));
        } else {
          reject(error);
        }
        return null;
      }
    );
  });

  const canceler = {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };

  if (parent) {
    parent._cancelables = parent._cancelables || [];
    parent._cancelables.push(canceler);
  }
  return canceler;
}

export function clearCancelables(parent?: ParentType) {
  if (parent && parent._cancelables) {
    parent._cancelables.forEach((c) => c.cancel());
    delete parent._cancelables;
  }
}
