

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Form } from '@ant-design/compatible';

import { observer } from 'mobx-react';

const clsPrefix = 'app-fake-form';

function FakeFormItem({ children, name, ...otherProps }) {
  let childNode = null;
  if (React.isValidElement(children) && name) {
    const childProps = {
      id: name,
    };
    childNode = React.cloneElement(children, childProps);
  } else {
    childNode = children;
  }

  return <Form.Item {...otherProps}>{childNode}</Form.Item>;
}
FakeFormItem.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
};

@observer
export default class FakeForm extends Component {
  // sometimes you want the UI of the ant forms,
  // (in particular the warning/error messages)
  // but you don't want the logic & you don't want
  // it to be a <form> tag (for one thing you
  // get a warning if you put a form in a form)

  static Item = FakeFormItem;

  static propTypes = {
    layout: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
  };

  render() {
    const { layout, className, children } = this.props;
    return (
      <div
        className={classNames(
          className,
          clsPrefix,
          'ant-legacy-form',
          `ant-legacy-form-${layout || 'horizontal'}`
        )}
      >
        {children}
      </div>
    );
  }
}
