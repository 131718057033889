/* Generated file, please DO NOT edit directly */
/* See jsapp/proto2ts.sh */

/* eslint-disable */

export const protobufPackage = 'permissions';

export interface RoleType {}

/**
 * A role is a relationship of a user to
 * an object that can grant access to things
 *
 * A role_type is a type of role, which is some
 * data type with an id in our system
 */
export const RoleTypeEnum = {
  UNKNOWN_ROLE_TYPE: 'UNKNOWN_ROLE_TYPE',
  USER: 'USER',
  PARTY: 'PARTY',
  TEAM: 'TEAM',
  /**
   * THIS_SIDE_CLIENT - transaction roles based permission
   * each user will get these roles based on
   * their transaction role. Use these when any party
   * with a given role should get a permission
   * look at web/permissions/transaction_role_groups.py
   * for role to transaction role mapping
   */
  THIS_SIDE_CLIENT: 'THIS_SIDE_CLIENT',
  THIS_SIDE_TEAM: 'THIS_SIDE_TEAM',
  THIS_SIDE_AGENT: 'THIS_SIDE_AGENT',
  THIS_SIDE: 'THIS_SIDE',
  SELLER: 'SELLER',
  LISTING_TEAM: 'LISTING_TEAM',
  LISTING_AGENT: 'LISTING_AGENT',
  SELLER_SIDE: 'SELLER_SIDE',
  BUYER: 'BUYER',
  BUYER_TEAM: 'BUYER_TEAM',
  BUYER_AGENT: 'BUYER_AGENT',
  BUYER_SIDE: 'BUYER_SIDE',
  FILE_REVIEWER: 'FILE_REVIEWER',
  /** TEAM_OWNER - ORG ROLES */
  TEAM_OWNER: 'TEAM_OWNER',
  TEAM_ADMIN: 'TEAM_ADMIN',
  TEAM_MEMBER: 'TEAM_MEMBER',
  TEAM_FILE_REVIEWER: 'TEAM_FILE_REVIEWER',
} as const;

export type RoleTypeEnum = typeof RoleTypeEnum[keyof typeof RoleTypeEnum];

export interface ObjectType {}

export const ObjectTypeEnum = {
  UNKNOWN_ROLE_TYPE: 'UNKNOWN_ROLE_TYPE',
  TEAM: 'TEAM',
  CLAUSE: 'CLAUSE',
} as const;

export type ObjectTypeEnum = typeof ObjectTypeEnum[keyof typeof ObjectTypeEnum];

export interface Permission {}

/** A permission is an atomic unit of access */
export const PermissionEnum = {
  UNKNOWN_PERMISSION: 'UNKNOWN_PERMISSION',
  /** R - Universal permissions 1 - 100 */
  R: 'R',
  /**
   * FLOW_SET_FIELDS - Flow permissions 100-300
   * these are low-level enforced by the flow system
   */
  FLOW_SET_FIELDS: 'FLOW_SET_FIELDS',
  FLOW_PROGRESS: 'FLOW_PROGRESS',
  /**
   * FLOW_COMMENT - these are enforced at the dispatch kind level
   * by the flow system
   */
  FLOW_COMMENT: 'FLOW_COMMENT',
  FLOW_FLAG: 'FLOW_FLAG',
  FLOW_DEACTIVATE: 'FLOW_DEACTIVATE',
  FLOW_COMPLETE: 'FLOW_COMPLETE',
  FLOW_SUBMIT: 'FLOW_SUBMIT',
  FLOW_DOCUMENT_PREVIEW: 'FLOW_DOCUMENT_PREVIEW',
  FLOW_SET_GLOBALS: 'FLOW_SET_GLOBALS',
  FLOW_BACK_FILL: 'FLOW_BACK_FILL',
  FLOW_FLAG_ADMIN: 'FLOW_FLAG_ADMIN',
  _OBSOLETE_CAN_FORWARD_SHARED_TRANSACTION_ITEM:
    '_OBSOLETE_CAN_FORWARD_SHARED_TRANSACTION_ITEM',
  /** CREATE_TEAM - ORG Permission 200-299 */
  CREATE_TEAM: 'CREATE_TEAM',
  VIEW_TEAM: 'VIEW_TEAM',
  UPDATE_TEAM: 'UPDATE_TEAM',
  REMOVE_TEAM: 'REMOVE_TEAM',
  UPDATE_TEAM_PERMISSIONS: 'UPDATE_TEAM_PERMISSIONS',
  ADD_TEAM_MEMBER: 'ADD_TEAM_MEMBER',
  REMOVE_TEAM_MEMBER: 'REMOVE_TEAM_MEMBER',
  VIEW_TEAM_MEMBERS: 'VIEW_TEAM_MEMBERS',
  UPDATE_TEAM_MEMBERS: 'UPDATE_TEAM_MEMBERS',
  VIEW_TEAM_TRANSACTIONS: 'VIEW_TEAM_TRANSACTIONS',
  VIEW_TEAM_ZIPFORM_ACCOUNTS: 'VIEW_TEAM_ZIPFORM_ACCOUNTS',
  VIEW_TEAM_TEMPLATES: 'VIEW_TEAM_TEMPLATES',
  MANAGE_TEAM_TEMPLATES: 'MANAGE_TEAM_TEMPLATES',
  APPROVE_CANCELLATIONS: 'APPROVE_CANCELLATIONS',
  MANAGE_API_INTEGRATIONS: 'MANAGE_API_INTEGRATIONS',
  /** ACT_AS_CLIENT - 1000 - 1999 For non-flow app. We may want to make fine-grained permissions later */
  ACT_AS_CLIENT: 'ACT_AS_CLIENT',
  ACT_AS_AGENT: 'ACT_AS_AGENT',
  ACT_AS_ADMIN: 'ACT_AS_ADMIN',
  REVIEW_FILES: 'REVIEW_FILES',
} as const;

export type PermissionEnum = typeof PermissionEnum[keyof typeof PermissionEnum];

export interface UserPermissions {
  userIds: string[];
  permissions: PermissionEnum[];
  noUsers: boolean;
}

export interface Policy {}

/**
 * A policy is a logical grouping of permissions
 * that a role can grant
 */
export const PolicyEnum = {
  UNKNOWN_POLICY: 'UNKNOWN_POLICY',
  FLOW_CLIENT: 'FLOW_CLIENT',
  FLOW_AGENT: 'FLOW_AGENT',
  FLOW_FULL: 'FLOW_FULL',
  FLOW_TESTER: 'FLOW_TESTER',
  DANGER_FLOW_PUBLIC: 'DANGER_FLOW_PUBLIC',
  /** _OBSOLETE_SHARED_TRANSACTION_ITEM_ADMINISTRATOR - Policy for user/group with whom the transaction item was shared. */
  _OBSOLETE_SHARED_TRANSACTION_ITEM_ADMINISTRATOR:
    '_OBSOLETE_SHARED_TRANSACTION_ITEM_ADMINISTRATOR',
  /** _OBSOLETE_SHARED_TRANSACTION_ITEM_VIEWER - Policy for user/group to whom the transaction item was forwarded for viewing only. */
  _OBSOLETE_SHARED_TRANSACTION_ITEM_VIEWER:
    '_OBSOLETE_SHARED_TRANSACTION_ITEM_VIEWER',
  TRANSACTION_CLIENT: 'TRANSACTION_CLIENT',
  TRANSACTION_AGENT: 'TRANSACTION_AGENT',
  TRANSACTION_ADMIN: 'TRANSACTION_ADMIN',
  TRANSACTION_FILE_REVIEWER: 'TRANSACTION_FILE_REVIEWER',
  /** OWN_TEAM - ORG Policies 50-69 */
  OWN_TEAM: 'OWN_TEAM',
  MANAGE_TEAM: 'MANAGE_TEAM',
  MANAGE_TEAM_WITHOUT_ADD: 'MANAGE_TEAM_WITHOUT_ADD',
  VIEW_TEAM: 'VIEW_TEAM',
  VIEW_TEAM_TRANSACTIONS: 'VIEW_TEAM_TRANSACTIONS',
  VIEW_TEAM_TEMPLATES: 'VIEW_TEAM_TEMPLATES',
  MANAGE_TEAM_TEMPLATES: 'MANAGE_TEAM_TEMPLATES',
  VIEW_TEAM_ZIPFORM: 'VIEW_TEAM_ZIPFORM',
  REVIEW_FILES: 'REVIEW_FILES',
  /** ENVELOPE_ADMIN - SIGNING policies 70-99 */
  ENVELOPE_ADMIN: 'ENVELOPE_ADMIN',
  /** MANAGE_CLAUSE - 100-104 Clause Permissions */
  MANAGE_CLAUSE: 'MANAGE_CLAUSE',
} as const;

export type PolicyEnum = typeof PolicyEnum[keyof typeof PolicyEnum];

export interface UserRole {
  /**
   * A role is a role_id + a role_type
   *
   * A UserRole is the "has" relation between a user
   * and a role
   */
  id: string;
  userId: string;
  roleId: string;
  roleType: RoleTypeEnum;
}

export interface AccessControl {
  /**
   * an Access Control is a ACL entry, granting
   * a policy to a role
   */
  id: string;
  roleId: string;
  roleType: RoleTypeEnum;
  policy: PolicyEnum;
  objectId: string;
  objectType: ObjectTypeEnum;
  deleted: number;
}

export interface AccessPolicy {
  /**
   * an AccessPolicy is the total access policy of
   * an object, at present consisting only of an acl
   *
   * this is supposed to be opaque, never construct
   * an access_policy nor access its attributes, use
   * the PermissionService instead
   */
  acl: AccessControl[];
}
