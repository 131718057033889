import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getDisplayName from 'src/utils/get-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { Portal } from 'react-portal';
import { inject, observer } from 'mobx-react';

/*
  This hides the app-chrome sidebar and/or topbar and puts the
  component in a portal, thus hiding page navigation. This is used for
  flows and modal-like pages.
*/
export default function hideBars({
  getHideTopBar = () => true,
  portal = true,
  containerProps = {},
  allowRef = false,
} = {}) {
  return (WrappedComponent) => {
    @inject('ui')
    @observer
    class HideBars extends Component {
      static propTypes = {
        ui: PropTypes.object.isRequired,
        forwardedRef: PropTypes.object,
      };

      setScrollViewRef = (scrollViewRef) => {
        this.scrollViewRef = scrollViewRef;
      };

      setScrollTop = (top = 0) => {
        if (this.scrollViewRef) {
          this.scrollViewRef.scrollTop = top;
        }
      };

      componentDidMount() {
        const { ui } = this.props;
        this.unsetTopBar = ui.setHasTopBar(!getHideTopBar(this.props));
      }

      componentWillUnmount() {
        this.unsetTopBar();
      }

      render() {
        const { forwardedRef, ...props } = this.props;
        if (portal) {
          return (
            <Portal>
              <div
                ref={this.setScrollViewRef}
                className="full-page-scroll"
                {...containerProps}
              >
                <WrappedComponent
                  {...props}
                  ref={forwardedRef}
                  setScrollTop={this.setScrollTop}
                />
              </div>
            </Portal>
          );
        }
        return <WrappedComponent {...props} ref={forwardedRef} />;
      }
    }

    hoistNonReactStatics(HideBars, WrappedComponent);
    HideBars.displayName = `HideBars(${getDisplayName(WrappedComponent)})`;
    return !allowRef
      ? HideBars
      : React.forwardRef((props, ref) => (
          <HideBars {...props} forwardedRef={ref} />
        ));
  };
}
