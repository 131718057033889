import invariant from 'invariant';
import { chain } from 'lodash';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import { toJS } from 'mobx';
import logger from 'src/logger';
import { LISTING_AGENT } from 'src/models/transactions/roles';
import type TransactionStore from 'src/stores/transaction-store';
import { TeamMembership } from 'src/types/proto/auth';
import {
  RecipientUserContactRecipient,
  Recipient,
} from 'src/types/proto/transactions';
import getKindData from 'src/utils/get-kind-data';
import type Party from './items/party';

export const KIND_PARTY = 'PARTY';

export const KIND_TEAM_MEMBERSHIP = 'TEAM_MEMBERSHIP';

export const KIND_USER_CONTACT = 'USER_CONTACT';

export const KIND_NEW_CONTACT = 'NEW_CONTACT';

export function makePartyRecipient(party: Party) {
  return {
    kind: KIND_PARTY,
    key: `${KIND_PARTY}-${party.id}`,
    party: {
      id: party.id,
      vers: party.vers,
      contact: toJS(party.contact),
      roles: toJS(party.roles),
    },
  };
}

export function makeTeamMembershipRecipient(membership: TeamMembership) {
  return {
    kind: KIND_TEAM_MEMBERSHIP,
    key: `${KIND_TEAM_MEMBERSHIP}-${membership.id}`,
    teamMembership: {
      id: membership.id,
      contact: {
        firstName: membership.user?.contact?.firstName,
        lastName: membership.user?.contact?.lastName,
        email: membership.user?.contact?.email,
      },
    },
  };
}

export function makeUserContactRecipient({
  userContact,
  roles = [],
}: {
  userContact?: RecipientUserContactRecipient;
  roles?: string[];
}) {
  return {
    kind: KIND_USER_CONTACT,
    key: `${KIND_USER_CONTACT}-${userContact?.userContactId}`,
    userContact: {
      ...userContact,
      roles,
    },
  };
}

export function getContactForRecipient(recipient: Recipient) {
  const gettersByKinds = {
    PARTY: 'party.contact',
    NEW_CONTACT: 'newContact.contact',
    USER_CONTACT: 'userContact.contact',
  };
  return get(
    recipient,
    gettersByKinds[recipient.kind as keyof typeof gettersByKinds]
  );
}

export function makeNewContactRecipient({
  firstName,
  lastName,
  email,
  roles = [],
}: {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
}) {
  return {
    kind: KIND_NEW_CONTACT,
    key: [firstName, lastName, email].join('::'),
    newContact: {
      contact: {
        firstName,
        lastName,
        email,
      },
      roles,
    },
  };
}

export function makeRecipient(
  recipientSource: Recipient | Party | (TeamMembership & { kind: string })
) {
  if (recipientSource.kind === KIND_PARTY) {
    return makePartyRecipient(recipientSource as Party);
  }
  if (recipientSource.kind === KIND_TEAM_MEMBERSHIP) {
    return makeTeamMembershipRecipient(recipientSource as TeamMembership);
  }
  if (recipientSource.kind === KIND_USER_CONTACT) {
    return makeUserContactRecipient(recipientSource as Recipient);
  }

  logger.warn(`Unsupported recipient kind ${recipientSource.kind}`);
  return null;
}

// HACK: Since the recipient object denormalizes contact, role info,
// we need to keep it up to date for party recipients. We do this by
// recreating the recipient from the source party.
export function refreshPartyRecipient(
  transactions: TransactionStore,
  recipient: Recipient,
  nullIfNotFound = false
) {
  if (recipient.kind !== KIND_PARTY) {
    return recipient;
  }

  const party = transactions.itemsById.get(recipient.party?.id);
  if (!party) {
    return nullIfNotFound ? null : recipient;
  }

  return {
    ...recipient,
    ...makePartyRecipient(party),
  };
}

type RecipientType = Omit<Recipient, 'kind'> & {
  kind: Exclude<Recipient['kind'], 'UNKNOWN'>;
};

export function getRoles(recipient: RecipientType): string[] {
  const kindData = getKindData(recipient);
  return kindData?.roles || [];
}

export function ensureRole(recipient: RecipientType, role: string) {
  const kindData = getKindData(recipient);

  return {
    ...recipient,
    [camelCase(recipient.kind)]: {
      ...kindData,
      roles: uniq(((kindData?.roles as string[]) || []).concat(role)),
    },
  };
}

export function getRolesFromRecipients(recipients: Recipient[]) {
  return chain(recipients)
    .map(getRoles)
    .flatten()
    .uniq()
    .value() as unknown as string[];
}

export function getListingAgentParty(recipients: Recipient[]) {
  const agentRecipient = recipients.find((recipient) => {
    if (
      recipient.party &&
      recipient.party.roles.includes(LISTING_AGENT as 'LISTING_AGENT')
    ) {
      return true;
    }
    return false;
  });
  return agentRecipient?.party;
}

export function recipientsHaveAnyRoles(
  recipients: Recipient[],
  roles: string[]
) {
  if (!recipients || !recipients.length) {
    return false;
  }

  const allRoles = getRolesFromRecipients(recipients);
  return intersection(allRoles, roles).length > 0;
}

export function recipientsHaveRequiredRoles(
  recipients: Recipient[],
  requireRoles: string[]
) {
  invariant(isArray(recipients), '`recipients` must be an array.');
  invariant(isArray(requireRoles), '`requireRoles` must be an array.');
  return (
    !requireRoles.length ||
    intersection(getRolesFromRecipients(recipients), requireRoles).length ===
      requireRoles.length
  );
}
