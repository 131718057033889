import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import type AggregateStore from 'src/stores/aggregate-store';

export interface AggregateJson {
  id: string;
  meta: {
    title: string;
  };
}

export default abstract class Aggregate<
  SStore extends Partial<AggregateStore>,
  SJson extends AggregateJson
> extends Model<SJson> {
  public store: SStore;

  constructor(store: SStore, json: SJson) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get meta() {
    return this.data.meta;
  }

  @computed
  get title() {
    return this.meta.title;
  }
}
